import React from 'react';

import DropdownButton from 'components/DropdownButton';

import styles from './styles.module.css';

const ShareToButton = ({
  disabled,
  onShareTooltipClick,
  onTutoringTooltipClick
}) => (
  <DropdownButton
    color="blue"
    title="Share To"
    disabled={disabled}
    className={styles.sameWidth}
  >
    <DropdownButton.Item
      onClick={onShareTooltipClick}
      color="blue"
      className={styles.sameWidth}
    >
      Meeting
    </DropdownButton.Item>
    <DropdownButton.Item
      onClick={onTutoringTooltipClick}
      color="blue"
      className={styles.sameWidth}
    >
      Small Group
    </DropdownButton.Item>
  </DropdownButton>
);

export default React.memo(ShareToButton);
