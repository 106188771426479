import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import { TEACHERS_MEETING_SORT_URL } from 'config/urls';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { notifyErrors } from 'utils/notifications';
import { reverse, stringifyParams } from 'utils/urls';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import Typography from 'components/Typography';

import { initializeMeetingBoard } from './sdk';
import styles from './styles.module.css';

const SortWorkDialog = ({ invitationIdentifier, meetingShares, onClose }) => {
  const history = useHistory();
  const [title, setTitle] = useState('');

  const defaultMeetingShares = _.reduce(
    _.map(meetingShares, 'meeting_share_id'),
    (result, value) => {
      result[value] = true;
      return result;
    },
    {}
  );

  const [selectedMeetingShares, setSelectedMeetingShares] =
    useState(defaultMeetingShares);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);

    // Filter only meeting shares that have their checkbox checked
    const markedMeetingSharesIds = _.keys(_.pickBy(selectedMeetingShares));

    const postData = {
      board_name: title,
      meeting_shares_ids: markedMeetingSharesIds
    };

    const { success, data, errors } = await initializeMeetingBoard(postData);

    setSubmitting(false);

    if (success) {
      onClose();
      history.push({
        pathname: reverse(TEACHERS_MEETING_SORT_URL, { invitationIdentifier }),
        search: stringifyParams({ sortId: data.id })
      });
    } else {
      notifyErrors(errors);
    }
  }, [onClose, selectedMeetingShares, title, history, invitationIdentifier]);

  const toggleMeetingShareSelected = useCallback(
    (id) =>
      setSelectedMeetingShares((prevSelectedMeetingShares) => ({
        ...prevSelectedMeetingShares,
        [id]: !prevSelectedMeetingShares[id]
      })),
    []
  );

  const allMeetingSharesAreSelected = useMemo(
    () => _.every(_.values(selectedMeetingShares)),
    [selectedMeetingShares]
  );

  const someMeetingSharesAreSelected = useMemo(
    () => _.some(_.values(selectedMeetingShares)),
    [selectedMeetingShares]
  );

  const toggleAllMeetingSharesSelected = useCallback(
    () =>
      setSelectedMeetingShares((prevSelectedMeetingShares) =>
        _.mapValues(
          prevSelectedMeetingShares,
          () => !allMeetingSharesAreSelected
        )
      ),
    [allMeetingSharesAreSelected]
  );

  return (
    <Dialog
      open
      alignTop
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Select work to sort
        </Typography>
        <Button
          color="pink"
          onClick={onSubmit}
          disabled={submitting || !someMeetingSharesAreSelected}
          className={styles.createButton}
        >
          Sort
        </Button>
      </div>
      <InputField
        fullWidth
        variant="underlined"
        placeholder="Name the Sort (optional)"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        className={styles.sortTitleInput}
      />
      <div className={styles.meetingShareSelect}>
        <div
          className={styles.selectAll}
          onClick={toggleAllMeetingSharesSelected}
        >
          <Checkbox color="primary" checked={allMeetingSharesAreSelected} />
          <Typography variant="S-TEXT-1" color={colors.grey3}>
            Select all assignments
          </Typography>
        </div>
        {_.map(meetingShares, (meetingShare) => {
          const isSelected =
            selectedMeetingShares[meetingShare.meeting_share_id];

          return (
            <div
              key={meetingShare.meeting_share_id}
              className={classnames(styles.selectableMeetingShare, {
                [styles.selected]: isSelected
              })}
              onClick={() =>
                toggleMeetingShareSelected(meetingShare.meeting_share_id)
              }
            >
              <Checkbox color="primary" checked={isSelected} />
              <Typography variant="B-Text-2" color={colors.grey1}>
                {meetingShare.name} - {meetingShare.section_name}
              </Typography>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
};

export default React.memo(SortWorkDialog);
