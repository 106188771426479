import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import { loadStripe } from '@stripe/stripe-js/pure';
import cx from 'classnames';
import { Formik } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';

import { colors } from 'theme/palette';
import { notifyError, notifySuccess } from 'utils/notifications';
import {
  SubscriptionPaymentStatus,
  SubscriptionStatus,
  getPaymentMethodRepresentation
} from 'utils/subscriptions';

import Button from 'components/Button';
import DebouncedField from 'components/DebouncedField';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import StripeField from 'components/StripeField';
import Switch from 'components/Switch';
import Typography from 'components/Typography';

import { ReactComponent as LockIcon } from './assets/lock.svg';
import { createSubscription, customerAddCard, fetchCustomer } from './sdk';
import styles from './styles.module.css';

const BillingPriceOption = ({
  price: { stripeId, label, amount, interval, notice },
  selectedBillingPriceStripeId,
  setSelectedBillingPriceStripeId
}) => {
  const checked = stripeId === selectedBillingPriceStripeId;

  return (
    <Grid
      container
      alignItems="center"
      className={cx('pointer', styles.pricePlan, { [styles.checked]: checked })}
      onClick={() => setSelectedBillingPriceStripeId(stripeId)}
    >
      <Grid container item xs alignItems="center">
        <Grid item>
          <Radio value={stripeId} checked={checked} />
        </Grid>
        <Grid item>
          <Typography
            variant="H-TEXT-3"
            color={checked ? colors.blue1 : colors.grey2}
          >
            {label}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs alignItems="center" justify="flex-end">
        {notice && (
          <Grid item>
            <Typography
              variant="S-TEXT-2"
              color={colors.pink2}
              className={styles.priceNotice}
            >
              {notice}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Typography
            variant="B-Text-2"
            color={checked ? colors.blue2 : colors.grey2}
          >
            {notice && ' - '}${amount} / {interval}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BillingPeriodSelect = ({
  template,
  selectedBillingPriceStripeId,
  setSelectedBillingPriceStripeId
}) => {
  const monthlyAmount = template.monthly_amount.amount;
  const yearlyAmount = template.yearly_amount.amount;

  const amountDifference = monthlyAmount * 12 - yearlyAmount;
  const yearlyAmountSavingPercentage = (amountDifference / yearlyAmount) * 100;

  const prices = [
    {
      stripeId: template.monthly_price_stripe_id,
      label: 'Monthly',
      amount: monthlyAmount,
      interval: 'month'
    },
    {
      stripeId: template.yearly_price_stripe_id,
      label: 'Annualy',
      amount: yearlyAmount,
      interval: 'year',
      notice: `Save ${yearlyAmountSavingPercentage}%`
    }
  ];

  return (
    <>
      {_.map(prices, (price) => (
        <BillingPriceOption
          key={price.stripeId}
          price={price}
          selectedBillingPriceStripeId={selectedBillingPriceStripeId}
          setSelectedBillingPriceStripeId={setSelectedBillingPriceStripeId}
        />
      ))}
    </>
  );
};

const validationSchema = yup.object().shape({
  nameOnCard: yup.string().required('Name on card is required'),
  zipCode: yup
    .string()
    .required('Zip code is required')
    .matches(/^[0-9]+$/, 'Zip code must be only digits.')
    .min(5, 'Zip code must be exactly 5 digits.')
    .max(5, 'Zip code must be exactly 5 digits.')
});

const PaymentForm = ({
  customer,
  paidSubscriptionTemplate,
  redirectToDashboard
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);

  const [selectedBillingPriceStripeId, setSelectedBillingPriceStripeId] =
    useState(paidSubscriptionTemplate.yearly_price_stripe_id);

  const customerHasPaymentMethod = !_.isEmpty(
    customer.payment_method_stripe_id
  );
  const [useCurrentPaymentMethod, setUseCurrentPaymentMethod] = useState(
    customerHasPaymentMethod
  );

  const [startPolling, setStartPolling] = useState(false);
  const [currentlyPolling, setCurrentlyPolling] = useState(false);
  const [pollCount, setPollCount] = useState(0);

  const [customerToConfirm, setCustomerToConfirm] = useState(null);

  const [paymentIsSuccessful, setPaymentIsSuccessful] = useState(false);

  const setErrorState = (msg) => {
    setErrors(msg);
    setSubmitting(false);
  };

  const stopPolling = () => {
    setCurrentlyPolling(false);
    setStartPolling(false);
    setPollCount(0);
  };

  const upgradePlan = async (values) => {
    setSubmitting(true);

    let paymentMethodId = customer.payment_method_stripe_id;

    if (!useCurrentPaymentMethod) {
      const cardElement = elements.getElement(CardNumberElement);

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: values.nameOnCard,
          address: {
            postal_code: values.zipCode
          }
        }
      });

      if (error) {
        notifyError(error);
        setSubmitting(false);
        return;
      }

      const data = { payment_method_id: paymentMethod.id };
      const { success } = await customerAddCard(data);

      if (success) {
        paymentMethodId = paymentMethod.id;
      } else {
        setErrorState("Something went wrong, we'll check & get back to you");
      }
    }

    const data = {
      product: paidSubscriptionTemplate.stripe_id,
      price: selectedBillingPriceStripeId,
      payment_method: paymentMethodId
    };

    const { success } = await createSubscription(data);

    if (success) {
      setStartPolling(true);
    } else {
      setErrorState("Something went wrong, we'll check & get back to you");
    }
  };

  useEffect(() => {
    if (!startPolling) {
      return;
    }

    if (currentlyPolling) {
      return;
    }

    const threshold = 25;

    if (pollCount >= threshold) {
      stopPolling();
      setErrorState("Something went wrong, we'll check & get back to you");
      return;
    }

    async function pollCustomer() {
      setCurrentlyPolling(true);
      setPollCount(pollCount + 1);

      const { success, data } = await fetchCustomer();

      if (!success) {
        stopPolling();
        setErrorState("Something went wrong, we'll check & get back to you");
        return;
      }

      const current = data.subscription;
      const last = data.last_subscription;

      const currentIsActive = current.status === SubscriptionStatus.ACTIVE;

      const lastIsIncomplete =
        _.get(last, 'status') === SubscriptionStatus.INCOMPLETE;
      const lastPaymentStatus = _.get(last, 'payment_status');
      const lastRequiresAction =
        lastPaymentStatus === SubscriptionPaymentStatus.REQUIRES_ACTION;

      const lastRequiresPaymentMethod =
        lastPaymentStatus === SubscriptionPaymentStatus.REQUIRES_PAYMENT_METHOD;

      if (currentIsActive && last === null) {
        stopPolling();
        setPaymentIsSuccessful(true);
      }

      if (lastRequiresAction && customerToConfirm === null) {
        stopPolling();
        setCustomerToConfirm(data);
      }

      if (lastIsIncomplete && lastRequiresPaymentMethod) {
        stopPolling();
        setErrorState(`Payment error: ${last.payment_intent_error}`);
      }

      setCurrentlyPolling(false);
    }

    const timeout = setTimeout(pollCustomer, 2000);

    return () => clearTimeout(timeout);
  }, [
    startPolling,
    currentlyPolling,
    pollCount,
    paymentIsSuccessful,
    customerToConfirm
  ]);

  useEffect(() => {
    if (customerToConfirm === null) {
      return;
    }

    const { payment_method_stripe_id, last_subscription } = customerToConfirm;

    async function confirmCardPayment() {
      const { error } = await stripe.confirmCardPayment(
        last_subscription.payment_intent_client_secret,
        {
          payment_method: payment_method_stripe_id
        }
      );

      if (error) {
        setErrorState(error.message);
      } else {
        setStartPolling(true);
      }
    }

    confirmCardPayment();
  }, [customerToConfirm, stripe]);

  useEffect(() => {
    if (paymentIsSuccessful) {
      redirectToDashboard();
      notifySuccess('You have successfully created your subscription!');
    }
  }, [paymentIsSuccessful, redirectToDashboard]);

  const hasErrors = errors !== null;
  const currentPaymentMethod = getPaymentMethodRepresentation(customer);

  return (
    <>
      {!hasErrors && (
        <>
          {customerHasPaymentMethod && (
            <Box marginBottom={3}>
              <Typography
                variant="H-TEXT-3"
                color={colors.blue2}
                className={styles.offset}
              >
                Current payment method
              </Typography>
              <Typography
                variant="H-TEXT-3"
                color={colors.blue4}
                className={styles.currentPaymentMethod}
              >
                {currentPaymentMethod}
              </Typography>
              <FormControlLabel
                disabled={submitting}
                control={
                  <Switch
                    checked={useCurrentPaymentMethod}
                    onChange={() =>
                      setUseCurrentPaymentMethod(!useCurrentPaymentMethod)
                    }
                  />
                }
                label="Use current payment method"
              />
            </Box>
          )}
          <Formik
            initialValues={{ nameOnCard: '', zipCode: '' }}
            validationSchema={validationSchema}
            onSubmit={upgradePlan}
          >
            {({ handleSubmit, values, errors, dirty, setFieldValue }) => (
              <>
                {!useCurrentPaymentMethod && (
                  <Box>
                    <Typography
                      variant="H-TEXT-3"
                      color={colors.blue3}
                      className={cx(styles.bold, styles.offset)}
                    >
                      Your subscription
                    </Typography>
                    <BillingPeriodSelect
                      template={paidSubscriptionTemplate}
                      selectedBillingPriceStripeId={
                        selectedBillingPriceStripeId
                      }
                      setSelectedBillingPriceStripeId={
                        setSelectedBillingPriceStripeId
                      }
                    />
                    <Box marginTop={3}>
                      <Typography
                        variant="H-TEXT-3"
                        color={colors.blue3}
                        className={cx(styles.bold, styles.offset)}
                      >
                        Card Information
                      </Typography>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <DebouncedField
                            fullWidth
                            component={InputField}
                            value={values.nameOnCard}
                            onChange={(value) => {
                              setFieldValue('nameOnCard', value);
                            }}
                            error={!_.isNil(errors.nameOnCard)}
                            helperText={errors.nameOnCard}
                            placeholder="Name on card"
                            variant="underlined"
                          />
                        </Grid>
                        <Grid item>
                          <StripeField
                            component={CardNumberElement}
                            options={{
                              showIcon: true
                            }}
                          />
                        </Grid>
                        <Grid container item spacing={1}>
                          <Grid item xs={4}>
                            <StripeField component={CardExpiryElement} />
                          </Grid>
                          <Grid item xs={4}>
                            <StripeField component={CardCvcElement} />
                          </Grid>
                          <Grid item xs={4}>
                            <DebouncedField
                              component={InputField}
                              value={values.zipCode}
                              onChange={(value) => {
                                setFieldValue('zipCode', value);
                              }}
                              error={!_.isNil(errors.zipCode)}
                              helperText={errors.zipCode}
                              placeholder="Zip"
                              variant="underlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
                <Box marginTop={4}>
                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    variant="base"
                    color="blue"
                    endIcon={<LockIcon className={styles.lockIcon} />}
                    disabled={
                      submitting ||
                      (!useCurrentPaymentMethod &&
                        (!dirty || !_.isEmpty(errors)))
                    }
                  >
                    Subscribe
                  </Button>
                  {submitting && <LinearProgress />}
                </Box>
              </>
            )}
          </Formik>
        </>
      )}
      {hasErrors && (
        <Typography variant="H-TEXT-2" color={colors.pinkDarker}>
          {errors}
        </Typography>
      )}
    </>
  );
};

const PlanUpgradePaymentDialog = ({
  customer,
  paidSubscriptionTemplate,
  onClose,
  redirectToDashboard
}) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_API_KEY));
  }, []);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      disableBackdropClick={true}
    >
      <DialogTitle disableTypography className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          {paidSubscriptionTemplate.display_name} plan
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Elements stripe={stripePromise}>
          <PaymentForm
            customer={customer}
            paidSubscriptionTemplate={paidSubscriptionTemplate}
            redirectToDashboard={redirectToDashboard}
          />
        </Elements>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default PlanUpgradePaymentDialog;
