import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { TEACHERS_STUDENT_GROUP_URL } from 'config/urls';
import _ from 'lodash';

import { useGoogleClassroomLastSync } from 'pages/Teachers/StudentGroupList/components/ClassesSyncCards/utils.js';
import CreateStudentGroupDialog from 'pages/Teachers/StudentGroupList/components/CreateStudentGroupDialog';
import UpdateSectionDialog from 'pages/Teachers/StudentGroupList/components/UpdateSectionDialog';
import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { reverse } from 'utils/urls';

import Typography from 'components/Typography';

import { ReactComponent as EditIcon } from './assets/edit.svg';
import styles from './styles.module.scss';

const StudentGroupCard = ({ group, onStudentGroupUpdate }) => {
  const history = useHistory();
  const {
    isOpened: updateStudentGroupDialogOpen,
    openDialog: openUpdateStudentGroupDialog,
    closeDialog: closeUpdateStudentGroupDialog
  } = useDialog();

  const {
    isOpened: updateSectionDialogOpen,
    openDialog: openUpdateSectionDialog,
    closeDialog: closeUpdateSectionDialog
  } = useDialog();

  const { lastSync: lastGoogleSync, loadingLastSync: loadingLastGoogleSync } =
    useGoogleClassroomLastSync();

  const studentsSyncedBy =
    _.isEmpty(lastGoogleSync) && !loadingLastGoogleSync
      ? 'Clever'
      : 'Google Classroom';

  const redirectToStudentGroupDetail = useCallback(() => {
    history.push(reverse(TEACHERS_STUDENT_GROUP_URL, { id: group.id }));
  }, [history, group.id]);

  const handleEditClick = useCallback(
    (event) => {
      event.stopPropagation();

      if (group.is_student_group) {
        openUpdateStudentGroupDialog();
      } else {
        openUpdateSectionDialog();
      }
    },
    [
      group.is_student_group,
      openUpdateStudentGroupDialog,
      openUpdateSectionDialog
    ]
  );

  return (
    <>
      <Paper
        variant="outlined"
        className={styles.studentGroupCard}
        onClick={redirectToStudentGroupDetail}
      >
        <div className={styles.header}>
          <Typography variant="H-TEXT-3" color={colors.blue1}>
            {group.name}
          </Typography>

          <IconButton
            className={styles.editIcon}
            size="small"
            onClick={handleEditClick}
          >
            <EditIcon fill={colors.grey2} />
          </IconButton>
        </div>
        <Divider />
        <div className={styles.content}>
          <div>
            <Typography variant="S-TEXT-1" color={colors.blue1}>
              Students
            </Typography>
            <Typography
              variant="B-Text-3"
              color={colors.grey2}
              className={styles.studentsNames}
            >
              {group.students.map((student) => student.name).join(', ')}
            </Typography>
          </div>
          <div className={styles.countWrapper}>
            <Typography variant="S-TEXT-1" color={colors.blue1}>
              {group.assignments_count} Assignment
              {group.assignments_count !== 1 ? 's' : ''}
            </Typography>
          </div>
        </div>
      </Paper>
      {updateStudentGroupDialogOpen && (
        <CreateStudentGroupDialog
          initialStudentGroup={group}
          initialSchool={group.school}
          initialStudents={group.students}
          initialUsers={group.users}
          onClose={closeUpdateStudentGroupDialog}
          onStudentGroupUpdate={onStudentGroupUpdate}
        />
      )}
      {updateSectionDialogOpen && (
        <UpdateSectionDialog
          section={group}
          school={group.school}
          students={group.students}
          studentsSyncedBy={studentsSyncedBy}
          users={group.users}
          onClose={closeUpdateSectionDialog}
          onSectionUpdate={onStudentGroupUpdate}
        />
      )}
    </>
  );
};

export default StudentGroupCard;
