import { BASE_URL } from 'config/urls';

import { get, requestSdk } from 'utils/sdk';

export const markSelectedSessionAsSeen = (sessionId) =>
  requestSdk(() =>
    get(
      `${BASE_URL}/v1/trackers/shared-tracker-instance-sections/mark-as-seen/${sessionId}/`
    )
  );

export const markAllSessionsAsSeen = () =>
  requestSdk(() =>
    get(
      `${BASE_URL}/v1/trackers/shared-tracker-instance-sections/mark-all-as-seen/`
    )
  );
