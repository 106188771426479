import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import cx from 'classnames';
import { Formik } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';

import { colors } from 'theme/palette';
import { notifyError, notifySuccess } from 'utils/notifications';
import { getPaymentMethodRepresentation } from 'utils/subscriptions';

import Button from 'components/Button';
import DebouncedField from 'components/DebouncedField';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import StripeField from 'components/StripeField';
import Typography from 'components/Typography';

import { customerAddCard } from './sdk';
import styles from './styles.module.css';

const validationSchema = yup.object().shape({
  nameOnCard: yup.string().required('Name on card is required'),
  zipCode: yup
    .string()
    .required('Zip code is required')
    .matches(/^[0-9]+$/, 'Zip code must be only digits.')
    .min(5, 'Zip code must be exactly 5 digits.')
    .max(5, 'Zip code must be exactly 5 digits.')
});

const UpdatePaymentMethodForm = ({ onClose }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState(false);

  const updatePaymentMethod = async (values) => {
    setSubmitting(true);

    const cardElement = elements.getElement(CardNumberElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: values.nameOnCard,
        address: {
          postal_code: values.zipCode
        }
      }
    });

    if (error) {
      notifyError(error);
      setSubmitting(false);
      return;
    }

    const data = { payment_method_id: paymentMethod.id };
    const { success } = await customerAddCard(data);

    if (success) {
      onClose();
      notifySuccess('Payment method changed successfully.');
    }
  };

  return (
    <Formik
      initialValues={{ nameOnCard: '', zipCode: '' }}
      validationSchema={validationSchema}
      onSubmit={updatePaymentMethod}
    >
      {({ handleSubmit, values, errors, dirty, setFieldValue }) => (
        <>
          <Box marginTop={3}>
            <Typography
              variant="H-TEXT-3"
              color={colors.blue3}
              className={cx(styles.bold, styles.offset)}
            >
              New card
            </Typography>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <DebouncedField
                  fullWidth
                  component={InputField}
                  value={values.nameOnCard}
                  onChange={(value) => {
                    setFieldValue('nameOnCard', value);
                  }}
                  error={!_.isNil(errors.nameOnCard)}
                  helperText={errors.nameOnCard}
                  placeholder="Name on card"
                  variant="underlined"
                />
              </Grid>
              <Grid item>
                <StripeField
                  component={CardNumberElement}
                  options={{
                    showIcon: true
                  }}
                />
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={4}>
                  <StripeField component={CardExpiryElement} />
                </Grid>
                <Grid item xs={4}>
                  <StripeField component={CardCvcElement} />
                </Grid>
                <Grid item xs={4}>
                  <DebouncedField
                    component={InputField}
                    value={values.zipCode}
                    onChange={(value) => {
                      setFieldValue('zipCode', value);
                    }}
                    error={!_.isNil(errors.zipCode)}
                    helperText={errors.zipCode}
                    placeholder="Zip"
                    variant="underlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={4}>
            <Button
              fullWidth
              onClick={handleSubmit}
              variant="base"
              color="blue"
              disabled={submitting || !dirty || !_.isEmpty(errors)}
            >
              Update payment method
            </Button>
            {submitting && <LinearProgress />}
          </Box>
        </>
      )}
    </Formik>
  );
};

const UpdatePaymentMethodDialog = ({ stripePromise, customer, onClose }) => {
  const currentPaymentMethod = customer.payment_method_stripe_id
    ? getPaymentMethodRepresentation(customer)
    : 'N/A';

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      disableBackdropClick={true}
    >
      <DialogTitle disableTypography className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Update payment method
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography
            variant="H-TEXT-3"
            color={colors.blue2}
            className={styles.offset}
          >
            Current payment method
          </Typography>
          <Typography
            variant="H-TEXT-3"
            color={colors.blue4}
            className={styles.currentPaymentMethod}
          >
            {currentPaymentMethod}
          </Typography>
        </Box>
        <Elements stripe={stripePromise}>
          <UpdatePaymentMethodForm onClose={onClose} />
        </Elements>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default UpdatePaymentMethodDialog;
