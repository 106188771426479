import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import { TEACHERS_CAPTURE_URL } from 'config/urls';
import _ from 'lodash';

import Stepper from 'pages/Teachers/BulkUpload/components/Stepper';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Layout = ({
  className,
  currentStepIndex,
  children,
  isNextButtonDisabled,
  nextButtonAction,
  nextButtonTooltip,
  isCloseButtonDisabled = false,
  showLoader = false
}) => {
  useEffect(() => {
    const overflowDisableCSS = 'overflow: hidden';
    // Disable page scroll when you land in a page with this Layout.
    document.body.style.cssText += overflowDisableCSS;

    return () => (document.body.style.cssText -= overflowDisableCSS);
  }, []);

  const linkUrl = useMemo(
    () => (isCloseButtonDisabled ? '#' : TEACHERS_CAPTURE_URL),
    [isCloseButtonDisabled]
  );

  const getNextButton = useCallback(() => {
    if (_.isNil(nextButtonAction)) {
      return <div />;
    }

    const nextButton = (
      <Button
        color="pink"
        className={styles.nextButton}
        disabled={isNextButtonDisabled}
        onClick={nextButtonAction}
        disableElevation
        endIcon={<ChevronRightRoundedIcon />}
      >
        Next
      </Button>
    );

    if (_.isEmpty(nextButtonTooltip)) {
      return nextButton;
    }

    return (
      <Tooltip
        arrow
        title={<Typography variant="B-Text-2">{nextButtonTooltip}</Typography>}
      >
        <span>{nextButton}</span>
      </Tooltip>
    );
  }, [isNextButtonDisabled, nextButtonTooltip, nextButtonAction]);

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.header}>
        <Stepper
          className={styles.stepper}
          currentStepIndex={currentStepIndex}
        />

        {getNextButton()}

        <Link
          to={linkUrl}
          className={cx(styles.close, {
            [styles.disabledClose]: isCloseButtonDisabled
          })}
        >
          <SvgIcon component={CloseIcon} />
        </Link>
      </div>
      {children}
      {showLoader && <div className={styles.loadingOverlay} />}
    </div>
  );
};

export default Layout;
