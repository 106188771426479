import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Pagination from '@material-ui/lab/Pagination';
import { CLEVER_SYNC_ID, GOOGLE_SYNC_ID } from 'config/constants';
import { TEACHERS_STUDENT_GROUP_URL } from 'config/urls';
import _ from 'lodash';
import { useUserSectionsList } from 'sdk';

import CleverSyncObserver from 'pages/Teachers/Assignments/List/components/CleverSyncObserver';
import GoogleClassroomSyncObserver from 'pages/Teachers/Assignments/List/components/GoogleClassroomSyncObserver';
import SectionsList from 'pages/Teachers/StudentGroupList/components/SectionsList';
import { withNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { useDialog, usePagination } from 'utils/hooks';
import { useMixpanelPageOpenTrack } from 'utils/integrations/mixpanel';
import { reverse } from 'utils/urls';
import { parseParams } from 'utils/urls';

import ClassesSyncCards from './components/ClassesSyncCards';
import AddButton from 'components/AddButton';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import {
  CreateStudentGroupDialog,
  Filters,
  SessionsTable,
  StudentGroupsListSkeleton
} from './components';
import styles from './styles.module.scss';
import {
  buildFiltersData,
  useCopySectionResponse,
  useFilters,
  useSessionsAssignedToMe
} from './utils';

const LIMIT = 10;

const StudentGroupList = ({ history }) => {
  const [filters, setFilters] = useFilters(history);

  const searchParams = parseParams(history.location.search);
  const googleSyncId = useMemo(
    () => _.get(searchParams, GOOGLE_SYNC_ID),
    [searchParams]
  );

  const cleverSyncId = useMemo(() => {
    const cleverSyncIdFromSearchParams = _.get(searchParams, CLEVER_SYNC_ID);

    const cleverSyncIdFromLocalStorage = localStorage.getItem(CLEVER_SYNC_ID);

    localStorage.removeItem(CLEVER_SYNC_ID);

    return cleverSyncIdFromSearchParams || cleverSyncIdFromLocalStorage;
  }, [searchParams]);

  const { user } = useContext(UserContext);

  const builtFilters = useMemo(
    () => buildFiltersData(filters, user.id),
    [filters, user.id]
  );

  const [pagination, setPagination] = useState({
    numPages: 1,
    currentPage: 1
  });

  const sectionsFilters = useMemo(() => {
    let offset = undefined;

    offset = LIMIT * (pagination.currentPage - 1);

    return _.omit({ ...builtFilters, offset }, _.isNil);
  }, [builtFilters, pagination]);

  const { data: sections, isLoading: isLoadingSections } = useUserSectionsList({
    params: sectionsFilters,
    enabled: !_.isEmpty(builtFilters)
  });

  const count = _.get(sections, 'count');

  useEffect(() => {
    if (!isLoadingSections) {
      let numPages = 1;

      if (!_.isNil(count)) {
        numPages = _.ceil(count / LIMIT);
      }

      setPagination((prevPagination) => ({
        ...prevPagination,
        numPages
      }));
    }
  }, [count, isLoadingSections]);

  const [studentGroups, updateStudentGroup] = useCopySectionResponse(
    sections?.results
  );

  const [
    { data: sessionsAssignedToMe, loading: sessionsLoading },
    currentSessionsPage,
    setSessionsPage,
    sessionsPageCount
  ] = usePagination(useSessionsAssignedToMe);

  const onStudentGroupCreate = useCallback(
    ({ studentGroup }) =>
      history.push(
        reverse(TEACHERS_STUDENT_GROUP_URL, { id: studentGroup.id })
      ),
    [history]
  );

  const {
    isOpened: createStudentGroupDialogOpen,
    openDialog: openCreateStudentGroupDialog,
    closeDialog: closeCreateStudentGroupDialog
  } = useDialog();

  const sessionsList = useMemo(
    () => _.get(sessionsAssignedToMe, 'results', []),
    [sessionsAssignedToMe]
  );

  const changePageAndRefetchListData = ({ page }) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: page
    }));
  };

  const { numPages, currentPage } = pagination;

  useMixpanelPageOpenTrack('Teacher opened Classes Page');

  return (
    <>
      <div className={styles.container}>
        <ClassesSyncCards />
        {!_.isEmpty(sessionsList) && (
          <>
            <div className={styles.headerLimiter}>
              <div className={styles.labelContainer}>
                <Typography variant="S-TEXT-1" color={colors.grey3}>
                  Small Group Sessions assigned to me
                </Typography>
              </div>
            </div>
            <TableContainer
              component={Paper}
              variant="outlined"
              className={styles.studentGroupTable}
            >
              {!sessionsLoading && (
                <SessionsTable
                  sessionsList={sessionsList}
                  pageCount={sessionsPageCount}
                  currentPage={currentSessionsPage}
                  setPage={setSessionsPage}
                />
              )}
            </TableContainer>
          </>
        )}

        <div className={styles.headerLimiter}>
          <div className={styles.labelContainer}>
            <Typography
              display="inline"
              variant="S-TEXT-1"
              color={colors.grey3}
            >
              Classes and Small Groups
            </Typography>

            <AddButton
              onClick={openCreateStudentGroupDialog}
              color="blue"
              noTypography
            >
              <Typography variant="S-TEXT-1">New Small Group</Typography>
            </AddButton>
          </div>
        </div>

        <Filters filters={filters} setFilters={setFilters} history={history} />

        {isLoadingSections && <StudentGroupsListSkeleton cardsCount={6} />}
        {!isLoadingSections && _.isEmpty(studentGroups) && (
          <Paper className={styles.noResultsContainer}>
            <Typography variant="H-TEXT-1" align="center" color={colors.blue3}>
              No Results
            </Typography>
          </Paper>
        )}
        {!isLoadingSections && (
          <SectionsList
            sections={studentGroups}
            onStudentGroupUpdate={updateStudentGroup}
          />
        )}
      </div>
      {!isLoadingSections && numPages > 1 && (
        <Pagination
          classes={{ root: styles.pagination }}
          count={numPages}
          page={currentPage}
          color="secondary"
          onChange={(event, page) => changePageAndRefetchListData({ page })}
        />
      )}

      {createStudentGroupDialogOpen && (
        <CreateStudentGroupDialog
          onClose={closeCreateStudentGroupDialog}
          onStudentGroupCreate={onStudentGroupCreate}
        />
      )}

      {!_.isNil(googleSyncId) && (
        <GoogleClassroomSyncObserver googleSyncId={googleSyncId} />
      )}
      {!_.isNil(cleverSyncId) && (
        <CleverSyncObserver cleverSyncId={cleverSyncId} />
      )}
    </>
  );
};

export default withNavbar(StudentGroupList, {
  title: 'My classes',
  sticky: true
});
