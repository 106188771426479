import _ from 'lodash';
import mixpanel from 'mixpanel-browser';

const MIXPANEL_KEY = process.env.REACT_APP_MIXPANEL_KEY;

export function initMixpanel() {
  if (_.isNil(MIXPANEL_KEY)) {
    console.warn(
      'Mixpanel cannot be configured for this environment. Please set `REACT_APP_MIXPANEL_KEY` if you want to use the integration.'
    );
    return;
  }

  mixpanel.init(MIXPANEL_KEY);
}
