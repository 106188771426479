import React from 'react';

import MuiButton from '@material-ui/core/Button';
import classnames from 'classnames';
import {
  EDLIGHT_MAIN_DOMAIN_URL,
  LOGIN_URL,
  WALKTHROUGH_CREATE_PROFILE_URL
} from 'config/urls';
import logo from 'images/logo-dark.svg';

import {
  TestimonialFooter,
  Typography,
  useWalkthroughUser
} from 'pages/Walkthrough/components';
import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Button from 'components/Button';
import Image from 'components/Image';

import { ReactComponent as StudentImage } from './assets/student.svg';
import { ReactComponent as TeacherImage } from './assets/teacher.svg';
import styles from './styles.module.css';

const TeacherStudentChosePage = ({ history, location }) => {
  const walkthroughUser = useWalkthroughUser(location.pathname);

  const onTeacherClick = () => {
    history.push(WALKTHROUGH_CREATE_PROFILE_URL);
    trackMixpanelEvent(
      walkthroughUser.id,
      'User started walkthrough as a teacher'
    );
  };

  const onStudentClick = () => {
    history.push(LOGIN_URL);
    trackMixpanelEvent(
      walkthroughUser.id,
      'User started walkthrough as a student'
    );
  };

  return (
    <div className={styles.page}>
      <a
        href={EDLIGHT_MAIN_DOMAIN_URL}
        className={classnames(styles.mobileScreen, styles.mobileLogo)}
      >
        <Image src={logo} alt="Logo" />
      </a>
      <div className={styles.container}>
        <a href={EDLIGHT_MAIN_DOMAIN_URL} className={styles.lgScreen}>
          <Image src={logo} className={styles.logo} alt="Logo" />
        </a>
        <MuiButton
          className={styles.logInButton}
          onClick={() => history.push(LOGIN_URL)}
        >
          <Typography variant="H-TEXT-2" color={colors.blue2}>
            Log In
          </Typography>
        </MuiButton>
        <div>
          <Typography
            variant="H-TEXT-4"
            color={colors.blue1}
            className={styles.welcomeText}
          >
            I am a...
          </Typography>
          <Typography
            variant="H-TEXT-1"
            color={colors.blue1}
            className={styles.welcomeTextMobile}
          >
            I am a...
          </Typography>
          <div className={styles.iconsContainer}>
            <div className={styles.teacherContainer}>
              <TeacherImage onClick={onTeacherClick} />
              <Button
                className={styles.teacherButton}
                color="blue"
                onClick={onTeacherClick}
              >
                Teacher
              </Button>
            </div>
            <div className={styles.studentContainer}>
              <StudentImage onClick={onStudentClick} />
              <Button
                className={styles.studentButton}
                color="blue"
                onClick={onStudentClick}
              >
                Student
              </Button>
            </div>
          </div>
          <div
            className={classnames(
              styles.mobileScreen,
              styles.mobileLogInContainer
            )}
          >
            <Typography variant="B-Text-2" color={colors.blue2}>
              Already have an account?
            </Typography>
            <Typography
              variant="H-TEXT-3"
              color={colors.pink1}
              className={styles.mobileLogInButton}
            >
              Log In
            </Typography>
          </div>
        </div>
      </div>
      <TestimonialFooter />
    </div>
  );
};

export default TeacherStudentChosePage;
