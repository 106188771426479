export const colors = {
  pink: '#ef5a93',
  pinkDarker: '#dd3d7b',
  pinkDarkerHover: 'rgb(167, 62, 102)',
  pinkLighter: '#f8adca',
  pinkLight: '#ff6ea7',

  // New design colors
  pink1: '#cc2364',
  pink2: '#dd3d7b',
  pink3: '#ef5a93',
  pink4: '#ff73a2',

  green1: '#3a7339',
  green2: '#438642',
  green3: '#4e994d',
  green4: '#6cb26b',
  green5: '#daf2da',

  blue1: '#292E52',
  blue2: '#333966',
  blue3: '#555893',
  blue4: '#7477B8',
  blue5: '#b0b3f4',
  blue6: '#e6e7ff',

  grey1: '#4d4d4d',
  grey2: '#757575',
  grey3: '#949494',
  grey4: '#b7b7b7',
  grey5: '#d0d0d0',
  grey6: '#ededed',
  grey7: '#f5f5f5',

  yellowDark: '#d29f3d',
  yellowLight: '#f0bd5b',
  yellow1: '#b27909',
  yellow2: '#bf881d',
  yellow3: '#d29f3d',
  yellow4: '#e4b14e',
  yellow5: '#ffeecc',
  yellow6: '#fff9f0',

  blue: '#555893',
  blueDark: '#33385c',
  blueDarker: '#292e52',
  blueLight: '#7477b8',
  blueLighter: '#b0b3f4',

  green: '#75b874',
  greenDarker: '#61a460',
  greenDarker2: '#438642',
  greenLighter: '#89cc88',

  greyDark: '#7e7e7e',
  greyDarker: '#4d4d4d',
  // grey2: '#c4c4c4', // replaced with new grey4
  // grey1: '#d9d9d9', // replaced with new grey6
  // grey3: '#a0a0a0', // replaced with new grey3
  background: '#f7f7f7',
  greyWhite: '#fafafa',
  greyLighter: '#f3f3f3',
  greyLight: '#ededed',

  white: '#ffffff',
  black: '#000000'
};

const palette = {
  common: {
    colors
  },
  primary: {
    main: colors.pink
  },
  secondary: {
    main: colors.blueDark
  },
  background: {
    paper: colors.white,
    default: colors.background
  },
  action: {
    hoverOpacity: 0.16
  }
};

export default palette;
