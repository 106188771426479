import React from 'react';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import classnames from 'classnames';

import { StudentsStatusTable, withNavbar } from 'pages/Teachers/shared';
import { notifyErrors } from 'utils/notifications';

import { studentsStatuses } from './sdk';
import styles from './styles.module.css';

class TeacherAssignmentStudentsStatus extends React.Component {
  state = {
    students: null
  };

  sectionId = this.props.match.params.sectionId;

  componentDidMount() {
    this.fetchStudentsStatusData();
  }

  fetchStudentsStatusData = async () => {
    const { success, data, errors } = await studentsStatuses(this.sectionId);

    if (success) {
      this.setState({
        students: data,
        loading: false
      });
    } else {
      this.setState({ loading: false }, () => notifyErrors(errors));
    }
  };

  render() {
    const { width } = this.props;
    const { students } = this.state;

    const wrapperClassName = classnames({
      [styles.wrapper]: isWidthUp('lg', width),
      [styles.smallWrapper]: !isWidthUp('lg', width)
    });

    return (
      <div className={wrapperClassName}>
        <TableContainer component={Paper}>
          <StudentsStatusTable students={students} />
        </TableContainer>
      </div>
    );
  }
}

export default withWidth()(
  withNavbar(TeacherAssignmentStudentsStatus, { title: 'Students' })
);
