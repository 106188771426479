import { useEffect, useState } from 'react';

import { parseParams } from 'utils/urls';

const defaultStudentGroupFilterValues = {
  studentGroupName: '',
  student: '',
  tutor: ''
};

export const useDefaultFilters = (history) => {
  const [defaultFilters, setDefaultFilters] = useState({});

  useEffect(() => {
    const queryParams = parseParams(history.location.search, {
      parseNumbers: true
    });

    const defaultFilterValues = {
      ...defaultStudentGroupFilterValues,
      ...queryParams
    };

    setDefaultFilters(defaultFilterValues);
  }, [history]);

  return defaultFilters;
};
