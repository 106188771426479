import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

import { AssignmentStatusOptions } from 'utils/constants';

import Typography from 'components/Typography';

import { STATUS_COLORS, STATUS_ICONS } from './constants';
import styles from './styles.module.css';

const ChangeStatusDropdown = ({ changeStatus }) => {
  return (
    <div>
      {_.map(
        _.omit(STATUS_ICONS, [
          AssignmentStatusOptions.ASSIGNED,
          AssignmentStatusOptions.SUBMITTED
        ]),
        (statusIcon, status) => (
          <div
            key={status}
            className={styles.statusWrapper}
            onClick={() => {
              changeStatus(status);
            }}
          >
            <SvgIcon
              component={statusIcon}
              fontSize="small"
              className={styles.dropdownIcon}
            />
            <Typography
              variant="B-Text-3"
              color={STATUS_COLORS[status]}
              display="inline"
              className={styles.statusText}
            >
              {status}
            </Typography>
          </div>
        )
      )}
    </div>
  );
};

export const ChangeStatusTooltip = ({ open, changeStatus, children }) => (
  <Tooltip
    open={open}
    arrow
    placement="bottom-start"
    disableFocusListener
    disableHoverListener
    disableTouchListener
    classes={{
      popper: styles.statusPopper,
      tooltip: styles.statusBox,
      arrow: styles.arrow
    }}
    title={<ChangeStatusDropdown changeStatus={changeStatus} />}
  >
    {children}
  </Tooltip>
);
