import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { TEACHERS_ASSIGNMENTS_RESULTS_URL } from 'config/urls';
import _ from 'lodash';
import moment from 'moment';
import { addCommentToAssignment, addCommentToSharedAssignment } from 'sdk';

import StudentWorkCard from 'pages/Teachers/Tutoring/components/StudentWorkCard';
import { AssignmentPaper } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { reverse, stringifyParams } from 'utils/urls';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const AssignmentPaperHeader = ({ assignment }) => (
  <Typography
    variant="H-TEXT-3"
    color={colors.blue2}
    component={Link}
    to={{
      pathname: reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
        trackerId: assignment.tracker_id
      }),
      search: stringifyParams({
        section: assignment.tracker_instance_section_id
      })
    }}
    className={styles.link}
  >
    {assignment.name}
  </Typography>
);

const AssignmentInfoPaper = ({ assignment: apiAssignment, studentGroup }) => {
  const [assignment, setAssignment] = useState(apiAssignment);

  const handleAddAssignmentComment = useCallback(
    (event, value) => {
      if (value === '') return;

      const sharedAssignmentId = _.get(
        assignment,
        'shared_tracker_instance_section_id'
      );

      const id = sharedAssignmentId || assignment.id;

      // Add comment to the shared assignment if it is a shared one.
      // Otherwise - add it to the "normal" assignment
      const sdk = sharedAssignmentId
        ? addCommentToSharedAssignment
        : addCommentToAssignment;

      const parent = _.get(assignment.comments, '0.id');

      sdk({ id, text: value, parent }).then((data) => {
        setAssignment({
          ...assignment,
          comments: [...assignment.comments, data]
        });
      });
    },
    [assignment, setAssignment]
  );

  return (
    <>
      <AssignmentPaper
        assignment={assignment}
        assignmentTasksCount={_.get(assignment, 'tasks_count', 0)}
        headerComponent={<AssignmentPaperHeader assignment={assignment} />}
        comments={_.orderBy(assignment.comments, (x) => moment(x.created_at))}
        addComment={handleAddAssignmentComment}
      />
      <div className={styles.tasksContainer}>
        {assignment.students.map((studentTasksOnAssignment) => (
          <StudentWorkCard
            key={studentTasksOnAssignment.id}
            assignmentId={assignment.id}
            trackerId={assignment.tracker_id}
            studentData={_.omit(studentTasksOnAssignment, [
              'tasks',
              'comments'
            ])}
            initalComments={studentTasksOnAssignment.comments}
            tasksData={studentTasksOnAssignment.tasks}
          />
        ))}
      </div>
    </>
  );
};

export default AssignmentInfoPaper;
