import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Field, Formik } from 'formik';
import _ from 'lodash';

import Flex from 'components/Flex';

import { ReactComponent as addCommentIcon } from './assets/add_comment.svg';
import styles from './styles.module.css';

const initialValues = { text: '' };

class Comments extends React.Component {
  render() {
    const {
      comments,
      addComment,
      theme: {
        palette: {
          common: { colors }
        }
      }
    } = this.props;

    return (
      <div className={styles.commentSection}>
        <div className={styles.comments}>
          {_.map(comments, (comment) => (
            <p
              key={comment.id}
              className={classnames(styles.comment, {
                [styles.isStudent]: comment.user.is_student
              })}
            >
              <span className={styles.author}>
                {_.isEmpty(comment.user.preferred_name)
                  ? comment.user.last_name
                  : comment.user.preferred_name}
              </span>
              <span className={styles.text}>{comment.text}</span>
            </p>
          ))}
        </div>

        <Formik initialValues={initialValues} onSubmit={addComment}>
          {({ isSubmitting, handleSubmit, submitForm, dirty }) => (
            <form onSubmit={handleSubmit}>
              <Flex spaceBetween>
                <Field
                  className={styles.input}
                  type="text"
                  name="text"
                  placeholder="Add a comment..."
                />
                <IconButton
                  disabled={isSubmitting || !dirty}
                  onClick={() => !isSubmitting && dirty && submitForm()}
                >
                  <SvgIcon
                    titleAccess="Add comment"
                    component={addCommentIcon}
                    htmlColor={
                      isSubmitting || !dirty ? colors.grey4 : colors.blueDarker
                    }
                  />
                </IconButton>
              </Flex>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withTheme(Comments);
