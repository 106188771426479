import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import RestoreRoundedIcon from '@material-ui/icons/RestoreRounded';
import { TEACHERS_ASSIGNMENTS_RESULTS_URL } from 'config/urls';
import _ from 'lodash';

import { StudentLinkCopyButton } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import {
  buildGoogleClassroomOptions,
  buildGoogleClassroomWidgetId,
  renderGoogleClassroomWidget
} from 'utils/integrations/googleClassroom';
import { calendarDate } from 'utils/moment';
import { reverse } from 'utils/urls';

import DateTimeField from 'components/DateTimeField';
import Typography from 'components/Typography';

import { ReactComponent as TrashIcon } from './assets/trash.svg';
import styles from './styles.module.css';

const DATEPICKER_FORMAT = 'ddd MMM DD [at] hh:mm A';

export const AssignmentListByInstance = ({
  assignmentDetails,
  updateOpenDate,
  updateDueDate,
  softDelete,
  restore
}) => {
  const { instances } = assignmentDetails;

  // Important:
  // Soon, we may show all instances, no matter whether they have "active" assignments or not.
  // Revisit this once we decide to do so.
  const instancesWithAssignments = useMemo(
    () => _.filter(instances, (instance) => !_.isEmpty(instance.assignments)),
    [instances]
  );

  const buildAssignmentResultsUrl = (sectionId) => {
    const url = reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
      trackerId: assignmentDetails.id
    });

    return `${url}?section=${sectionId}`;
  };

  const getAssignmentName = (assignment) => {
    const assignmentName = (
      <Typography noWrap variant="S-TEXT-1" color={colors.blue1}>
        {assignment.name}
      </Typography>
    );

    if (assignment.deleted) {
      return assignmentName;
    }

    return (
      <Link
        to={buildAssignmentResultsUrl(assignment.id)}
        className={styles.link}
      >
        {assignmentName}
      </Link>
    );
  };

  useEffect(() => {
    _.forEach(instancesWithAssignments, (instance) =>
      renderGoogleClassroomWidget(
        buildGoogleClassroomWidgetId(instance.id),
        buildGoogleClassroomOptions(
          assignmentDetails,
          instance.student_deeplink
        )
      )
    );
  }, [assignmentDetails, instancesWithAssignments]);

  return (
    <>
      {_.map(instancesWithAssignments, (instance) => (
        <div key={instance.id}>
          <div className={styles.header}>
            <Typography variant="B-Text-2" color={colors.grey1}>
              Assigned {calendarDate(instance.created_at)} by{' '}
              {instance.created_by.name}
            </Typography>
            <div className={styles.googleClassroomWidget}>
              <div id={buildGoogleClassroomWidgetId(instance.id)}></div>
              <Typography variant="S-TEXT-1" color={colors.blueDark}>
                Share to Google Classroom
              </Typography>
            </div>
            <StudentLinkCopyButton deeplink={instance.student_deeplink} />
          </div>
          {_.map(instance.assignments, (assignment) => (
            <div key={assignment.id} className={styles.assignment}>
              {getAssignmentName(assignment)}

              <div className={styles.dateContainer}>
                <Typography variant="B-Text-2" color={colors.grey2}>
                  Open:
                </Typography>
                <DateTimeField
                  disablePast={false}
                  format={DATEPICKER_FORMAT}
                  value={assignment.open_at}
                  className={styles.datePicker}
                  disabled={assignment.deleted}
                  onChange={(openDate) =>
                    updateOpenDate(instance.id, assignment.id, openDate)
                  }
                />
              </div>

              <div className={styles.dateContainer}>
                <Typography variant="B-Text-2" color={colors.grey2}>
                  Due:
                </Typography>
                <DateTimeField
                  disablePast={false}
                  format={DATEPICKER_FORMAT}
                  value={assignment.due_at}
                  className={styles.datePicker}
                  disabled={assignment.deleted}
                  onChange={(dueDate) =>
                    updateDueDate(instance.id, assignment.id, dueDate)
                  }
                />
              </div>
              <div>
                {!assignment.deleted && (
                  <IconButton
                    size="small"
                    onClick={() => softDelete(instance.id, assignment.id)}
                  >
                    <TrashIcon />
                  </IconButton>
                )}
                {assignment.deleted && (
                  <IconButton
                    size="small"
                    onClick={() => restore(instance.id, assignment.id)}
                  >
                    <RestoreRoundedIcon />
                  </IconButton>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
