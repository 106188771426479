import React, { useCallback, useContext, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import cx from 'classnames';
import { TEACHERS_SUBSCRIPTIONS_DASHBOARD_URL } from 'config/urls';
import _ from 'lodash';

import { withNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { notifyErrors } from 'utils/notifications';
import { subscriptionState } from 'utils/subscriptions';

import Button from 'components/Button';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import {
  PlanDowngradeConfirmationDialog,
  PlanUpgradePaymentDialog
} from './components';
import { fetchCustomer, fetchSubscriptionTemplates } from './sdk';
import styles from './styles.module.css';

const PlansSkeleton = () => (
  <Grid
    container
    item
    xs
    alignItems="center"
    justify="center"
    spacing={3}
    className={styles.plansContainer}
  >
    {_.times(3, (n) => (
      <Grid item key={n}>
        <Skeleton variant="rect" width={300} height={390} />
      </Grid>
    ))}
  </Grid>
);

const PlanFeature = ({ name, textClassName }) => (
  <Grid container className={styles.planFeature}>
    <Grid item className={styles.icon}>
      <CheckCircleRoundedIcon fontSize="small" htmlColor={colors.grey5} />
    </Grid>
    <Grid item>
      <Typography
        variant="B-Text-1"
        color={colors.grey1}
        className={cx(styles.planFeatureName, textClassName)}
      >
        {name}
      </Typography>
    </Grid>
  </Grid>
);

const Plans = ({
  user,
  customer,
  freeSubscriptionTemplate,
  paidSubscriptionTemplate,
  openDowngradeDialog,
  openUpgradeDialog
}) => {
  const getLimitRepresentation = (limit) =>
    limit === null ? 'Unlimited' : limit;

  const freeSubscriptionTemplateImagesLimit = getLimitRepresentation(
    freeSubscriptionTemplate.images_limit
  );

  const paidSubscriptionTemplateImagesLimit = getLimitRepresentation(
    paidSubscriptionTemplate.images_limit
  );
  const paidSubscriptionTemplateAssignmentsLimit = getLimitRepresentation(
    paidSubscriptionTemplate.assignments_limit
  );

  const freePlanName = freeSubscriptionTemplate.display_name;
  const paidPlanName = paidSubscriptionTemplate.display_name;

  const { freeButtonState, paidButtonState } = subscriptionState({
    currentSubscription: customer.subscription,
    lastSubscription: customer.last_subscription,
    freeSubscriptionTemplate,
    paidSubscriptionTemplate
  });

  return (
    <Grid
      container
      item
      xs
      alignItems="center"
      justify="center"
      spacing={3}
      className={styles.plansContainer}
    >
      <Grid item>
        <Paper variant="outlined" className={styles.planPaper}>
          <Typography
            variant="H-TEXT-3"
            align="center"
            color={colors.blue3}
            className={styles.bold}
          >
            {freePlanName}
          </Typography>
          <div className={styles.planPriceContainer}>
            <Typography
              variant="H-TEXT-3"
              align="center"
              color={colors.blue3}
              className={styles.freePlanPriceText}
            >
              $0
            </Typography>
          </div>
          <Box marginTop={3}>
            <PlanFeature
              name={`${freeSubscriptionTemplateImagesLimit} Student Images`}
            />
            <PlanFeature name="Up to 2 Co-Teachers" />
            <PlanFeature name="Google Classroom Roster Integration" />
          </Box>
          <Button
            color="lightGrey"
            className={styles.choosePlanButton}
            onClick={openDowngradeDialog}
            disabled={freeButtonState.disabled}
          >
            {freeButtonState.text}
          </Button>
        </Paper>
      </Grid>
      <Grid item>
        <Paper variant="outlined" className={styles.planPaper}>
          <Typography
            variant="H-TEXT-3"
            align="center"
            color={colors.pink1}
            className={styles.bold}
          >
            {paidPlanName}
          </Typography>
          <div className={styles.planPriceContainer}>
            <Typography variant="H-TEXT-1" align="center" color={colors.pink1}>
              ${paidSubscriptionTemplate.yearly_amount.amount / 12} / month
            </Typography>
            <Typography variant="S-TEXT-4" align="center" color={colors.grey3}>
              Billed annually or
            </Typography>
            <Typography variant="S-TEXT-4" align="center" color={colors.grey3}>
              ${paidSubscriptionTemplate.monthly_amount.amount} month-to-month
            </Typography>
          </div>
          <Box marginTop={3}>
            <PlanFeature
              name={`${paidSubscriptionTemplateImagesLimit} images`}
            />
            <PlanFeature
              name={`${paidSubscriptionTemplateAssignmentsLimit} assignments`}
            />
            <PlanFeature name="Data integrations" />
          </Box>
          <Button
            color="pink"
            className={styles.choosePlanButton}
            onClick={openUpgradeDialog}
            disabled={paidButtonState.disabled}
          >
            {paidButtonState.text}
          </Button>
        </Paper>
      </Grid>
      <Grid item>
        <Paper variant="outlined" className={styles.planPaper}>
          <Typography
            variant="H-TEXT-3"
            align="center"
            color={colors.blue3}
            className={styles.bold}
          >
            School / District
          </Typography>
          <div className={styles.planPriceContainer}>
            <Typography variant="H-TEXT-1" align="center" color={colors.blue3}>
              Contact us
            </Typography>
            <Typography variant="H-TEXT-1" align="center" color={colors.blue3}>
              for pricing
            </Typography>
          </div>
          <Box>
            <Typography variant="B-Text-1" color={colors.grey1}>
              Everything in {paidPlanName}, plus...
            </Typography>
            <PlanFeature
              name="Central roster management"
              textClassName={styles.centralRosterManagementFeature}
            />
            <PlanFeature name="Reporting for coaches" />
            <PlanFeature name="Shared templates" />
          </Box>
          <Button
            color="blue"
            className={styles.choosePlanButton}
            href={`https://calendly.com/edlight/upgrade/?name=${user.name}&email=${user.email}`}
            target="_blank"
          >
            Contact us
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};

const ChangePlan = ({ history }) => {
  const { user } = useContext(UserContext);

  const [customer, setCustomer] = useState(null);
  const [subscriptionTemplates, setSubscriptionTemplates] = useState(null);

  const [isDowngradeDialogOpened, setIsDowngradeDialogOpened] = useState(false);
  const [isUpgradeDialogOpened, setIsUpgradeDialogOpened] = useState(false);

  const getCustomerData = useCallback(async () => {
    const { success, data, errors } = await fetchCustomer();

    if (success) {
      setCustomer(data);
    } else {
      notifyErrors(errors);
    }
  }, []);

  useEffect(() => {
    async function getSubscriptionTemplates() {
      const { success, data, errors } = await fetchSubscriptionTemplates();

      if (success) {
        setSubscriptionTemplates(data);
      } else {
        notifyErrors(errors);
      }
    }

    getCustomerData();
    getSubscriptionTemplates();
  }, [getCustomerData]);

  const openDowngradeDialog = () => setIsDowngradeDialogOpened(true);
  const closeDowngradeDialog = () => {
    setIsDowngradeDialogOpened(false);
    getCustomerData();
  };

  const openUpgradeDialog = () => setIsUpgradeDialogOpened(true);
  const closeUpgradeDialog = () => {
    setIsUpgradeDialogOpened(false);
    getCustomerData();
  };

  const redirectToDashboard = () =>
    history.push(TEACHERS_SUBSCRIPTIONS_DASHBOARD_URL);

  const loading = customer === null || subscriptionTemplates === null;

  const freeSubscriptionTemplate = _.head(
    _.filter(subscriptionTemplates, { is_free: true })
  );
  const paidSubscriptionTemplate = _.head(
    _.filter(subscriptionTemplates, { is_free: false })
  );

  return (
    <>
      <Grid container direction="column" alignItems="center" justify="center">
        <Grid item xs>
          <Typography
            variant="H-TEXT-1"
            color={colors.blue3}
            className={styles.letsGetStartedText}
          >
            Select a plan
          </Typography>
        </Grid>
        {loading && <PlansSkeleton />}
        {!loading && (
          <Plans
            user={user}
            customer={customer}
            freeSubscriptionTemplate={freeSubscriptionTemplate}
            paidSubscriptionTemplate={paidSubscriptionTemplate}
            openDowngradeDialog={openDowngradeDialog}
            openUpgradeDialog={openUpgradeDialog}
          />
        )}
      </Grid>
      {isDowngradeDialogOpened && (
        <PlanDowngradeConfirmationDialog
          customer={customer}
          onClose={closeDowngradeDialog}
          redirectToDashboard={redirectToDashboard}
        />
      )}
      {isUpgradeDialogOpened && (
        <PlanUpgradePaymentDialog
          customer={customer}
          paidSubscriptionTemplate={paidSubscriptionTemplate}
          onClose={closeUpgradeDialog}
          redirectToDashboard={redirectToDashboard}
        />
      )}
    </>
  );
};

export default withNavbar(ChangePlan, { title: 'Change subscription' });
