import _ from 'lodash';
import queryString from 'query-string';

export const reverse = (url, params = {}) => {
  let result = url;

  _.forEach(params, (value, key) => {
    result = result.replace(`:${key}`, value);
  });

  return result;
};

export const openInNewTab = (url) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: url
  }).click();
};

export const stringifyParams = (params, options = {}) =>
  queryString.stringify(params, options);

export const parseParams = (search, options = {}) =>
  queryString.parse(search, options);

export const base64UrlSafeEncodeString = (string) => {
  const encodedString = Buffer.from(string).toString('base64');

  return encodedString.replace('+', '-').replace('/', '_');
};
