import { BASE_URL } from 'config/urls';

import { callUrl, get } from 'utils/sdk';

export const fetchSessionsAssignedToMe = (params) =>
  callUrl(
    get,
    `${BASE_URL}/v1/trackers/shared-tracker-instance-sections/assigned-to-me/`,
    params
  );
