import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import MuiAudioPlayer from 'material-ui-audio-player';

import styles from './styles.module.css';

const useAudioPlayerStyles = makeStyles(() => ({
  root: {
    margin: 0,
    // Aligns the time of the player with the main slider
    '& div:last-child': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  volumeIcon: {
    '& + div': {
      // Selects the first div after the icon component which is the container of the slider
      // and fixes a z-index problem. NOTE: We cannot use the `volumeSlider` class to fix this.
      zIndex: 1
    }
  },
  mainSlider: {
    // Fixes a bug with the time alignment
    paddingBottom: 10
  }
}));

// NOTE: We visualize only the last audio file until we have everything implemented
const AudioPlayer = ({ audioFiles }) => {
  const lastAudioFile = _.last(audioFiles);
  const audioSrc = _.get(lastAudioFile, 'file.file_url');

  if (!audioSrc) {
    return null;
  }

  return (
    <div className={styles.container}>
      <MuiAudioPlayer
        src={audioSrc}
        elevation={0}
        spacing={2}
        variation="primary"
        useStyles={useAudioPlayerStyles}
        width="95%"
        download={true}
        loop={false}
        debug={false}
      />
    </div>
  );
};

export default AudioPlayer;
