import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.css';

const TutoringTooltipContent = React.forwardRef(
  ({ disabled, onTutoringTooltipClick, openAssignStudentGroupDialog }, ref) => (
    <div className={styles.content} ref={ref}>
      <div
        className={classnames(styles.tab, { [styles.disabled]: disabled })}
        onClick={() => {
          if (!disabled) {
            openAssignStudentGroupDialog();
            onTutoringTooltipClick();
          }
        }}
      >
        <Typography variant="H-TEXT-3" color={colors.white}>
          Assign to Tutoring
        </Typography>
      </div>
      <div className={styles.tab} onClick={onTutoringTooltipClick}>
        <Typography variant="S-TEXT-1" color={colors.white}>
          Cancel
        </Typography>
      </div>
    </div>
  )
);

TutoringTooltipContent.displayName = 'TutoringTooltipContent';

const TutoringTooltip = ({
  onTutoringTooltipClick,
  open,
  openAssignStudentGroupDialog,
  canAssign = true,
  children
}) => {
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true
      }}
      classes={{
        tooltip: styles.tooltip,
        popper: styles.popper
      }}
      onClose={onTutoringTooltipClick}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={
        <TutoringTooltipContent
          disabled={!canAssign}
          onTutoringTooltipClick={onTutoringTooltipClick}
          openAssignStudentGroupDialog={openAssignStudentGroupDialog}
        />
      }
    >
      {children}
    </Tooltip>
  );
};

export default TutoringTooltip;
