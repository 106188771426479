export const excludeAssignedFilterOptions = [
  {
    key: 'exclude_assigned',
    value: 'true',
    label: 'All Submitted'
  },
  {
    key: 'all',
    value: 'false',
    label: 'All Assigned'
  }
];
