import React, { useCallback, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import cx from 'classnames';
import {
  STUDENTS_ASSIGNMENTS_LIST_URL,
  STUDENTS_ASSIGNMENTS_OVERVIEW_URL
} from 'config/urls';
import _ from 'lodash';

import { Typography, withNavbar } from 'pages/Students/shared';
import { colors } from 'theme/palette';
import { formatDate } from 'utils/moment';
import { reverse } from 'utils/urls';

import {
  archiveNotifications,
  getAllNotifications,
  markAllUnseenNotificationsAsSeen
} from './sdk';
import styles from './styles.module.css';
import { NotificationTypeIconAndText } from './utils';

const NewNotificationBadge = () => (
  <div className={styles.newBadge} style={{ backgroundColor: colors.yellow5 }}>
    <Typography variant="S-TEXT-3" align="center" color={colors.yellow1}>
      New
    </Typography>
  </div>
);

const Notification = ({
  data: {
    seen,
    notification_type,
    tracker,
    tracker_instance_section,
    created_by,
    created_at
  },
  history,
  lgScreen
}) => {
  const { text, IconComponent } =
    NotificationTypeIconAndText[notification_type];

  const handleClick = () => {
    const assignmentDetailUrl = reverse(STUDENTS_ASSIGNMENTS_OVERVIEW_URL, {
      assignmentId: tracker_instance_section.id
    });

    history.push({
      pathname: assignmentDetailUrl
    });
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      onClick={handleClick}
      className={cx(styles.notification, 'pointer')}
    >
      <Grid
        item
        container
        justify="center"
        style={{ width: lgScreen ? 92 : 64 }}
      >
        <Grid item>
          <IconComponent className={styles.icon} />
        </Grid>
      </Grid>
      <Grid container item xs spacing={1}>
        <Grid container item justify="space-between">
          <Grid item>
            <Typography variant="H-TEXT-3" color={colors.blue3}>
              {text}
            </Typography>
          </Grid>
          <Grid item>{!seen && <NewNotificationBadge />}</Grid>
        </Grid>
        <Grid container item>
          <Grid item>
            <Typography variant="B-Text-3" color={colors.grey1}>
              {tracker.name}
            </Typography>
          </Grid>
          <Grid container item justify="space-between">
            <Grid item>
              <Typography
                variant={lgScreen ? 'S-TEXT-3' : 'S-TEXT-4'}
                color={colors.grey3}
              >
                {created_by.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant={lgScreen ? 'S-TEXT-3' : 'S-TEXT-4'}
                color={colors.grey3}
              >
                {formatDate(created_at, 'ddd MM/D [at] h:mm a')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const NotificationsSkeleton = () => (
  <Grid container direction="column">
    {_.map(_.range(3), (index) => (
      <Grid item key={index} xs={12}>
        <Box display="flex" padding={2}>
          <Skeleton variant="rect" width={70} height={70} />
          <Box paddingLeft={1} width="100%">
            <Skeleton width="50%" />
            <Skeleton width="30%" />
            <Skeleton />
          </Box>
        </Box>
        {index !== 2 && <Divider />}
      </Grid>
    ))}
  </Grid>
);

const NoNotifications = () => (
  <Box display="flex" flexDirection="column" marginTop={3.5}>
    <div className={styles.noNotifications}>
      <div className={styles.noNotificationsHeader}>
        <Typography variant="H-TEXT-3" color={colors.grey4} align="center">
          No new
        </Typography>
        <Typography variant="H-TEXT-3" color={colors.grey4} align="center">
          Notifications
        </Typography>
      </div>
    </div>
  </Box>
);

const NotificationsTitle = ({ count }) => (
  <Box display="flex">
    Notifications
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: colors.pink2 }}
      className={styles.titleCount}
    >
      <Typography variant="S-TEXT-3" color={colors.white}>
        {count}
      </Typography>
    </Box>
  </Box>
);

const StudentNotificationsList = ({
  history,
  width,
  navigation: { setBackButtonAction, setClearButtonAction, setTitle }
}) => {
  const [notifications, setNotifications] = useState({});
  const [loading, setLoading] = useState(true);

  // TODO: Move to common usePagination hook
  const limit = 20;
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(1);

  const archive = useCallback(() => {
    if (!_.isEmpty(notifications) && !loading) {
      archiveNotifications();
      setNotifications({});
    }
  }, [notifications, loading]);

  const lgScreen = isWidthUp('sm', width);

  const fetchNotifications = ({ page }) =>
    new Promise((resolve) => {
      setLoading(true);

      const offset = limit * (page - 1);

      getAllNotifications({ limit, offset }).then(({ data }) => {
        setNotifications(data.results);

        const numPages = _.ceil(data.count / data.limit);
        setNumPages(numPages);

        setLoading(false);

        return resolve();
      });
    });

  const goBack = useCallback(() => {
    // If there's no `prevPage` passed in the state -> redirect to assignments list
    const prevPath = _.get(
      history.location.state,
      'prevPath',
      STUDENTS_ASSIGNMENTS_LIST_URL
    );
    history.push(prevPath);
  }, [history]);

  useEffect(() => {
    fetchNotifications({ page }).then(markAllUnseenNotificationsAsSeen);
  }, [page]);

  useEffect(() => {
    setClearButtonAction(archive);
  }, [setClearButtonAction, archive]);

  useEffect(() => {
    const newNotifications = _.size(_.filter(notifications, (x) => !x.seen));

    if (newNotifications > 0) {
      setTitle(<NotificationsTitle count={newNotifications} />);
    }
  }, [setTitle, notifications]);

  useEffect(() => {
    setBackButtonAction(goBack);
  }, [setBackButtonAction, goBack]);

  if (loading) {
    return <NotificationsSkeleton />;
  }

  if (!loading && _.isEmpty(notifications)) {
    return <NoNotifications />;
  }

  return (
    <div>
      {_.map(notifications, (notification) => (
        <div key={notification.id}>
          <Notification
            data={notification}
            history={history}
            lgScreen={lgScreen}
          />
          <Divider />
        </div>
      ))}
      {numPages > 1 && (
        <Pagination
          className={styles.pagination}
          shape="rounded"
          count={numPages}
          page={page}
          onChange={(_, page) => setPage(page)}
        />
      )}
    </div>
  );
};

export default withWidth()(
  withNavbar(StudentNotificationsList, { title: 'Notifications' })
);
