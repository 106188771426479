import React from 'react';

import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import SearchFilter from 'components/SearchFilter';
import SelectFilter from 'components/SelectFilter';

import styles from './styles.module.css';

const Filters = ({
  filters,
  user,
  selectedUser,
  authorOptions,
  filterByAuthor,
  assignmentsOptions,
  filterByAssignmentName,
  sectionsOptions,
  filterBySectionName,
  yearsOptions,
  filterByYear
}) => {
  const getUserNameForSearch = (selectedUser) => {
    const selectedUserId = _.get(selectedUser, 'id');
    const selectedUserName = _.get(selectedUser, 'name');

    return user.id === selectedUserId ? 'Me' : selectedUserName;
  };

  const getUserSearchOptionLabel = (selectedUser) => {
    const userName = getUserNameForSearch(selectedUser);
    return `Created by: ${userName}`;
  };

  const yearsOptionsForFilter = _.map(yearsOptions, (year) => {
    const startYear = new Date(year.naive_start_date).getFullYear();
    const endYear = new Date(year.naive_end_date).getFullYear();
    const label = `${startYear} - ${endYear}`;

    return {
      label,
      value: year.id
    };
  });

  return (
    <Grid container className={styles.filters} spacing={3} alignItems="center">
      <Grid item xs>
        <SearchFilter
          autoComplete
          value={selectedUser}
          options={authorOptions}
          renderOption={getUserNameForSearch}
          getOptionLabel={getUserSearchOptionLabel}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={filterByAuthor}
          placeholder="Created by"
        />
      </Grid>
      <Grid item xs>
        <SearchFilter
          freeSolo
          autoComplete
          value={_.get(filters, 'name', '')}
          options={_.map(assignmentsOptions, 'name')}
          onChange={filterByAssignmentName}
          placeholder="Search assignments"
        />
      </Grid>
      <Grid item xs>
        <SearchFilter
          freeSolo
          autoComplete
          value={_.get(filters, 'section', '')}
          options={_.map(sectionsOptions, 'name')}
          onChange={filterBySectionName}
          placeholder="Class"
        />
      </Grid>
      <Grid item xs={3} md={2}>
        <SelectFilter
          value={_.get(filters, 'year', '')}
          options={yearsOptionsForFilter}
          onChange={(e) => filterByYear(e.target.value)}
          placeholder="Years"
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
