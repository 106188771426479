import { BASE_URL } from 'config/urls';

import { callUrl, get } from 'utils/sdk';

export const fetchStudentPortfolio = ({ studentId, filters, offset, limit }) =>
  callUrl(get, `${BASE_URL}/v1/trackers/student-portfolio/${studentId}/`, {
    ...filters,
    offset,
    limit
  });
