import React, { memo } from 'react';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const SectionStudentsList = ({ students }) => (
  <div className={styles.container}>
    {students.map((student) => (
      <div key={student.id} className={styles.row}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {student.name}
        </Typography>
        <div className={styles.extraInformation}>
          <Typography
            className={styles.studentId}
            variant="S-TEXT-1"
            color={colors.grey3}
          >
            {student.local_student_id}
          </Typography>
          <Typography variant="S-TEXT-1" color={colors.grey3}>
            {student.grade_ordinal}
          </Typography>
        </div>
      </div>
    ))}
  </div>
);

export default memo(
  SectionStudentsList,
  (prevProps, nextProps) =>
    prevProps.students?.map((student) => student.id) ===
    nextProps.students?.map((student) => student.id)
);
