import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import {
  TEACHERS_ASSIGNMENTS_DETAIL_URL,
  TEACHERS_ASSIGNMENTS_FEEDBACK_URL,
  TEACHERS_ASSIGNMENTS_RESULTS_URL
} from 'config/urls';
import _ from 'lodash';

import { calendarDate } from 'utils/moment';
import { reverse, stringifyParams } from 'utils/urls';

import { ReactComponent as AssignedIcon } from './assets/assigned.svg';
import { ReactComponent as CreateIcon } from './assets/create.svg';
import { ReactComponent as ReviewIcon } from './assets/review.svg';
import { ReactComponent as ScoreIcon } from './assets/score.svg';
import { ReactComponent as SubmitIcon } from './assets/submit.svg';
import styles from './styles.module.css';

export const ActivityMapping = (action) =>
  _.get(
    {
      created: {
        icon: <SvgIcon component={CreateIcon} className={styles.actionIcon} />,
        object: action.actor.name,
        verb: action.verb,
        actionObject: action.data.tracker_name,
        conjunction: null,
        target: null,
        timestamp: calendarDate(action.timestamp),
        // TODO: Handle Trackers!
        linkTo: reverse(TEACHERS_ASSIGNMENTS_DETAIL_URL, {
          trackerId: action.data.tracker_id
        })
      },
      updated: {
        icon: <SvgIcon component={CreateIcon} className={styles.actionIcon} />,
        object: action.actor.name,
        verb: action.verb,
        actionObject: action.data.tracker_name,
        conjunction: null,
        target: null,
        timestamp: calendarDate(action.timestamp),
        linkTo: reverse(TEACHERS_ASSIGNMENTS_DETAIL_URL, {
          trackerId: action.data.tracker_id
        })
      },
      assigned: {
        icon: (
          <SvgIcon component={AssignedIcon} className={styles.actionIcon} />
        ),
        object: action.actor.name,
        verb: action.verb,
        actionObject: action.data.tracker_name,
        conjunction: 'to',
        target: action.data.section_name,
        timestamp: calendarDate(action.timestamp),
        linkTo: reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
          trackerId: action.data.tracker_id
        })
      },
      reviewed: {
        icon: <SvgIcon component={ScoreIcon} className={styles.actionIcon} />,
        object: action.actor.name,
        verb: action.verb,
        actionObject: null,
        conjunction: null,
        target: action.data.tracker_name,
        timestamp: calendarDate(action.timestamp),
        linkTo: reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
          trackerId: action.data.tracker_id
        })
      },
      submitted: {
        icon: <SvgIcon component={SubmitIcon} className={styles.actionIcon} />,
        object: action.actor.name,
        verb: action.verb,
        actionObject: null,
        conjunction: null,
        target: action.data.tracker_name,
        timestamp: calendarDate(action.timestamp),
        linkTo: `${reverse(TEACHERS_ASSIGNMENTS_FEEDBACK_URL, {
          trackerId: action.data.tracker_id
        })}?${stringifyParams({
          section: action.data.tracker_instance_section_id,
          student: action.data.student_id
        })}`
      },
      revised: {
        icon: <SvgIcon component={ReviewIcon} className={styles.actionIcon} />,
        object: action.actor.name,
        verb: action.verb,
        actionObject: null,
        conjunction: null,
        target: action.data.tracker_name,
        timestamp: calendarDate(action.timestamp),
        linkTo: `${reverse(TEACHERS_ASSIGNMENTS_FEEDBACK_URL, {
          trackerId: action.data.tracker_id
        })}?${stringifyParams({
          section: action.data.tracker_instance_section_id,
          student: action.data.student_id
        })}`
      }
    },
    action.verb
  );
