import React, { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useCurrentSchoolYear } from 'sdk';
import { noopPromise } from 'utils';

import { ViewToggle } from 'pages/Teachers/StudentPortfolio/components';
import { useNavbar } from 'pages/Teachers/shared/TeacherPageLayout';
import { SUBMITTED_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION } from 'utils/constants';
import { formatDate } from 'utils/moment';
import { useFetch } from 'utils/sdk';
import { parseParams, stringifyParams } from 'utils/urls';

import { fetchStudentPortfolio } from './sdk.js';

const parseDateFilters = (filters) => ({
  startDate: moment(filters.startDate, 'MM/DD/YYYY'),
  endDate: moment(filters.endDate, 'MM/DD/YYYY')
});

const validateFilterDates = (filters) => {
  const { startDate, endDate } = parseDateFilters(filters);

  return (
    startDate.isValid() &&
    endDate.isValid() &&
    String(filters.startDate).indexOf('_') === -1 &&
    String(filters.endDate).indexOf('_') === -1 &&
    startDate.isBefore(endDate)
  );
};

export const validateFilters = (filters) => {
  return validateFilterDates(filters);
};

const prepareDateFilters = ({ startDate, endDate }) => ({
  start_date: formatDate(startDate, 'YYYY-MM-DD'),
  end_date: formatDate(endDate, 'YYYY-MM-DD')
});

export const prepareAssignmentFilters = (filters) => ({
  assignment: filters.assignment,
  sections: filters.sections,
  status: filters.status,
  standards: filters.standards,
  ...prepareDateFilters(parseDateFilters(filters))
});

const limit = 20;
export const useInfiniteScroll = (hook, params) => {
  const [page, setPage] = useState(0);
  const [resultsCount, setResultsCount] = useState();
  // add end of loading when received last page

  const requestData = useMemo(
    () => ({ ...params, offset: page * limit, limit }),
    [params, page]
  );

  const triggerFetch = useMemo(() => !_.isNull(params), [params]);
  const result = hook(requestData, triggerFetch);

  const [aggregatedResults, setAggregatedResults] = useState({
    data: { results: [] },
    loading: true
  });

  useEffect(() => {
    if (!result.loading) {
      setAggregatedResults((x) => {
        const obj = {
          ...x,
          ...result,
          data: {
            ...result.data,
            results: [...x.data.results, ...result.data.results]
          }
        };
        return obj;
      });
    }
    if (result.loading) {
      setAggregatedResults((x) => ({ ...x, loading: true }));
    }
  }, [result]);

  useEffect(() => {
    if (!resultsCount) {
      setResultsCount(result.data.count);
    }
  }, [result, resultsCount]);

  const loadNextPage = useCallback(() => {
    if (resultsCount > page * limit) {
      setPage((x) => x + 1);
    }
  }, [resultsCount, page]);

  const resetPages = useCallback(({ withLoading = false }) => {
    setPage(0);
    setResultsCount(0);
    setAggregatedResults({ loading: withLoading, data: { results: [] } });
  }, []);

  return [aggregatedResults, loadNextPage, resetPages];
};

export const useStudentAssignments = (requestData, triggerFetch) => {
  const mapResponse = useCallback(
    (response) => ({
      ...response,
      results: response.results.map((assignment) => {
        const firstTask = _.head(assignment.tasks) || {};
        firstTask.isActive = true;

        const firstWork = _.head(firstTask.work) || {};
        firstWork.isActive = true;

        const dueAt = assignment.due_at || moment();
        assignment.due_at = dueAt;

        return assignment;
      })
    }),
    []
  );

  const sdk = useMemo(
    () => (triggerFetch ? fetchStudentPortfolio : noopPromise),
    [triggerFetch]
  );

  const studentAssignments = useFetch(
    sdk,
    { data: { results: [] }, loading: true },
    requestData,
    mapResponse
  );

  const data = useMemo(() => {
    // If the page is not ready to perform the request we need to make sure that it's still in a loading state
    if (!triggerFetch) {
      return { ...studentAssignments, loading: true };
    }
    return studentAssignments;
  }, [studentAssignments, triggerFetch]);

  return data;
};

/**
 * `filtersOrCallback` is either the filters object or a callback that needs the filters
 * to be calculated. Same as the callback that `setA` uses in:
 *
 * const [a, setA] = useState();
 *
 */
export const getFiltersObject = (filtersOrCallback, filters) => {
  if (typeof filtersOrCallback === 'function') {
    return filtersOrCallback(filters);
  }
  return filtersOrCallback;
};

export const useFilters = (history) => {
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (!_.isEmpty(filters)) {
      history.push({
        search: stringifyParams(filters, { arrayFormat: 'bracket' })
      });
    }
  }, [filters, history]);

  return [filters, setFilters];
};

export const useNavigation = ({
  studentName,
  onTitleClick,
  viewMode,
  setViewMode
}) => {
  const navBar = useNavbar({
    title: studentName,
    onTitleClick,
    sticky: true,
    onBackButtonClick: onTitleClick
  });

  useEffect(() => {
    navBar.navigation.setLeftSubheader(
      <ViewToggle viewMode={viewMode} setViewMode={setViewMode} />
    );
  }, [navBar.navigation, viewMode, setViewMode]);
};

const buildDefaultFilterValues = (currentYear, history) => {
  const initialFilters = {
    sections: [],
    standards: [],
    assignment: '',
    status: SUBMITTED_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION.value,
    startDate: formatDate(moment(currentYear.naive_start_date, 'YYYY-MM-DD')),
    endDate: formatDate(moment(currentYear.naive_end_date, 'YYYY-MM-DD'))
  };

  const queryParams = parseParams(history.location.search, {
    arrayFormat: 'bracket',
    parseNumbers: true
  });

  return { ...initialFilters, ...queryParams };
};

export const useDefaultFilters = (history) => {
  const { data: currentSchoolYear, isLoading: currentSchoolYearLoading } =
    useCurrentSchoolYear();

  const [defaultFilters, setDefaultFilters] = useState({});

  useEffect(() => {
    if (!currentSchoolYearLoading && currentSchoolYear) {
      const defaultFilterValues = buildDefaultFilterValues(
        currentSchoolYear,
        history
      );

      setDefaultFilters(defaultFilterValues);
    }
  }, [currentSchoolYear, currentSchoolYearLoading, history]);

  return defaultFilters;
};

export const useInitialFilters = () => {
  const { data: currentSchoolYear, isLoading: currentSchoolYearLoading } =
    useCurrentSchoolYear();

  const [initialFilters, setInitialFilters] = useState({});

  useEffect(() => {
    if (!currentSchoolYearLoading && currentSchoolYear) {
      setInitialFilters({
        sections: [],
        standards: [],
        assignment: '',
        status:
          SUBMITTED_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION.value,
        startDate: formatDate(
          moment(currentSchoolYear.naive_start_date, 'YYYY-MM-DD')
        ),
        endDate: formatDate(
          moment(currentSchoolYear.naive_end_date, 'YYYY-MM-DD')
        )
      });
    }
  }, [currentSchoolYear, currentSchoolYearLoading]);

  return initialFilters;
};
