import React, { useCallback, useMemo } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { notifyErrors } from 'utils/notifications';

import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import Typography from 'components/Typography';

import { useStandards } from './hooks';
import { addMeetingStandard } from './sdk';
import styles from './styles.module.css';

const getOptionLabel = (option) => {
  const shortDescription = _.get(option, 'short_description', '');
  if (_.isEmpty(shortDescription)) {
    return _.get(option, 'code', '');
  } else {
    return `${option.code} - ${shortDescription}`;
  }
};

const AddStandardDialog = ({
  meetingId,
  meetingStandards,
  onSuccess,
  onClose
}) => {
  const selectedStandardsIds = useMemo(
    () => _.map(meetingStandards, 'id'),
    [meetingStandards]
  );

  const { standards, setStandardSearchValue, standardSearchValue } =
    useStandards(selectedStandardsIds);

  const onStandardInputChange = useCallback(
    (event) => setStandardSearchValue(event.target.value),
    [setStandardSearchValue]
  );

  const onOptionSelect = useCallback(
    (opt) => {
      addMeetingStandard(meetingId, opt.id).then(({ errors, success }) => {
        if (!success) {
          notifyErrors(errors);
        } else {
          onSuccess(opt);
          onClose();
        }
      });
    },
    [onSuccess, onClose, meetingId]
  );

  // This is used to calculate the padding of children standards in list
  const minStandardLevel = _.min(_.map(standards, 'level'));

  return (
    <Dialog
      open
      alignTop
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <Typography
        variant="H-TEXT-2"
        color={colors.blue1}
        className={styles.label}
      >
        Add Standard
      </Typography>
      <InputField
        fullWidth
        autoFocus
        placeholder="Search Standards (e.g., RL.7.2)"
        variant="underlined"
        value={standardSearchValue}
        onChange={onStandardInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={styles.searchIcon}>
              <SearchRoundedIcon fontSize="small" htmlColor={colors.grey4} />
            </InputAdornment>
          )
        }}
      />
      {!_.isEmpty(standardSearchValue) && (
        <div className={styles.optionsContainer}>
          {_.map(standards, (standard) => {
            const standardPaddingLeft =
              (standard.level - minStandardLevel + 1) * 10;
            return (
              <div
                key={standard.id}
                onClick={() => onOptionSelect(standard)}
                className={styles.standardOption}
              >
                <Typography
                  variant="B-Text-2"
                  color={colors.grey2}
                  style={{
                    paddingLeft: `${standardPaddingLeft}px`
                  }}
                >
                  {getOptionLabel(standard)}
                </Typography>
              </div>
            );
          })}
        </div>
      )}
    </Dialog>
  );
};

export default AddStandardDialog;
