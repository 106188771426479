import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Paper from '@material-ui/core/Paper';
import { TEACHERS_STUDENT_GROUP_LIST_URL } from 'config/urls';
import _ from 'lodash';
import moment from 'moment';
import { useSection, useSectionFeed } from 'sdk';
import { useCaching } from 'utils';

import { useNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { buildFilters } from 'utils/filters';
import { stringifyParams } from 'utils/urls';

import { excludeAssignedFilterOptions } from './components/Filters/constants';
import AddButton from 'components/AddButton';
import AssignmentCreateDialogContext from 'components/AssignmentCreateDialogContext';
import AssignmentDialogContext from 'components/AssignmentDialogContext';
import NoResultsPlaceholder from 'components/NoResultsPlaceholder';
import Typography from 'components/Typography';

import {
  AddCommentButton,
  AssignmentInfoPaper,
  FeedComment,
  Filters,
  HelpText,
  RandomTutoringFeedItemSkeleton,
  TutoringFeedSkeleton
} from './components';
import { FEED_ELEMENTS } from './constants';
import styles from './styles.module.scss';

const Tutoring = ({ match, history }) => {
  const [filters, setFilters] = useState({
    exclude_assigned: excludeAssignedFilterOptions[0].value
  });

  const sectionId = match.params.id;

  const { setIsAssignmentDialogOpened, isAssignmentDialogOpened } = useContext(
    AssignmentDialogContext
  );
  const { isOpened: isAssignmentCreateDialogOpened } = useContext(
    AssignmentCreateDialogContext
  );

  const { data: section, isLoading: isLoadingSection } = useSection({
    sectionId
  });

  const sectionFeedParams = useMemo(() => buildFilters(filters), [filters]);

  const {
    size: page,
    setSize: setPage,
    data: aggregatedSectionFeedData,
    isLoading: isLoadingSectionFeed,
    isValidating: isValidatingSectionFeed,
    refetch: refetchSectionFeed
  } = useSectionFeed({ sectionId, params: sectionFeedParams });

  const aggregatedAssignments = _.reduce(
    aggregatedSectionFeedData,
    (result, value) => [
      ...result,
      ...value.results.map((item) => ({
        ...item,
        type: FEED_ELEMENTS.ASSIGNMENT
      }))
    ],
    []
  );

  const showSectionFeedPlaceholder =
    isLoadingSectionFeed || isValidatingSectionFeed;

  const handleBackClick = useCallback(() => {
    history.push(TEACHERS_STUDENT_GROUP_LIST_URL);
  }, [history]);

  const commentsList = useMemo(
    () =>
      isLoadingSection
        ? []
        : section.comments.map((comment) => ({
            ...comment,
            type: FEED_ELEMENTS.COMMENT
          })),
    [section?.comments, isLoadingSection]
  );

  const [comments, setComments] = useCaching(commentsList);

  const orderedCommentsAndAssignments = useMemo(
    () =>
      _.orderBy(
        [...comments, ...aggregatedAssignments],
        (elem) => moment(elem.created_at),
        ['desc']
      ),
    [comments, aggregatedAssignments]
  );

  const handleCommentAdd = useCallback(
    (comment) => {
      setComments((comments) => {
        return [
          ...comments,
          {
            ...comment,
            replies: [],
            type: FEED_ELEMENTS.COMMENT,
            newComment: true
          }
        ];
      });
    },
    [setComments]
  );

  useEffect(() => {
    // If the create assignment dialog gets closed we'll be removing the section ids URL param.
    if (!isAssignmentDialogOpened && !isAssignmentCreateDialogOpened) {
      history.push({ search: '' });
    }
  }, [isAssignmentDialogOpened, isAssignmentCreateDialogOpened, history]);

  const openNewAssignmentDialog = () => {
    history.push({
      search: stringifyParams({
        initialSelectedSectionIds: [section.id]
      })
    });
    setIsAssignmentDialogOpened(true);
  };

  useNavbar({
    title: section?.name,
    onBackButtonClick: handleBackClick,
    sticky: true
  });

  useEffect(() => {
    if (isAssignmentCreateDialogOpened) {
      return () => {
        refetchSectionFeed();
      };
    }
  }, [isAssignmentCreateDialogOpened, setPage, refetchSectionFeed]);

  if (isLoadingSection) {
    return (
      <div className={styles.skeletonContainer}>
        <Filters disabled filters={filters} setFilters={setFilters} />
        <TutoringFeedSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Filters filters={filters} setFilters={setFilters} />
      <div className={styles.headerContainer}>
        <Paper variant="outlined" className={styles.header}>
          <Typography variant="B-Text-3" color={colors.grey1}>
            {section.default_name}
          </Typography>

          <div className={styles.actionButtons}>
            <AddButton color="pink" onClick={openNewAssignmentDialog}>
              New assignment
            </AddButton>
            <AddCommentButton
              sectionId={sectionId}
              onCommentAdd={handleCommentAdd}
            />
          </div>
        </Paper>
      </div>
      <div className={styles.feedContainer}>
        <div className={styles.feed}>
          <InfiniteScroll
            dataLength={aggregatedAssignments.length}
            next={() => setPage(page + 1)}
            hasMore={_.last(aggregatedSectionFeedData)?.next} // Take the next url from the last result.
          >
            {orderedCommentsAndAssignments.map((element) => {
              if (element.type === FEED_ELEMENTS.ASSIGNMENT) {
                return (
                  <div
                    key={`assignment_${element.id}`}
                    className={styles.assignmentBlock}
                  >
                    <HelpText assignment={element} />
                    <AssignmentInfoPaper
                      assignment={element}
                      studentGroup={section}
                    />
                  </div>
                );
              }

              if (element.type === FEED_ELEMENTS.COMMENT) {
                return (
                  <FeedComment
                    key={`comment_${element.id}`}
                    element={element}
                    sectionId={sectionId}
                  />
                );
              }

              return null;
            })}
            {showSectionFeedPlaceholder && (
              <div className={styles.loadingPlaceholder}>
                <RandomTutoringFeedItemSkeleton />
              </div>
            )}
          </InfiniteScroll>

          {!showSectionFeedPlaceholder &&
            _.isEmpty(orderedCommentsAndAssignments) && (
              <NoResultsPlaceholder />
            )}
        </div>
      </div>
    </div>
  );
};

export default Tutoring;
