import { useEffect } from 'react';
import { isAndroid, isIOS, isMacOs, isSafari } from 'react-device-detect';

import { TEACHERS_ASSIGNMENTS_URL } from 'config/urls';

const MobileApp = ({ history }) => {
  useEffect(() => {
    if (isAndroid) {
      window.location.replace(
        'https://play.google.com/store/apps/details?id=app.edlight'
      );
    } else if (isIOS || isSafari || isMacOs) {
      window.location.replace(
        'https://apps.apple.com/bg/app/edlight/id1570328651'
      );
    } else {
      window.location.replace(TEACHERS_ASSIGNMENTS_URL);
    }
  }, []);

  return null;
};

export default MobileApp;
