import { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';

import { useFetch } from 'utils/sdk';

import { getSchoolUsers } from './sdk';

export const useSchoolUsers = (user, schoolId) => {
  const [userName, setUserName] = useState('');
  const [selectedTutor, setSelectedTutor] = useState(user);

  const requestParams = useMemo(
    () => ({ id: schoolId, search: userName }),
    [schoolId, userName]
  );

  const { data: users } = useFetch(getSchoolUsers, { data: [] }, requestParams);

  return { users, userName, setUserName, selectedTutor, setSelectedTutor };
};

const buildStudentNotes = (students, studentsNotes) =>
  _.reduce(
    students,
    (result, student) => ({
      ...result,
      [student.id]: _.get(studentsNotes, student.id, '')
    }),
    {}
  );
export const useStudentNotes = (students) => {
  const [studentsNotes, setStudentsNotes] = useState({});

  const setStudentNotes = useCallback(
    (studentId, text) => {
      setStudentsNotes((prevStudentsNotes) => {
        const notes = buildStudentNotes(students, prevStudentsNotes);
        notes[studentId] = text;
        return notes;
      });
    },
    [students]
  );

  return { studentsNotes, setStudentNotes };
};
