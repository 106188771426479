import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import Skeleton from '@material-ui/lab/Skeleton';
import cx from 'classnames';
import { TEACHERS_BULK_UPLOAD_NAME_REGION_SELECT_URL } from 'config/urls';
import _ from 'lodash';
import {
  bulkUploadPagesConfirm,
  useBulkUploadDetail,
  useBulkUploadFirstAssignmentItems
} from 'sdk';

import Layout from 'pages/Teachers/BulkUpload/components/Layout';
import { useBulkUploadStatusRedirect } from 'pages/Teachers/BulkUpload/hooks';
import { colors } from 'theme/palette';
import { notifyErrors } from 'utils/notifications';
import { reverse } from 'utils/urls';

import Button from 'components/Button';
import Typography from 'components/Typography';

import { ReactComponent as InfoIcon } from './assets/info.svg';
import styles from './styles.module.scss';

const ConfirmPages = ({ match }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [rotationCount, setRotationCount] = useState(0);

  const history = useHistory();

  const bulkUploadId = _.get(match, 'params.bulkUploadId');

  const { data: bulkUpload, isLoading: isFetchingBulkUpload } =
    useBulkUploadDetail({ bulkUploadId });
  useBulkUploadStatusRedirect({
    bulkUpload,
    isLoading: isFetchingBulkUpload
  });

  const {
    data: assignmentItems,
    isLoading: isLoadingAssignmentItems,
    refetch: refetchAssignmentItems
  } = useBulkUploadFirstAssignmentItems({ bulkUploadId });

  const pageNumber = assignmentItems?.length;

  const shouldPoll = useMemo(
    () => _.some(assignmentItems, (item) => _.isNil(item.image)),
    [assignmentItems]
  );

  useEffect(() => {
    if (shouldPoll) {
      const interval = setInterval(() => refetchAssignmentItems(), 1000);

      return () => clearInterval(interval);
    }
  }, [shouldPoll, refetchAssignmentItems]);

  const handleNextClick = async () => {
    setShowLoader(true);

    const { success, errors } = await bulkUploadPagesConfirm({
      bulkUploadId,
      rotationCount
    });

    if (success) {
      history.push(
        reverse(TEACHERS_BULK_UPLOAD_NAME_REGION_SELECT_URL, {
          bulkUploadId
        })
      );
    } else {
      notifyErrors(errors);
    }

    setShowLoader(false);
  };

  return (
    <>
      <Layout
        currentStepIndex={1}
        showLoader={isFetchingBulkUpload || showLoader}
        nextButtonAction={handleNextClick}
        isNextButtonDisabled={
          isFetchingBulkUpload ||
          isLoadingAssignmentItems ||
          showLoader ||
          shouldPoll
        }
      >
        <Typography
          className={styles.assignmentName}
          variant="H-TEXT-1"
          color={colors.blue1}
        >
          {bulkUpload?.assignment.name}
        </Typography>

        <Typography
          className={styles.pagesNumber}
          variant="H-TEXT-2"
          color={colors.blue1}
        >
          {pageNumber} pages
        </Typography>

        <div
          className={cx(styles.itemsContainer, {
            [styles.horizontalItems]: rotationCount % 2 !== 0
          })}
        >
          {assignmentItems?.map((item) => (
            <div key={item.task_name} className={styles.assignmentItem}>
              {_.isNil(item.image) ? (
                <Skeleton variant="rectangular" height={244} />
              ) : (
                <div
                  className={styles.image}
                  style={{
                    transform: `rotate(${rotationCount * -90}deg)`,
                    backgroundImage: `url(${item.image})`
                  }}
                />
              )}
              <Divider />
              <div className={styles.nameContainer}>
                <Typography
                  className={styles.taskName}
                  variant="H-TEXT-3"
                  color={colors.blue1}
                >
                  {item.task_name}
                </Typography>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.rotateButtonContainer}>
          <Button
            className={styles.rotateButton}
            noTypography
            onClick={() => setRotationCount((prevCount) => (prevCount + 1) % 4)}
          >
            <SvgIcon
              component={Rotate90DegreesCcwIcon}
              className={styles.rotateIcon}
            />
            <Typography variant="H-TEXT-3">Rotate All</Typography>
          </Button>
        </div>

        <div className={styles.tipsContainer}>
          <div className={styles.tips}>
            <div className={styles.tipsHeader}>
              <SvgIcon component={InfoIcon} />
              <Typography
                className={styles.taskName}
                variant="S-TEXT-1"
                color={colors.pink1}
              >
                Helpful Tips:
              </Typography>
            </div>

            <ul className={styles.tipsContent}>
              <li>
                <Typography variant="B-Text-3">
                  The PDF should contain only one assignment{' '}
                </Typography>
              </li>

              <li>
                <Typography variant="B-Text-3">
                  If multiple assignments are mixed together, the upload may
                  fail.
                </Typography>
              </li>

              <li>
                <Typography variant="B-Text-3">
                  We expect every student’s Page 1 to look like Page 1 above,
                  Page 2 to look like Page 2, etc..
                </Typography>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ConfirmPages;
