import React, { useState } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

import Button from 'components/Button';
import InputField from 'components/InputField';

import styles from './styles.module.css';

const AddCommentBox = ({ addComment }) => {
  const [input, setInput] = useState('');

  const handleEnterKey = (event) => {
    // Handle submit on `Enter` key, but preserve the default behaviour (adding a new line) if `Shift` is pressed too.
    if (event.keyCode === 13 && !event.shiftKey) {
      addComment(input);
    }
  };

  return (
    <>
      <InputField
        variant="underlined"
        className={styles.commentInput}
        placeholder="Add comment to selected pages"
        multiline
        rows={6}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleEnterKey}
      />
      <Button
        color="pink"
        variant="medium"
        disabled={_.isEmpty(input)}
        fullWidth
        style={{ marginTop: 10 }}
        onClick={() => addComment(input)}
      >
        ADD COMMENT
      </Button>
    </>
  );
};

export const AddCommentTooltip = ({ open, addComment, children }) => (
  <Tooltip
    open={open}
    arrow
    placement="bottom"
    disableFocusListener
    disableHoverListener
    disableTouchListener
    classes={{
      popper: styles.addCommentPopper,
      tooltip: styles.addCommentBox,
      arrow: styles.addCommentArrow
    }}
    title={<AddCommentBox addComment={addComment} />}
  >
    {children}
  </Tooltip>
);
