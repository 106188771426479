import React from 'react';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import styles from './styles.module.css';

const StudentGroupCardSkeleton = () => {
  return (
    <Paper variant="outlined" className={styles.studentGroupCardSkeleton}>
      <div className={styles.header}>
        <Skeleton variant="text" animation="wave" width={250} />
        <Skeleton variant="circle" animation="wave" height={20} width={20} />
      </div>
      <Divider />
      <div className={styles.content}>
        <div>
          <Skeleton variant="text" animation="wave" width={150} />
          <Skeleton variant="text" animation="wave" width={200} />
        </div>
        <Skeleton variant="text" animation="wave" width={180} />
      </div>
    </Paper>
  );
};

const StudentGroupsListSkeleton = ({ cardsCount = 5 }) => {
  return (
    <div className={styles.cardsContainer}>
      {_.times(cardsCount, (cardIdx) => (
        <StudentGroupCardSkeleton key={cardIdx} />
      ))}
    </div>
  );
};

export default StudentGroupsListSkeleton;
