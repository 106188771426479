import React, { useCallback, useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { loadStripe } from '@stripe/stripe-js/pure';
import { TEACHERS_SUBSCRIPTIONS_CHANGE_PLAN_URL } from 'config/urls';
import _ from 'lodash';

import { withNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { localizeDate } from 'utils/moment';
import { notifyErrors } from 'utils/notifications';
import {
  getPaymentMethodRepresentation,
  subscriptionState
} from 'utils/subscriptions';
import { openInNewTab } from 'utils/urls';

import Button from 'components/Button';
import Typography from 'components/Typography';

import { UpdatePaymentMethodDialog } from './components';
import {
  fetchCustomer,
  fetchNextBillingDate,
  fetchSubscriptionTemplates
} from './sdk';
import styles from './styles.module.css';

const SubscriptionMoney = ({ subscription, plan }) => {
  const { is_free } = plan;
  const {
    interval,
    amount: { amount, currency }
  } = subscription;

  const intervalMapper = { monthly: 'every month', yearly: 'every year' };

  if (is_free) {
    return <span>Free</span>;
  }

  if (currency.toLowerCase() === 'usd') {
    return (
      <span>
        ${amount} {intervalMapper[interval] || interval}
      </span>
    );
  }

  return (
    <span>
      {amount} {currency} {intervalMapper[interval] || interval}
    </span>
  );
};

const DetailValue = ({ value }) => (
  <span style={{ color: colors.blue4 }}>{value}</span>
);

const PaymentInfoPaper = ({ header, children }) => (
  <Paper variant="outlined" elevation={0} className={styles.paper}>
    <Grid
      container
      alignItems="center"
      style={{ height: 50, borderBottom: `1px solid ${colors.grey6}` }}
    >
      <Grid item>
        <Typography
          variant="H-TEXT-3"
          color={colors.blue1}
          className={styles.paperHeader}
        >
          {header}
        </Typography>
      </Grid>
    </Grid>
    {children}
  </Paper>
);

const NextPayment = ({
  customer,
  nextBillingDate,
  freeSubscriptionTemplate,
  paidSubscriptionTemplate
}) => {
  const nextBillingDateLocalized =
    (nextBillingDate && localizeDate(nextBillingDate, 'MMM D, Y')) || '...';

  const { nextPaymentText } = subscriptionState({
    currentSubscription: customer.subscription,
    lastSubscription: customer.last_subscription,
    nextBillingDate: nextBillingDateLocalized,
    freeSubscriptionTemplate,
    paidSubscriptionTemplate
  });

  let nextPaymentParts = [nextPaymentText];

  if (_.isArray(nextPaymentText)) {
    nextPaymentParts = nextPaymentText;
  }

  return (
    <PaymentInfoPaper header="Next payment">
      <Grid
        container
        direction="column"
        justify="center"
        className={styles.paperContent}
      >
        <Grid item className={styles.nextPayment}>
          {nextPaymentParts.map((nextPaymentText, index) => (
            <Typography
              key={index}
              variant="H-TEXT-1"
              color={colors.grey1}
              align="center"
            >
              {nextPaymentText}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </PaymentInfoPaper>
  );
};

const PlanDetails = ({ customer, goToChangePlanPage }) => {
  const subscription = customer.subscription;
  const plan = subscription.template;

  return (
    <PaymentInfoPaper header="Plan details">
      <Grid
        container
        direction="column"
        justify="space-between"
        className={styles.paperContent}
      >
        <div className={styles.quickGrid}>
          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              Plan:
            </Typography>
          </div>
          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              <DetailValue value={plan.display_name} />
            </Typography>
          </div>

          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              Started:{' '}
            </Typography>
          </div>
          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              <DetailValue
                value={localizeDate(subscription.active_from, 'MMM D, Y')}
              />
            </Typography>
          </div>

          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              Amount:{' '}
            </Typography>
          </div>
          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              <DetailValue
                value={
                  <SubscriptionMoney subscription={subscription} plan={plan} />
                }
              />
            </Typography>
          </div>
        </div>
        <Grid item>
          <Button
            onClick={goToChangePlanPage}
            variant="base"
            color="blue"
            fullWidth
          >
            Change Plan
          </Button>
        </Grid>
      </Grid>
    </PaymentInfoPaper>
  );
};

const BillingDetails = ({ customer, openUpdatePaymentMethodDialog }) => {
  const paymentMethodId = customer.payment_method_stripe_id;

  const lastPaymentDate = _.get(customer, 'invoices[0].issue_date');
  const lastPaymentDateLocalized = lastPaymentDate
    ? localizeDate(lastPaymentDate, 'MMM D, Y')
    : 'None';

  const cardRepresentation = getPaymentMethodRepresentation(customer);

  return (
    <PaymentInfoPaper header="Billing details">
      <Grid
        container
        direction="column"
        justify="space-between"
        className={styles.paperContent}
      >
        <div className={styles.quickGrid}>
          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              Method:{' '}
            </Typography>
          </div>
          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              <DetailValue
                value={paymentMethodId ? cardRepresentation : 'None'}
              />
            </Typography>
          </div>

          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              Last Payment:
            </Typography>
          </div>

          <div>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              <DetailValue value={lastPaymentDateLocalized} />
            </Typography>
          </div>
        </div>

        <Grid item>
          <Button
            onClick={openUpdatePaymentMethodDialog}
            variant="base"
            color="blue"
            fullWidth
          >
            Update payment method
          </Button>
        </Grid>
      </Grid>
    </PaymentInfoPaper>
  );
};

const InvoicesTable = ({ invoices }) => (
  <TableContainer
    component={Paper}
    variant="outlined"
    className={styles.invoicesTableContainer}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={styles.headerCell}>
            <Typography variant="H-TEXT-3" color={colors.blue1}>
              Due
            </Typography>
          </TableCell>
          <TableCell className={styles.headerCell}>
            <Typography variant="H-TEXT-3" color={colors.blue1}>
              Amount
            </Typography>
          </TableCell>
          <TableCell className={styles.headerCell} colSpan={2}>
            <Typography variant="H-TEXT-3" color={colors.blue1}>
              Status
            </Typography>
          </TableCell>
          <TableCell className={styles.headerCell} align="center">
            <Typography variant="H-TEXT-3" color={colors.blue1}>
              Download
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices.map((invoice) => (
          <TableRow key={invoice.number}>
            <TableCell>
              <Typography variant="H-TEXT-3" color={colors.grey2}>
                {localizeDate(invoice.issue_date, 'L')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="H-TEXT-3" color={colors.grey2}>
                {invoice.total.amount.toFixed(2)}
              </Typography>
            </TableCell>
            <TableCell colSpan={2}>
              <Typography variant="H-TEXT-3" color={colors.green1}>
                {/* We are currently storing only paid invoices */}
                Paid
              </Typography>
            </TableCell>
            <TableCell align="center">
              <IconButton
                size="small"
                onClick={() => openInNewTab(invoice.pdf_url)}
              >
                <GetAppRoundedIcon htmlColor={colors.blue3} fontSize="large" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const SubscriptionsDashboard = ({ width, history }) => {
  const [stripePromise, setStripePromise] = useState(null);

  const [customer, setCustomer] = useState(null);
  const [subscriptionTemplates, setSubscriptionTemplates] = useState(null);
  const [nextBillingDate, setNextBillingDate] = useState(null);

  const [updatePaymentMethodDialogOpened, setUpdatePaymentMethodDialogOpened] =
    useState(false);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_API_KEY));
  }, []);

  const getCustomerData = useCallback(async () => {
    const { success, data, errors } = await fetchCustomer();

    if (success) {
      setCustomer(data);
    } else {
      notifyErrors(errors);
    }
  }, []);

  const getSubscriptionTemplates = useCallback(async () => {
    const { success, data, errors } = await fetchSubscriptionTemplates();

    if (success) {
      setSubscriptionTemplates(data);
    } else {
      notifyErrors(errors);
    }
  }, []);

  useEffect(() => {
    getCustomerData();
    getSubscriptionTemplates();
  }, [getCustomerData, getSubscriptionTemplates]);

  useEffect(() => {
    async function getNextBillingDate() {
      const {
        success,
        data: { next_billing_date },
        errors
      } = await fetchNextBillingDate(customer.subscription.stripe_id);

      if (success) {
        setNextBillingDate(next_billing_date);
      } else {
        notifyErrors(errors);
      }
    }

    if (customer && !customer.subscription.template.is_free) {
      getNextBillingDate();
    }
  }, [customer]);

  const openUpdatePaymentMethodDialog = () =>
    setUpdatePaymentMethodDialogOpened(true);

  const closeUpdatePaymentMethodDialog = () => {
    getCustomerData();
    setUpdatePaymentMethodDialogOpened(false);
  };

  const goToChangePlanPage = () =>
    history.push(TEACHERS_SUBSCRIPTIONS_CHANGE_PLAN_URL);

  const loading =
    customer === null ||
    subscriptionTemplates === null ||
    stripePromise === null;

  const lgScreen = isWidthUp('lg', width);

  const freeSubscriptionTemplate = _.head(
    _.filter(subscriptionTemplates, { is_free: true })
  );
  const paidSubscriptionTemplate = _.head(
    _.filter(subscriptionTemplates, { is_free: false })
  );

  return (
    <>
      <Grid container className={styles.container}>
        {!loading && (
          <>
            <Grid container item spacing={2}>
              <Grid item xs={lgScreen ? 3 : 4}>
                <NextPayment
                  customer={customer}
                  nextBillingDate={nextBillingDate}
                  freeSubscriptionTemplate={freeSubscriptionTemplate}
                  paidSubscriptionTemplate={paidSubscriptionTemplate}
                />
              </Grid>
              <Grid item xs>
                <PlanDetails
                  customer={customer}
                  goToChangePlanPage={goToChangePlanPage}
                />
              </Grid>
              <Grid item xs>
                <BillingDetails
                  customer={customer}
                  openUpdatePaymentMethodDialog={openUpdatePaymentMethodDialog}
                />
              </Grid>
            </Grid>
            {!_.isEmpty(customer.invoices) && (
              <Grid item xs>
                <InvoicesTable invoices={customer.invoices} />
              </Grid>
            )}
          </>
        )}
      </Grid>
      {updatePaymentMethodDialogOpened && (
        <UpdatePaymentMethodDialog
          stripePromise={stripePromise}
          customer={customer}
          onClose={closeUpdatePaymentMethodDialog}
        />
      )}
    </>
  );
};

export default withWidth()(
  withNavbar(SubscriptionsDashboard, {
    title: 'Account'
  })
);
