import React, { useCallback, useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { addCommentToSection } from 'sdk';

import { notifyError } from 'utils/notifications';

import AddButton from 'components/AddButton';
import Button from 'components/Button';
import EmojiInputField from 'components/EmojiInputField';

import styles from './styles.module.scss';

const AddCommentButton = ({ sectionId, onCommentAdd }) => {
  const [value, setValue] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnClick = useCallback(
    (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleCommentAddition = useCallback(async () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    const { data, success, errors } = await addCommentToSection({
      sectionId,
      data: {
        text: value
      }
    });

    if (success) {
      onCommentAdd(data);

      setValue('');
      setAnchorEl(null);
    } else {
      notifyError(errors);
    }

    setSubmitting(false);
  }, [sectionId, onCommentAdd, value, submitting]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <AddButton
          aria-describedby={id}
          color="blue"
          onClick={handleOnClick}
          disabled={submitting}
        >
          Note
        </AddButton>
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <Paper className={styles.popup} variant="outlined">
            <EmojiInputField
              autoFocus
              multiline={true}
              className={styles.inputField}
              EmojiButtonProps={{ classes: { root: styles.emojiButton } }}
              variant="underlined"
              placeholder="Add Teacher Notes (Not visible to students)"
              value={value}
              onKeyPress={(event) => {
                /* 13 == Enter */
                if (!submitting && event.which === 13 && !event.shiftKey) {
                  handleCommentAddition();
                }
              }}
              onChange={(event) => setValue(event.target.value)}
            />
            <Button
              variant="small"
              color="blue"
              disabled={submitting || value === ''}
              onClick={handleCommentAddition}
            >
              Add note
            </Button>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default AddCommentButton;
