import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Field, Formik } from 'formik';

import {
  createSubscription,
  customerAddCard,
  fetchCustomer,
  fetchProducts,
  getSubscription
} from './sdk';
import styles from './styles.module.css';

const Container = ({ children }) => {
  return <div className={styles.subContainer}>{children}</div>;
};

const CustomerInfo = ({ customer }) => {
  return (
    <Container>
      <p>Customer: {customer.id}</p>
      <p>Payment method: {customer.paymentMethod || 'N/A'}</p>
    </Container>
  );
};

const AddCardForm = ({ refetchCustomer }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState(false);

  const addCard = async () => {
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    if (error) {
      console.log(error);
      return;
    }

    setSubmitting(true);

    console.log(paymentMethod);

    const data = { payment_method_id: paymentMethod.id };
    const { success } = await customerAddCard(data);

    if (success) {
      refetchCustomer();
    }

    setSubmitting(false);
  };

  return (
    <Container>
      <Box marginBottom="1rem">
        <CardElement />
      </Box>

      <Button
        onClick={addCard}
        color="primary"
        variant="contained"
        disabled={submitting}
      >
        Add new card
      </Button>
    </Container>
  );
};

const CheckoutForm = ({ customer, products }) => {
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (subscriptionId !== null && subscription === null) {
      console.log('I should enter here only once');

      setTimeout(async () => {
        const { success, data } = await getSubscription(subscriptionId);

        if (success) {
          setSubscription(data);
        }
      }, 5000);
    }
  }, [subscriptionId, subscription]);

  useEffect(() => {
    if (subscription === null) {
      return;
    }

    async function confirmCard() {
      if (subscription.payment_status === 'requires_action') {
        console.log('confirming card');

        const result = await stripe.confirmCardPayment(
          subscription.payment_intent_client_secret,
          {
            payment_method: customer.paymentMethod
          }
        );

        // TODO: Proper error handling should be done here
        console.log(result);
      }
    }
    confirmCard();
  }, [subscription, stripe, customer.paymentMethod]);

  const onSubmit = async (values) => {
    console.log(values);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const data = { ...values };

    const createSubscriptionResult = await createSubscription(data);
    setSubscriptionId(createSubscriptionResult.data.subscription_id);
  };

  const initialValues = {
    customer: customer.id,
    product: '',
    price: ''
  };

  const getPrices = (productId) => {
    const product = products.filter(
      (product) => product.stripe_id === productId
    )[0];

    if (!product) {
      return [];
    }

    return [
      {
        ...product.daily_amount,
        id: product.daily_price_stripe_id,
        intervalLabel: 'daily'
      },
      {
        ...product.monthly_amount,
        id: product.monthly_price_stripe_id,
        intervalLabel: 'per month'
      },
      {
        ...product.yearly_amount,
        id: product.yearly_price_stripe_id,
        intervalLabel: 'yearly'
      }
    ];
  };

  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Box marginBottom="2rem">
              <Field as="select" name="product">
                <option value={''}>Select product</option>
                {products.map((product) => (
                  <option key={product.stripe_id} value={product.stripe_id}>
                    {product.display_name}
                  </option>
                ))}
              </Field>

              <Field as="select" name="price" disabled={!values.product}>
                <option value="">Select price</option>
                {getPrices(values.product).map((price) => (
                  <option key={price.id} value={price.id}>
                    {price.amount} {price.currency} {price.intervalLabel}
                  </option>
                ))}
              </Field>
            </Box>
            <Box>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={!stripe}
              >
                Purchase
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Container>
  );
};

const StripeTest = () => {
  const [customer, setCustomer] = useState(null);
  const [products, setProducts] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  const fetchData = async () => {
    const promises = [fetchCustomer(), fetchProducts()];
    const results = await Promise.all(promises);

    const [customerResponse, productsResponse] = results;

    if (customerResponse.success) {
      setCustomer({
        id: customerResponse.data.stripe_id,
        paymentMethod: customerResponse.data.payment_method_stripe_id
      });
    }

    if (productsResponse.success) {
      setProducts(productsResponse.data.filter((product) => !product.is_free));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_API_KEY));
  }, []);

  const loading =
    customer === null || products === null || stripePromise === null;

  return (
    <div className={styles.container}>
      {loading && <p>Fetching customer and products ...</p>}

      {!loading && (
        <Elements stripe={stripePromise}>
          <CustomerInfo customer={customer} />
          <AddCardForm customer={customer} refetchCustomer={fetchData} />
          <CheckoutForm products={products} customer={customer} />
        </Elements>
      )}
    </div>
  );
};

export default StripeTest;
