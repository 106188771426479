import { BASE_URL } from 'config/urls';

import { callUrl, get, post } from 'utils/sdk';

export const getUserSchools = async () =>
  callUrl(get, `${BASE_URL}/v1/schools/`);

export const getSchoolStudents = ({ id, search }) =>
  callUrl(get, `${BASE_URL}/v1/schools/${id}/students/`, { search });

export const getSchoolUsers = ({ id, search }) =>
  callUrl(get, `${BASE_URL}/v1/schools/schools-school-users/`, {
    name: search,
    schools_ids: [id]
  });

export const getDefaultStudentGroupName = (data) =>
  callUrl(
    post,
    `${BASE_URL}/v1/sections/student-groups/generate-default-group-name/`,
    data
  );
