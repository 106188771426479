import React, { useContext } from 'react';
import { withRouter } from 'react-router';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import MenuIcon from '@material-ui/icons/MenuRounded';
import classnames from 'classnames';
import { LOGIN_URL } from 'config/urls';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { shutdownIntercomWidget } from 'utils/integrations/intercom';
import { default as pusherManager } from 'utils/integrations/pusher';

import Typography from 'components/Typography';
import UsageContext from 'components/UsageContext';
import UserContext from 'components/UserContext';

import { ReactComponent as BackButtonIcon } from './assets/back_arrow.svg';
import { AccountMenuButton, HelpButton } from './components';
import { logout } from './sdk';
import styles from './styles.module.scss';

const TopBar = ({
  sideNavOpen,
  title,
  onTitleClick,
  onBackButtonClick,
  history,
  lgScreen,
  toggleSideNav,
  LeftSubheader,
  RightSubheader,
  sticky
}) => {
  const { user } = useContext(UserContext);
  const { usage } = useContext(UsageContext);

  const logoutAndRedirect = async () => {
    shutdownIntercomWidget();
    pusherManager.disconnect();
    await logout();
    history.push(LOGIN_URL);
  };

  return (
    <AppBar
      square
      className={classnames(styles.appBar, {
        [styles.small]: lgScreen && sideNavOpen,
        [styles.fullWidth]: !lgScreen
      })}
      elevation={0}
      position={sticky ? 'fixed' : 'absolute'}
      variant="outlined"
    >
      <div className={styles.container}>
        {!lgScreen && (
          <div className={styles.sideNavButtonWrapper}>
            <IconButton onClick={toggleSideNav}>
              <MenuIcon htmlColor={colors.blue1} fontSize="large" />
            </IconButton>
          </div>
        )}
        {!_.isNil(onBackButtonClick) && (
          <IconButton
            onClick={onBackButtonClick}
            classes={{ root: styles.backArrow }}
          >
            <SvgIcon
              titleAccess="Back"
              component={BackButtonIcon}
              htmlColor={colors.blue1}
            />
          </IconButton>
        )}
        <div className={styles.contentWrapper}>
          <div
            className={classnames({
              pointer: onTitleClick,
              [styles.noPaddingLeft]: onBackButtonClick
            })}
            onClick={onTitleClick}
          >
            <Typography noWrap variant="H-TEXT-3" color={colors.blue1}>
              {title}
            </Typography>
          </div>

          {LeftSubheader && <div>{LeftSubheader}</div>}
          {RightSubheader && <div>{RightSubheader}</div>}
        </div>

        <div className={styles.actionsWrapper}>
          <HelpButton user={user} />
          <AccountMenuButton
            user={user}
            usage={usage}
            logout={logoutAndRedirect}
            history={history}
          />
        </div>
      </div>
    </AppBar>
  );
};

export { default as TopBarSelect } from './components/TopBarSelect';
export default withRouter(TopBar);
