import { matchPath } from 'react-router';

import {
  STUDENTS_ASSIGNMENTS_LIST_URL,
  STUDENTS_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL
} from 'config/urls';
import _ from 'lodash';

import {
  shutdownIntercomWidget,
  updateIntercomEntity
} from 'utils/integrations/intercom';

export const getRedirectPath = (user, nextPath) => {
  const isTeacher = user.is_teacher || user.is_superuser;
  const isStudent = !_.isNull(user.student_id);

  const studentAppMatch = matchPath(nextPath, {
    path: STUDENTS_URL,
    exact: false
  });

  if (nextPath) {
    if (isTeacher && !studentAppMatch) {
      // If there is next path & the user is a teacher trying to access a /teacher route -> redirect to the next path.
      return nextPath;
    }

    if (isStudent && studentAppMatch) {
      // If there is next path & the user is a student trying to access a /student route -> redirect to the next path.
      return nextPath;
    }

    if (isTeacher && !isStudent && studentAppMatch) {
      // If there is next path & the user is a teacher, but without a demo student, trying to access a /student route -> redirect to the teachers' homepage.
      return TEACHERS_ASSIGNMENTS_LIST_URL;
    }

    if (isStudent && !isTeacher && !studentAppMatch) {
      // If there is next path & the user is a regular (non-teacher-demo) student, trying to access a /teacher route -> redirect to the students' homepage.
      return STUDENTS_ASSIGNMENTS_LIST_URL;
    }
  }

  // If there is no next path -> redirect to the respective user's homepage.
  return isTeacher
    ? TEACHERS_ASSIGNMENTS_LIST_URL
    : STUDENTS_ASSIGNMENTS_LIST_URL;
};

export const handleIntercomOnRedirect = (user, redirectPath) => {
  const isTeacher = user.is_teacher || user.is_superuser;
  const studentAppMatch = matchPath(redirectPath, {
    path: STUDENTS_URL,
    exact: false
  });

  if (isTeacher) {
    updateIntercomEntity(user);
  }

  if (studentAppMatch) {
    // Make sure to hide the Intercom widget if the user is being redirected to the students' app.
    shutdownIntercomWidget();
  }
};
