import React, { useContext, useMemo, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import _ from 'lodash';
import { useStudentGroups } from 'sdk';

import { SearchField } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { MuiAutocompleteChangeReasons } from 'utils/constants';
import {
  bootIntercomWidget,
  showIntercomWidget
} from 'utils/integrations/intercom';

import AttachmentsUpload from 'components/AttachmentsUpload';
import Button from 'components/Button';
import DebouncedField from 'components/DebouncedField';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import SearchFilter from 'components/SearchFilter';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { assignStudentGroup } from './sdk';
import styles from './styles.module.css';
import { useSchoolUsers, useStudentNotes } from './utils';

const AssignStudentGroupDialog = ({
  assignment,
  students,
  openCreateStudentGroupDialog,
  onStudentGroupAssign,
  onClose,
  initialStudentGroup = null
}) => {
  const { user } = useContext(UserContext);
  const [selectedStudentGroup, setSelectedStudentGroup] =
    useState(initialStudentGroup);

  const [submitting, setSubmitting] = useState(false);
  const [notesForTutor, setNotesForTutor] = useState('');
  const [attachments, setAttachments] = useState([]);

  const { data: studentGroupsData } = useStudentGroups({
    params: { limit: 100 }
  });

  const studentGroups = _.get(studentGroupsData, 'results', []);

  const currentStudents = useMemo(
    () => (selectedStudentGroup ? selectedStudentGroup.students : students),
    [selectedStudentGroup, students]
  );

  const { users, setUserName, selectedTutor, setSelectedTutor } =
    useSchoolUsers(user, assignment.school.id);

  const { studentsNotes, setStudentNotes } = useStudentNotes(currentStudents);

  const openIntercomWidget = () => {
    bootIntercomWidget({ name: user.name, email: user.email });
    showIntercomWidget();
  };

  const isAssignButtonDisabled =
    _.isNull(selectedStudentGroup) ||
    _.isNull(selectedTutor) ||
    _.isEmpty(currentStudents) ||
    !_.every(attachments, 'uploaded') ||
    submitting;

  const onAssignClick = () => {
    setSubmitting(true);

    const nonEmptyStudentsNotes = _.omitBy(studentsNotes, _.isEmpty);
    const attachmentsFilesIds = _.map(attachments, 'id');
    const studentsIds = _.map(currentStudents, 'id');

    const data = {
      assignment: assignment.id,
      students: studentsIds,
      tutor: selectedTutor.id,
      student_group: selectedStudentGroup.id,
      notes_for_tutor: notesForTutor,
      comments_data: nonEmptyStudentsNotes,
      comment_attachment_files: attachmentsFilesIds
    };

    assignStudentGroup(data)
      .then(({ id }) => {
        setSubmitting(false);
        onClose();
        onStudentGroupAssign(id);
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <Dialog
      open
      alignTop
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.dialogHeader}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Assign to tutoring
        </Typography>

        <div>
          <Button
            color="lightGrey"
            onClick={onClose}
            className={styles.dialogButton}
            startIcon={<DeleteRoundedIcon />}
          >
            Cancel
          </Button>
          <Button
            color="pink"
            onClick={onAssignClick}
            disabled={isAssignButtonDisabled}
            className={styles.dialogButton}
          >
            Assign
          </Button>
        </div>
      </div>
      <div className={styles.dialogContent}>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Tutoring group
        </Typography>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={5}>
            <SearchField
              className={styles.searchField}
              placeholder="Select existing group"
              options={studentGroups}
              value={selectedStudentGroup}
              getOptionLabel={(option) => _.get(option, 'name', '')}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(e, opt, trigger) => {
                if (trigger === MuiAutocompleteChangeReasons.SELECT_OPTION) {
                  setSelectedStudentGroup(opt);
                } else if (trigger === MuiAutocompleteChangeReasons.CLEAR) {
                  setSelectedStudentGroup(null);
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography variant="B-Text-3" align="center" color={colors.grey3}>
              - or -
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Button
              fullWidth
              color="blue"
              onClick={() => {
                onClose();
                openCreateStudentGroupDialog(currentStudents);
              }}
            >
              Create new group
            </Button>
          </Grid>
        </Grid>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Tutor
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={5}>
            <SearchFilter
              freeSolo
              autoComplete
              placeholder="Select a tutor"
              options={users}
              defaultValue={user}
              renderOption={(option) => _.get(option, 'name', '')}
              getOptionLabel={(option) => {
                if (option.id === user.id) {
                  return 'Me';
                }

                return _.get(option, 'name', '');
              }}
              onInputChange={(e, val, trigger) => {
                if (trigger === 'input') {
                  setUserName(val);
                }
              }}
              onChange={(e, opt, trigger) => {
                if (trigger === MuiAutocompleteChangeReasons.SELECT_OPTION) {
                  setSelectedTutor(opt);
                  setUserName('');
                }
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <Button
              fullWidth
              className={styles.useTutorButton}
              onClick={openIntercomWidget}
            >
              Use EdLight tutor
            </Button>
          </Grid>
        </Grid>
        <DebouncedField
          component={InputField}
          fullWidth
          multiline
          rows={4}
          variant="underlined"
          placeholder="Notes for Tutor"
          onChange={setNotesForTutor}
          classes={{
            root: styles.notesForTutor
          }}
          InputProps={{
            classes: { multiline: styles.textarea }
          }}
        />
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Students
        </Typography>
        <div className={styles.studentCommentsContainer}>
          {_.map(currentStudents, (student) => (
            <div key={student.id} className={styles.studentCommentRow}>
              <Typography variant="B-Text-2" color={colors.blue1}>
                {student.name}
              </Typography>
              <InputField
                fullWidth
                variant="underlined"
                placeholder="Write Here"
                onChange={(event) =>
                  setStudentNotes(student.id, event.target.value)
                }
              />
            </div>
          ))}
        </div>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Assignment
        </Typography>
        <Typography variant="B-Text-2" color={colors.blue1}>
          {assignment.tracker_name}
        </Typography>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Attachments
        </Typography>
        <AttachmentsUpload
          attachments={attachments}
          setAttachments={setAttachments}
        />
        {submitting && <LinearProgress className={styles.progress} />}
      </div>
    </Dialog>
  );
};

export default AssignStudentGroupDialog;
