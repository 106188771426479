import React from 'react';
import { withRouter } from 'react-router';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TEACHERS_SUBSCRIPTIONS_URL } from 'config/urls';

import { colors } from 'theme/palette';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './styles.module.css';

const UsageDialog = ({ onClose, history }) => {
  const onClick = () => {
    history.push(TEACHERS_SUBSCRIPTIONS_URL);
    onClose();
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <DialogTitle disableTypography className={styles.dialogHeader}>
        <Typography variant="H-TEXT-1">Limit exceeded</Typography>
      </DialogTitle>
      <DialogContent>
        <p>You've exceeded your current plan images limit.</p>
        <Typography
          className={styles.link}
          variant="S-TEXT-1"
          color={colors.pink4}
          onClick={onClick}
        >
          Please, upgrade your plan.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="base" color="pink" onClick={onClose}>
          Acknowledge
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(UsageDialog);
