import React, { useEffect, useRef } from 'react';

import * as d3 from 'd3';

import styles from './styles.module.css';

function wrap(text, width) {
  // Taken & modified from https://bl.ocks.org/mbostock/7555321
  text.each(function () {
    var text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      y = text.attr('y') || 0,
      dy = parseFloat(text.attr('dy')) || 0,
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em');

    let nextDy;

    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' '));
      if (tspan.node().getComputedTextLength() > width) {
        nextDy = (lineNumber + 1) * lineHeight + dy;

        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', nextDy + 'em')
          .text(word);

        lineNumber += 1;
      }
    }
  });
}

const Donut = ({
  data,
  labelFunction,
  itemColorFunction,
  labelColorFunction,
  sortFunction,
  w,
  h
}) => {
  const width = w || 220;
  const height = h || 220;
  const radius = Math.min(width, height) / 2;

  const visualization = useRef(null);

  useEffect(() => {
    // We want to remvoe all previous renders
    d3.select(visualization.current).selectAll('svg').remove();

    var pie = d3
      .pie()
      .sort((a, b) => {
        if (sortFunction) {
          return sortFunction(a, b);
        }

        return b.value - a.value;
      })
      .value((d) => d.value)(data);

    // inner arc used for pie chart
    var arc = d3
      .arc()
      .outerRadius(radius * 0.5)
      .innerRadius(radius * 0.7);

    var svg = d3
      .select(visualization.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    svg
      .selectAll('slices')
      .data(pie)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => {
        return itemColorFunction(data, d.data);
      })
      .attr('class', 'slice');

    function showCenterLabel() {
      const label = labelFunction(data);
      const color = labelColorFunction(data);

      svg
        .append('text')
        .text(label)
        .attr('class', styles.centerLabel)
        .attr('style', `fill: ${color}`)
        .attr('dy', -0.8 + 'em') // So we can center things
        .style('text-anchor', 'middle');

      svg.selectAll('text').call(wrap, radius / 2);
    }

    showCenterLabel();
  }, [
    width,
    height,
    radius,
    itemColorFunction,
    labelColorFunction,
    labelFunction,
    sortFunction,

    data
  ]);

  return <div ref={visualization} />;
};

export default Donut;
