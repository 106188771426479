import React, { useCallback, useContext } from 'react';
import { matchPath } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import LeftArrowIcon from '@material-ui/icons/ArrowBackIosRounded';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIosRounded';
import classnames from 'classnames';
import {
  TEACHERS_ASSIGNMENTS_FEEDBACK_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL
} from 'config/urls';
import logoSmall from 'images/logo-small.svg';
import logoBig from 'images/logo.svg';
import _ from 'lodash';
import { useClosestTask } from 'sdk';

import TeacherAssignmentCreate from 'pages/Teachers/Assignments/Create';
import { UsageDonut } from 'pages/Teachers/shared';
import SideNavItemWrapper from 'pages/Teachers/shared/TeacherPageLayout/components/SideNavItemWrapper';
import { showUsage } from 'pages/Teachers/shared/utils';
import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { trackMixpanelEventSdk } from 'utils/integrations/mixpanel/sdk';
import { reverse, stringifyParams } from 'utils/urls';

import AssignmentDialogContext from 'components/AssignmentDialogContext';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';
import UsageContext from 'components/UsageContext';
import UserContext from 'components/UserContext';

import { ITEMS } from './constants';
import styles from './styles.module.scss';

const SideNav = ({ open, toggle, lgScreen }) => {
  const { usage } = useContext(UsageContext);
  const { user } = useContext(UserContext);
  const displayUsage = showUsage(usage);

  const {
    isOpened: isNoAssignmentsDialogOpened,
    closeDialog: closeNoAssignmentsDialog,
    openDialog: openNoAssignmentsDialog
  } = useDialog();

  const history = useHistory();

  const { isAssignmentDialogOpened, setIsAssignmentDialogOpened } = useContext(
    AssignmentDialogContext
  );

  const { data: closestTask } = useClosestTask();

  const openClosestTask = useCallback(() => {
    const tracker = _.get(closestTask, 'tracker');

    if (_.isNil(tracker)) {
      openNoAssignmentsDialog();
    } else {
      history.push({
        pathname: reverse(TEACHERS_ASSIGNMENTS_FEEDBACK_URL, {
          trackerId: tracker
        }),
        search: stringifyParams({
          student: closestTask.student,
          section: closestTask.section,
          task: closestTask.id
        })
      });
    }
  }, [closestTask, openNoAssignmentsDialog, history]);

  const reviewSelected = !_.isNull(
    matchPath(history.location.pathname, {
      path: TEACHERS_ASSIGNMENTS_FEEDBACK_URL,
      exact: false
    })
  );

  const toggleNavigationOnTabletResolution = () => {
    if (!lgScreen) {
      toggle();
    }
  };

  const reviewPageOnClick = useCallback(() => {
    trackMixpanelEventSdk({
      user_id: user.id,
      event_name: 'Teacher opened Review Page'
    });
    openClosestTask();

    if (!lgScreen) {
      toggle();
    }
  }, [lgScreen, openClosestTask, toggle, user.id]);

  return (
    <>
      <Drawer
        open={open}
        variant={lgScreen ? 'permanent' : 'temporary'}
        classes={{
          paper: classnames(styles.drawer, { [styles.small]: !open })
        }}
        onClose={toggle}
      >
        <div className={styles.menuContent}>
          <div className={styles.menuActions}>
            <Link
              className={styles.logo}
              to={TEACHERS_ASSIGNMENTS_LIST_URL}
              onClick={toggleNavigationOnTabletResolution}
            >
              <SideNavItemWrapper open={open} text="Home">
                <img src={open ? logoBig : logoSmall} alt="Logo" />
              </SideNavItemWrapper>
            </Link>

            <List component="div" classes={{ root: styles.menuList }}>
              {_.map(ITEMS, ({ key, text, shortText, url, Icon }) => {
                const selected =
                  !_.isNull(
                    matchPath(history.location.pathname, {
                      path: url,
                      exact: false
                    })
                  ) && !reviewSelected;

                if (key === 'create') {
                  return (
                    <div
                      onClick={() => {
                        toggleNavigationOnTabletResolution();
                        setIsAssignmentDialogOpened(true);
                      }}
                    >
                      <SideNavItemWrapper open={open} text={text}>
                        <ListItem
                          button
                          selected={isAssignmentDialogOpened}
                          classes={{
                            root: styles.listItem,
                            selected: styles.selected
                          }}
                        >
                          <Icon
                            htmlColor={
                              isAssignmentDialogOpened
                                ? colors.blue5
                                : colors.white
                            }
                          />
                          {open && (
                            <Typography
                              classes={{ root: styles.listItemText }}
                              variant="H-TEXT-2"
                              color={
                                isAssignmentDialogOpened
                                  ? colors.blue5
                                  : colors.white
                              }
                            >
                              {shortText || text}
                            </Typography>
                          )}
                        </ListItem>
                      </SideNavItemWrapper>
                    </div>
                  );
                }

                if (key === 'review') {
                  return (
                    <SideNavItemWrapper open={open} text="Review">
                      <ListItem
                        button
                        key={key}
                        selected={reviewSelected}
                        onClick={reviewPageOnClick}
                        classes={{
                          root: styles.listItem,
                          selected: styles.selected
                        }}
                      >
                        <SvgIcon
                          component={Icon}
                          htmlColor={
                            reviewSelected ? colors.blue5 : colors.white
                          }
                        />
                        {open && (
                          <Typography
                            classes={{ root: styles.listItemText }}
                            variant="H-TEXT-2"
                            color={reviewSelected ? colors.blue5 : colors.white}
                          >
                            Review
                          </Typography>
                        )}
                      </ListItem>
                    </SideNavItemWrapper>
                  );
                }
                return (
                  <Link
                    key={key}
                    to={url}
                    onClick={toggleNavigationOnTabletResolution}
                    className={styles.listItemLink}
                  >
                    <SideNavItemWrapper open={open} text={text}>
                      <ListItem
                        button
                        selected={selected}
                        classes={{
                          root: styles.listItem,
                          selected: styles.selected
                        }}
                      >
                        <Icon
                          htmlColor={selected ? colors.blue5 : colors.white}
                        />
                        {open && (
                          <Typography
                            classes={{ root: styles.listItemText }}
                            variant="H-TEXT-2"
                            color={selected ? colors.blue5 : colors.white}
                          >
                            {text}
                          </Typography>
                        )}
                      </ListItem>
                    </SideNavItemWrapper>
                  </Link>
                );
              })}
            </List>
          </div>

          {displayUsage && <UsageDonut usage={usage} small={!open} />}

          <IconButton onClick={toggle}>
            {open ? (
              <LeftArrowIcon
                htmlColor={colors.white}
                classes={{ root: styles.arrowIcon }}
              />
            ) : (
              <RightArrowIcon
                htmlColor={colors.white}
                classes={{ root: styles.arrowIcon }}
              />
            )}
          </IconButton>
        </div>
      </Drawer>
      {isNoAssignmentsDialogOpened && (
        <Dialog
          open
          onClose={closeNoAssignmentsDialog}
          classes={{ paper: styles.dialogPaper }}
        >
          <Typography variant="H-TEXT-2" color={colors.blue1}>
            No Assignments to Review!
          </Typography>
        </Dialog>
      )}

      {isAssignmentDialogOpened && (
        <Dialog
          open
          alignTop
          onClose={() => setIsAssignmentDialogOpened(false)}
          classes={{ paper: styles.assignmentCreateDialog }}
        >
          <TeacherAssignmentCreate />
        </Dialog>
      )}
    </>
  );
};

export default SideNav;
