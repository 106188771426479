import React from 'react';

import _ from 'lodash';

import { colors } from 'theme/palette';
import { formatDate } from 'utils/moment';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const HelpText = ({ assignment }) => {
  let text = '';
  const createdByName = _.get(assignment, 'created_by.name', '');
  let eventDate = assignment.created_at;

  if (assignment.is_assignment && assignment.assigned_to) {
    text = `${assignment.assigned_to.name} assigned to tutoring by ${createdByName}`;
  }
  if (assignment.is_tutoring_session && assignment.tutoring_session.end_at) {
    const completedByName = _.get(
      assignment,
      'tutoring_session.ended_by.name',
      ''
    );
    text = `Tutoring session completed by ${completedByName}`;
    eventDate = assignment.tutoring_session.end_at;
  } else {
    text = `Assignment created by ${createdByName}`;
  }

  return (
    <Typography
      className={styles.label}
      variant="S-TEXT-1"
      color={colors.grey3}
    >
      {text} on {formatDate(eventDate, 'MM/DD hh:mm A')}
    </Typography>
  );
};

export default HelpText;
