import React, { useCallback, useMemo } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { FieldArray, Formik } from 'formik';
import _ from 'lodash';
import {
  createTrackerFromAssignmentTemplate,
  useAssignmentTemplate
} from 'sdk';
import { getStandardButtonLabel } from 'utils';

import { AssignmentDialogModes } from 'pages/Teachers/shared/AssignmentDialog';
import { FIRST_PAGE } from 'pages/Teachers/shared/AssignmentDialog/constants';
import { dialogValidationSchema } from 'pages/Teachers/shared/AssignmentDialog/utils';
import AttachmentDropzone from 'pages/Teachers/shared/AttachmentDropzone';
import AttachmentList from 'pages/Teachers/shared/AttachmentList';
import AttachmentUploadOverlay from 'pages/Teachers/shared/AttachmentUploadOverlay';
import MaterialsButton from 'pages/Teachers/shared/MaterialsButton';
import TasksForm from 'pages/Teachers/shared/TasksForm';
import { buildFormDataForCreate } from 'utils/assignments';
import { setFormErrors } from 'utils/forms';
import { useDialog } from 'utils/hooks';

import Button from 'components/Button';
import DebouncedField from 'components/DebouncedField';
import EmojiInputField from 'components/EmojiInputField';
import InputField from 'components/InputField';
import StandardButton from 'components/StandardButton';
import StandardDialog from 'components/StandardDialog';

import styles from './styles.module.scss';

const AssignmentFromTemplateForm = ({
  stepper,
  assignmentTemplateId,
  onCreateSuccess
}) => {
  const { data: assignmentTemplate, isLoading } = useAssignmentTemplate({
    assignmentTemplateId
  });

  const initialValues = useMemo(
    () => ({
      assignmentName: _.get(assignmentTemplate, 'title', ''),
      description: _.get(assignmentTemplate, 'description', ''),
      tasks: _.isEmpty(assignmentTemplate?.tasks)
        ? [FIRST_PAGE]
        : assignmentTemplate.tasks,
      attachments: _.get(assignmentTemplate, 'attachments', [])
    }),
    [assignmentTemplate]
  );

  const {
    isOpened: isStandardDialogOpened,
    closeDialog: closeStandardDialog,
    openDialog: openStandardDialog
  } = useDialog();

  const handleCreateTrackerFromAssignmentTemplate = useCallback(
    async ({ formData, onError }) => {
      const { success, errors, data } =
        await createTrackerFromAssignmentTemplate({
          assignmentTemplateId,
          data: formData
        });

      if (success) {
        await onCreateSuccess(data);
      } else {
        onError(errors);
      }

      return success;
    },

    [onCreateSuccess, assignmentTemplateId]
  );

  const onSubmit = async ({ values, setFieldError }) => {
    const formData = buildFormDataForCreate({ values });
    const onError = (errors) => setFormErrors(errors, setFieldError);

    return await handleCreateTrackerFromAssignmentTemplate({
      formData,
      onError
    });
  };

  const onStandardsSelected = ({
    selectedStandards,
    formikTasks,
    setFieldValue
  }) => {
    formikTasks.forEach((task, taskIndex) => {
      const objectiveStandardsFieldName = `tasks.${taskIndex}.standards`;

      setFieldValue(objectiveStandardsFieldName, selectedStandards);
    });
  };

  const TasksFormComponent = (props) => (
    <TasksForm {...props} isAssigned={false} />
  );

  return isLoading ? null : (
    <Formik
      enableReinitialize
      validationSchema={dialogValidationSchema}
      initialValues={initialValues}
      onSubmit={(values, actions) =>
        onSubmit({ values, setFieldError: actions.setFieldError })
      }
    >
      {({ errors, isSubmitting, handleSubmit, values, setFieldValue }) => {
        const assignmentStandards = _.chain(values.tasks)
          .map('standards')
          .flatten()
          .uniqBy('id')
          .value();

        return (
          <AttachmentDropzone
            mode={AssignmentDialogModes.CREATE_FROM_TEMPLATE}
            setFieldValue={setFieldValue}
            initialAttachments={values.attachments}
          >
            {({ attachments, onRemove, isDragActive, openFileDialog }) => (
              <>
                {isDragActive && <AttachmentUploadOverlay />}
                <DialogTitle disableTypography className={styles.dialogHeader}>
                  <div className={styles.stepperContainer}>{stepper}</div>

                  <Button
                    color="pink"
                    disabled={isSubmitting || !_.isEmpty(errors)}
                    onClick={handleSubmit}
                    disableElevation
                    endIcon={<ChevronRightRoundedIcon />}
                  >
                    Create
                  </Button>
                </DialogTitle>
                <DialogContent>
                  <div className={styles.container}>
                    <DebouncedField
                      value={values.assignmentName}
                      component={EmojiInputField}
                      hideEmojiTogglerOnInactiveInput
                      autoFocus
                      fullWidth
                      onChange={(value) =>
                        setFieldValue('assignmentName', value)
                      }
                      error={!_.isNil(errors.assignmentName)}
                      helperText={errors.assignmentName}
                      type="text"
                      placeholder="Assignment Name"
                      variant="underlined"
                      classes={{ root: styles.name }}
                      EmojiButtonProps={{ tabIndex: '-1' }}
                    />

                    <DebouncedField
                      value={values.description}
                      component={InputField}
                      multiline
                      fullWidth
                      minRows={1}
                      onChange={(value) => setFieldValue('description', value)}
                      error={!_.isNil(errors.description)}
                      helperText={errors.description}
                      type="text"
                      placeholder="Optional Description..."
                      variant="underlined"
                      classes={{ root: styles.description }}
                    />

                    <StandardButton
                      disabled={values.tasks.length === 0}
                      label={getStandardButtonLabel({
                        standards: assignmentStandards
                      })}
                      blue={assignmentStandards.length > 0}
                      onClick={openStandardDialog}
                      className={styles.standardButton}
                    />

                    <MaterialsButton
                      className={styles.materialsButton}
                      onClick={openFileDialog}
                    />

                    <AttachmentList
                      enableFileDownload
                      attachments={attachments}
                      onRemove={onRemove}
                    />

                    <FieldArray name="tasks" component={TasksFormComponent} />

                    {isSubmitting && (
                      <LinearProgress className={styles.linearProgress} />
                    )}
                  </div>
                </DialogContent>
                {isStandardDialogOpened && (
                  <StandardDialog
                    selectedItems={assignmentStandards}
                    onClose={closeStandardDialog}
                    onSave={({ selectedStandards }) =>
                      onStandardsSelected({
                        selectedStandards,
                        formikTasks: values.tasks,
                        setFieldValue
                      })
                    }
                  />
                )}
              </>
            )}
          </AttachmentDropzone>
        );
      }}
    </Formik>
  );
};

export default AssignmentFromTemplateForm;
