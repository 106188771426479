import React from 'react';
import Linkify from 'react-linkify';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import classnames from 'classnames';
import {
  STUDENTS_ASSIGNMENTS_DETAIL_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL
} from 'config/urls';
import _ from 'lodash';

import {
  StatusOptions,
  TaskInfo,
  Typography,
  withNavbar
} from 'pages/Students/shared';
import { ReactComponent as CameraIcon } from 'pages/Students/shared/assets/camera.svg';
import { colors } from 'theme/palette';
import { formatDate } from 'utils/moment';
import { notifyErrors } from 'utils/notifications';
import { openInNewTab, parseParams, stringifyParams } from 'utils/urls';
import { reverse } from 'utils/urls';

import { MagicLoginDialog } from './components/MagicLoginDialog';
import Button from 'components/Button';
import UserContext from 'components/UserContext';

import { ReactComponent as FileIcon } from './assets/file.svg';
import { ReactComponent as PhoneIcon } from './assets/phone.svg';
import { assignmentDetail, generateMagicLoginToken } from './sdk';
import styles from './styles.module.css';

const StyledLinearProgress = withStyles({
  root: {
    background: 'var(--white)',
    borderRadius: 4,
    height: 6
  },
  bar: {
    background: 'var(--light-blue)'
  }
})(LinearProgress);

const AssignmentInfo = ({
  assignment,
  onTakePhotoClick,
  generateMagicLoginToken,
  lgScreen
}) => {
  const completeTasks = _.size(
    _.filter(
      assignment.tasks,
      ({ status }) =>
        status === StatusOptions.COMPLETE || status === StatusOptions.SUBMITTED
    )
  );

  return (
    <div className={styles.assignmentInfoContainer}>
      <div className={styles.assignmentInfo}>
        <div>
          <Typography variant="H-TEXT-3" color={colors.white}>
            {_.size(assignment.tasks)} TASKS
          </Typography>
          <Typography variant="B-Text-3" color={colors.white}>
            Due {formatDate(assignment.due_at, 'ddd MM/D [at] h:mm a')}
          </Typography>
        </div>
        <div>
          <Typography variant="S-TEXT-2" color={colors.blue5}>
            {completeTasks} / {_.size(assignment.tasks)} Done
          </Typography>
          <StyledLinearProgress
            variant="determinate"
            value={(completeTasks / _.size(assignment.tasks)) * 100}
          />
        </div>
      </div>
      <Typography
        variant={lgScreen ? 'B-Text-2' : 'B-Text-3'}
        className={styles.description}
        color={colors.white}
      >
        <Linkify properties={{ target: '_blank' }}>
          {assignment.description}
        </Linkify>
      </Typography>
      {!_.isEmpty(assignment.attachments) && (
        <div>
          {_.map(assignment.attachments, (attachment) => (
            <div
              className={classnames(styles.attachment, 'pointer')}
              key={attachment.id}
              onClick={() => openInNewTab(attachment.file_url)}
            >
              <FileIcon />
              <Typography variant="B-Text-3" color={colors.blue5}>
                {attachment.original_filename}
              </Typography>
            </div>
          ))}
        </div>
      )}
      <Grid container justify="center">
        <Grid item>
          <Button
            onClick={onTakePhotoClick}
            color="lightGreen"
            className={styles.goButton}
            startIcon={
              <CameraIcon
                className={styles.cameraButtonIcon}
                fill={colors.white}
              />
            }
          >
            GO
          </Button>
          {lgScreen && (
            <Button
              onClick={generateMagicLoginToken}
              color="pink"
              className={styles.phoneButton}
              startIcon={
                <SvgIcon component={PhoneIcon} htmlColor={colors.white} />
              }
            >
              PHONE
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

class StudentAssignmentsOverview extends React.Component {
  static contextType = UserContext;

  state = {
    loading: true,
    assignment: null,
    magicLoginDialog: {
      open: false,
      token: null
    }
  };

  componentDidMount() {
    this.fetchAssignment();

    const { backUrl } = parseParams(
      _.get(this.props, 'history.location.search', '')
    );

    if (!_.isEmpty(backUrl)) {
      // If a back url is provided we use it for the back action.
      this.props.navigation.setBackButtonAction(() =>
        this.props.history.push(backUrl)
      );
    } else {
      this.props.navigation.setBackButtonAction(this.goToAssignmentsList);
    }
  }

  fetchAssignment = async () => {
    const { assignmentId } = this.props.match.params;
    const { student_id: studentId } = this.context.user;

    const {
      data: assignment,
      success,
      errors
    } = await assignmentDetail(studentId, assignmentId);

    if (success) {
      this.setState({ assignment, loading: false });
      this.props.navigation.setTitle(assignment.name);
    } else {
      this.setState({ loading: false, assignment: null }, () =>
        notifyErrors(errors)
      );
      this.props.navigation.setTitle(null);
    }
  };

  goToAssignmentsList = () =>
    this.props.history.push(STUDENTS_ASSIGNMENTS_LIST_URL);

  goToAssignmentDetail = (taskId, camera) => {
    const assignmentId = _.get(this.props, 'match.params.assignmentId');

    this.props.history.push({
      pathname: reverse(STUDENTS_ASSIGNMENTS_DETAIL_URL, {
        assignmentId
      }),
      search: stringifyParams({ task: taskId, camera })
    });
  };

  generateMagicLoginToken = async () => {
    const postData = {
      redirect_path:
        process.env.REACT_APP_BASE_FRONTEND_URL +
        this.props.history.location.pathname
    };

    const { data, success, errors } = await generateMagicLoginToken(postData);

    if (success) {
      this.setState({
        magicLoginDialog: {
          open: true,
          token: data.key
        }
      });
    } else {
      notifyErrors(errors);
    }
  };

  closeMagicLoginDialog = () => {
    this.setState({
      magicLoginDialog: {
        ...this.state.magicLoginDialog,
        open: false
      }
    });
  };

  render() {
    const { assignment, loading, magicLoginDialog } = this.state;

    const lgScreen = isWidthUp('sm', this.props.width);

    if (loading) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <CircularProgress color="secondary" />
          Loading...
        </Box>
      );
    }

    if (!assignment) {
      return <h3>Assignment does not exist.</h3>;
    }

    return (
      <div className={styles.content}>
        <AssignmentInfo
          lgScreen={lgScreen}
          assignment={assignment}
          onTakePhotoClick={() =>
            this.goToAssignmentDetail(
              _.get(_.first(assignment.tasks), 'id'),
              true
            )
          }
          generateMagicLoginToken={this.generateMagicLoginToken}
        />
        <div className={styles.tasksContainer}>
          {_.map(assignment.tasks, (task, index) => (
            <TaskInfo
              key={task.id}
              lgScreen={lgScreen}
              onClick={() => this.goToAssignmentDetail(task.id, false)}
              index={index + 1}
              task={task}
            />
          ))}
        </div>
        {magicLoginDialog.open && (
          <MagicLoginDialog
            token={magicLoginDialog.token}
            closeDialog={this.closeMagicLoginDialog}
          />
        )}
      </div>
    );
  }
}

export default withWidth()(withNavbar(StudentAssignmentsOverview));
