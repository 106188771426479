import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { TEACHERS_TEAM_DETAIL_URL } from 'config/urls';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { reverse } from 'utils/urls';

import Typography from 'components/Typography';
import UpdateTeacherTeamDialog from 'components/UpdateTeacherTeamDialog';

import { ReactComponent as EditIcon } from './assets/edit.svg';
import styles from './styles.module.css';

const TeamCard = ({ team, onTeamUpdate }) => {
  const {
    isOpened: isUpdateTeamDialogOpened,
    openDialog: openUpdateTeamDialog,
    closeDialog: closeUpdateTeamDialog,
    dialogData: updateTeamDialogData,
    setDialogData: setUpdateTeamDialogData
  } = useDialog();

  // TODO: Implement this on the backend
  const linkTo = useMemo(
    () => reverse(TEACHERS_TEAM_DETAIL_URL, { teamId: team.id }),
    [team.id]
  );

  const onEditIconClick = useCallback(() => {
    setUpdateTeamDialogData({ teamId: team.id });
    openUpdateTeamDialog();
  }, [team.id, setUpdateTeamDialogData, openUpdateTeamDialog]);

  return (
    <>
      <Paper variant="outlined" className={styles.teamCard}>
        <div className={styles.header}>
          <Link to={linkTo} className={styles.teamCardLink}>
            <Typography variant="H-TEXT-3" color={colors.blue1}>
              {team.name}
            </Typography>
          </Link>
          <IconButton
            className={styles.editIcon}
            size="small"
            onClick={onEditIconClick}
          >
            <EditIcon fill={colors.grey2} />
          </IconButton>
        </div>
        <Divider />
        <div className={styles.content}>
          <div>
            <Typography variant="S-TEXT-1" color={colors.blue1}>
              Staff
            </Typography>
            <Typography
              variant="B-Text-3"
              color={colors.grey2}
              className={styles.usersNames}
            >
              {_.join(_.map(team.teacher_members, 'user.name'), ', ')}
            </Typography>
          </div>
          <div className={styles.countWrapper}>
            <Typography variant="S-TEXT-1" color={colors.blue1}>
              {team.meetings_count} Meetings
            </Typography>
          </div>
        </div>
      </Paper>
      {isUpdateTeamDialogOpened && (
        <UpdateTeacherTeamDialog
          {...updateTeamDialogData}
          onClose={closeUpdateTeamDialog}
          onSuccess={onTeamUpdate}
        />
      )}
    </>
  );
};

export default React.memo(TeamCard);
