import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import classnames from 'classnames';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.css';

class TeacherResponsiveLayout extends React.Component {
  render() {
    const { hideTitle = false, title, children, onBackClick } = this.props;

    return (
      <div className={styles.pageWrapper}>
        {!hideTitle && (
          <div className={classnames(styles.top, styles.navigation)}>
            <div className={styles.topNavContent}>
              <IconButton onClick={onBackClick} className={styles.backButton}>
                <ArrowBackIcon className={styles.backIcon} />
              </IconButton>
              <Typography
                className={styles.title}
                variant="H-TEXT-1"
                color={colors.blue1}
                align="center"
              >
                {title}
              </Typography>
            </div>
          </div>
        )}
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    );
  }
}

export default TeacherResponsiveLayout;
