import React from 'react';

import classnames from 'classnames';

import computerBackground from './assets/computer-background.svg';
import mobileBackground from './assets/mobile-background.svg';
import tabletBackground from './assets/tablet-background.svg';
import styles from './styles.module.css';

const LayoutWithBackgroundImage = ({ children, className }) => {
  return (
    <>
      <img
        src={computerBackground}
        className={styles.computerBackground}
        alt="Computer Background"
      />
      <img
        src={tabletBackground}
        className={styles.tabletBackground}
        alt="Tablet Background"
      />
      <img
        src={mobileBackground}
        className={styles.mobileBackground}
        alt="Mobile Background"
      />
      <div className={classnames(styles.pageContainer, className)}>
        {children}
      </div>
    </>
  );
};

export default LayoutWithBackgroundImage;
