import React, { useEffect, useMemo } from 'react';

import _ from 'lodash';
import {
  useCollegeSchoolUsers,
  useStudentPortfolioStudentsList,
  useUserSectionsNames
} from 'sdk';

import { buildFilters } from 'utils/filters';

import DebouncedSearchFilter from 'components/DebouncedSearchFilter';

import styles from './styles.module.css';
import { useDefaultFilters } from './utils';

const Filters = ({ filters, setFilters, history }) => {
  const defaultFilters = useDefaultFilters(history);

  useEffect(() => {
    if (!_.isEmpty(defaultFilters)) {
      setFilters((prevFilters) => ({ ...defaultFilters, ...prevFilters }));
    }
  }, [defaultFilters, setFilters]);

  const studentGroupNameValue = _.get(filters, 'studentGroupName', '');
  const { data: studentGroupsOptions } = useUserSectionsNames();

  const teacherValue = _.get(filters, 'tutor', '');
  const teacherSearch = useMemo(
    () => buildFilters({ search: teacherValue }),
    [teacherValue]
  );
  const { data: teacherData } = useCollegeSchoolUsers({
    params: teacherSearch
  });
  const teacherOptions = useMemo(() => {
    if (_.isNil(teacherData)) {
      return [];
    }

    const teacherNames = _.map(teacherData.results, 'name');

    return [...new Set(teacherNames)];
  }, [teacherData]);

  const studentValue = _.get(filters, 'student', '');
  const studentsSearch = useMemo(
    () => buildFilters({ search: studentValue }),
    [studentValue]
  );
  const { data: studentsList } = useStudentPortfolioStudentsList({
    params: studentsSearch
  });
  const studentsOptions = useMemo(
    () => _.map(studentsList, 'name'),
    [studentsList]
  );

  return (
    <div className={styles.filtersWrapper}>
      <DebouncedSearchFilter
        freeSolo
        autoComplete
        placeholder="Class / Group Name"
        options={studentGroupsOptions || []}
        value={studentGroupNameValue}
        onChange={(value) => {
          setFilters((filters) => ({ ...filters, studentGroupName: value }));
        }}
      />
      <DebouncedSearchFilter
        freeSolo
        autoComplete
        placeholder="Student"
        options={studentsOptions}
        value={studentValue}
        onChange={(value) => {
          setFilters((filters) => ({ ...filters, student: value }));
        }}
      />
      <DebouncedSearchFilter
        freeSolo
        autoComplete
        placeholder="Teacher"
        options={teacherOptions}
        value={teacherValue}
        onChange={(value) =>
          setFilters((filters) => ({
            ...filters,
            tutor: value
          }))
        }
      />
    </div>
  );
};

export default Filters;
