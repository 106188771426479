import React from 'react';
import { isMobile } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import Truncate from 'react-truncate';
import Webcam from 'react-webcam';

import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import Alert from '@material-ui/lab/Alert';
import imagefilters from 'canvas-filters';
import classnames from 'classnames';
import _ from 'lodash';

import { AssignmentStatus } from 'pages/Students/shared';
import { ImgixDimensions } from 'utils/constants';
import { compressImage, fileToBase64 } from 'utils/files';
import { notifyError } from 'utils/notifications';

import Flex from 'components/Flex';
import Image from 'components/Image';
import ImgixImage from 'components/ImgixImage';
import Timer from 'components/Timer';
import WebcamError from 'components/WebcamError';

import styles from './styles.module.css';

const WEBCAM_CAPTURE_WIDTH = 1280;

const MAX_ATTACHMENT_FILE_SIZE_IN_BYTES = 20 * 1000 * 1000; // 20MB

const white = withStyles({
  root: {
    color: 'var(--white)',
    fontSize: '3rem'
  }
});
const StyledDoneRoundedIcon = white(DoneRoundedIcon);
const StyledClearRoundedIcon = white(ClearRoundedIcon);
const StyledChevronLeftRoundedIcon = white(ChevronLeftRoundedIcon);
const StyledChevronRightRoundedIcon = white(ChevronRightRoundedIcon);
const StyledCheckCircleRoundedIcon = withStyles({
  root: { color: 'var(--white)' }
})(CheckCircleRoundedIcon);

const StyledLoading = withStyles({
  root: {
    color: 'var(--white)'
  }
})(CircularProgress);

const TaskInfo = ({ task, img, changeTask, showArrows }) => {
  const getCriteria = () => {
    // Poor man lines truncation
    const criteriaSize = _.size(task.criteria);

    if (criteriaSize <= 3) {
      return task.criteria;
    }

    const visualizedCriteria = _.slice(task.criteria, 0, 2);
    return [
      ...visualizedCriteria,
      // We make a "dummy" criteria to show the users that there is more criteria
      {
        name: `${criteriaSize - 2} more...`,
        value: false,
        id: _.last(visualizedCriteria).id + 100
      }
    ];
  };

  return (
    <div className={styles.taskInfoWrapper}>
      {showArrows ? (
        <IconButton onClick={() => changeTask(-1)}>
          <StyledChevronLeftRoundedIcon />
        </IconButton>
      ) : (
        <div />
      )}

      <div className={styles.taskInfo}>
        <div
          className={classnames(styles.imgWrapper, {
            [styles.noImage]: _.isNil(img)
          })}
        >
          <AssignmentStatus
            className={styles.status}
            status={task.status}
            variant="light"
            hideText
          />
          {img && (
            <ImgixImage
              src={img}
              dimensions={ImgixDimensions.SMALL}
              className={styles.imgPreview}
              alt={`Student work for ${task.name}`}
            />
          )}
        </div>
        <div className={styles.info}>
          <h3 className={styles.taskName}>
            <Truncate key={task.name} lines={1}>
              {task.name}
            </Truncate>
          </h3>
          <div className={styles.criteria}>
            {_.map(getCriteria(), ({ id, name, value }, index) => (
              <Flex key={id} flexStart>
                <StyledCheckCircleRoundedIcon
                  disabled={_.isNil(value)}
                  fontSize="small"
                />
                <span className={styles.criteriaName}>{name}</span>
              </Flex>
            ))}
          </div>
        </div>
      </div>

      {showArrows ? (
        <IconButton onClick={() => changeTask(1)}>
          <StyledChevronRightRoundedIcon />
        </IconButton>
      ) : (
        <div />
      )}
    </div>
  );
};

class WebcamCapture extends React.Component {
  constructor(props) {
    super(props);

    this.webcamRef = React.createRef();
    this.canvasRef = React.createRef();

    this.lastShot = null;
    this.lastBlurScore = 0;
    this.animationFrameId = null;
  }

  state = {
    cameraError: null,
    capturedImagesData: this.initializeImages(),
    startTimer: false,
    findBestFrame: false,
    previewImage: null,
    uploadedImageBlob: null // storing the base64 of the image before the upload confirmation
  };

  componentWillUnmount() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  initializeImages() {
    // Return value: { [task.id]: null }
    const { tasks } = this.props;

    return _.zipObject(_.map(tasks, 'id'), {
      image: null,
      submitting: false
    });
  }

  // returns num with the specified number of decimal places
  reduceDecimalPlaces(num, numDecimals) {
    var pow10s = Math.pow(10, numDecimals || 0);
    return numDecimals ? Math.round(pow10s * num) / pow10s : num;
  }

  // returns mean value of an array
  getArrayAverage(imageArray, numDecimals) {
    var width = imageArray[0].length,
      height = imageArray.length;

    var sum = _.sum(_.flatten(imageArray));

    return this.reduceDecimalPlaces(sum / (width * height), numDecimals);
  }

  // returns the variance  of an array
  getArrayVariance(imageArray, numDecimals) {
    var avg = this.getArrayAverage(imageArray, numDecimals),
      variance = 0,
      x,
      y,
      width = imageArray[0].length,
      height = imageArray.length;

    // Loop through imageArray to compute the variance
    for (y = 0; y < height; y += 1) {
      for (x = 0; x < width; x += 1) {
        variance += Math.pow(imageArray[y][x] - avg, 2);
      }
    }
    variance /= width * height;
    return this.reduceDecimalPlaces(variance, numDecimals);
  }

  // Convolve over the image with a laplacian kernel to detect edges.
  detectEdges(imageData, context, canvas) {
    var grayImage;
    // first convert the image to grayscale
    grayImage = imagefilters.GrayScale(imageData);

    // now detect the edges using the Edge function,
    // which convolves over the image with a laplacian kernel
    return imagefilters.Edge(grayImage);
  }

  // Compute a blur score based on the imageData after the laplacian kernel
  // has been applied. The blur score is determined by the variance of the
  // image. If an image contains high variance then there is a wide spread
  // of responses, both edge-like and non-edge like, representative of a
  // normal, in-focus image. But if there is very low variance, then there
  // is a tiny spread of responses, indicating there are very little edges
  // in the image. The more an image is blurred, the less edges there are.
  detectBlur(imageData, context, canvas) {
    // //////////////////////////////////////////////////////////////
    //   // THIS SECTION IS ONLY FOR VISUALIZING EDGES WHILE CODING /
    //   // context.putImageData(imageData,0,0);
    //   //
    //   // var debugImage = document.createElement('img');
    //   // debugImage.onload = function(){
    //   //   document.body.appendChild(debugImage);

    //   // }
    //   // debugImage.src= canvas.toDataURL();
    // /////////////////////////////////////////////////////////////
    // /////////////////////////////////////////////////////////////

    var i,
      x,
      y,
      row,
      pixels = imageData.data,
      rowLen = imageData.width * 4,
      rows = [];

    // convert the image data into an Array
    for (y = 0; y < pixels.length; y += rowLen) {
      row = new Array(imageData.width);
      x = 0;
      for (i = y; i < y + rowLen; i += 4) {
        row[x] = pixels[i];
        x += 1;
      }
      rows.push(row);
    }
    var precision = 4; // number of decimals

    // now that it's converted to an array, we can calculate the variance
    return this.getArrayVariance(rows, precision);
  }

  // Passes lower quality image source into detectBlur. If the current frame is
  // less blurry than previous frames, the program stores the source data for
  // the higher-quality image. Using the lower quality data for analysis alows us
  // to compute the blur in real time since it doesn't take as much computation.
  tick = () => {
    if (!this.webcamRef.current) {
      return;
    }

    // The timer can be reset. For example when the task has changed during the capture phase
    if (!this.state.startTimer) {
      this.resetBlurImageHelpers();
      return;
    }

    const imgBase64 = this.webcamRef.current.getScreenshot();

    const smallImage = document.createElement('img');

    smallImage.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = 300; // downsize the image so the width is 300 px wide
      canvas.height = (300 * smallImage.height) / smallImage.width;

      context.drawImage(smallImage, 0, 0, canvas.width, canvas.height);

      const blurScore = this.detectBlur(
        this.detectEdges(
          context.getImageData(0, 0, canvas.width, canvas.height),
          context,
          canvas
        ),
        context,
        canvas
      );

      if (blurScore > this.lastBlurScore) {
        // Store the least blurry frame and its blur score in global variables
        this.lastBlurScore = blurScore;
        this.lastShot = imgBase64;
      }
    };

    smallImage.onerror = notifyError;

    smallImage.src = imgBase64;

    // analyze a new frame every 0.1 seconds
    setTimeout(() => {
      this.animationFrameId = requestAnimationFrame(this.tick);
    }, 100);
  };

  onUserMediaError = (error) => {
    this.setState({ cameraError: error });
  };

  // This function is called when the student starts the timer.
  // It waits until the 1-second mark to start the blur detection.
  startCapture = () => {
    if (this.animationFrameId !== null) {
      return;
    }
    window.navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = _.filter(devices, { kind: 'videoinput' });

      if (_.isEmpty(videoDevices) || !_.some(videoDevices, 'label')) {
        // Camera device is not allowed
        this.setState({ cameraError: { name: 'TrackStartError' } });
        return;
      }
    });

    this.setState({ startTimer: true });

    // Wait two seconds before looking for the best frame
    setTimeout(() => {
      this.setState({ findBestFrame: true }, () => {
        // Check https://css-tricks.com/using-requestanimationframe/ for more info.
        this.animationFrameId = requestAnimationFrame(this.tick);
      });
    }, 2000);
  };

  // This function is called when the user is capturing photos from mobile devices
  capturePhotoMobile = () => {
    if (!this.webcamRef.current) {
      return;
    }

    const imgBase64 = this.webcamRef.current.getScreenshot();
    if (_.isNull(imgBase64)) {
      this.setState({ cameraError: { name: 'TrackStartError' } });
      return;
    }

    const finalImage = document.createElement('img');

    // info for cropping images
    const topNavBarPosit = 48; // top nav bar has height of 48 px
    const webcamBoundingRect = this.webcamDiv
      ? this.webcamDiv.getBoundingClientRect()
      : null;
    const taskNavBoundingRect = this.taskNav
      ? this.taskNav.getBoundingClientRect()
      : null;

    let overflowingBottom, overflowingTop, capButtonTop;
    if (webcamBoundingRect && taskNavBoundingRect) {
      capButtonTop = taskNavBoundingRect.top + 2; // add 2 pixels to account for border
      overflowingBottom = webcamBoundingRect.bottom > capButtonTop; // indicates whether image overflows buttons
      overflowingTop = webcamBoundingRect.top < topNavBarPosit; // indicates whether image overflows top nav bar
    }

    finalImage.onload = () => {
      let capturedImage = imgBase64;

      if (overflowingBottom || overflowingTop) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const croppedHeight =
          (finalImage.height * (capButtonTop - topNavBarPosit)) /
          webcamBoundingRect.height;

        const startY = overflowingTop
          ? (finalImage.height * (topNavBarPosit - webcamBoundingRect.top)) /
            webcamBoundingRect.height
          : 0;

        canvas.width = finalImage.width;
        canvas.height = croppedHeight;
        context.drawImage(
          finalImage,
          0,
          startY,
          finalImage.width,
          croppedHeight,
          0,
          0,
          finalImage.width,
          croppedHeight
        );

        capturedImage = canvas.toDataURL();
      }

      this.setState({
        capturedImagesData: {
          ...this.state.capturedImagesData,
          [this.props.activeTask.id]: {
            ...this.state.capturedImagesData[this.props.activeTask.id],
            image: capturedImage
          }
        },
        previewImage: capturedImage
      });
    };
    finalImage.onerror = notifyError;
    finalImage.src = imgBase64;
  };

  resetBlurImageHelpers = () => {
    this.lastShot = null;
    this.lastBlurScore = 0;
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    this.animationFrameId = null;
  };

  // This function is called when the user is capturing photos from a laptop. It is called
  // once the timer hits zero. It checks whether the final image must be mirrored and acts
  // accordingly. Then it saves the final image by calling setState.
  capturePhotoLaptop = () => {
    const finalImage = document.createElement('img');

    finalImage.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // The numbers seen here for cropping are based on:
      // .webcamBox { width: 70%; height: 90% }
      const cropStartX = finalImage.width * 0.15;
      const cropStartY = 0.05 * finalImage.height;
      const croppedWidth = finalImage.width * 0.7;
      const croppedHeight = finalImage.height * 0.9;

      canvas.width = croppedWidth;
      canvas.height = croppedHeight;

      context.translate(canvas.width, 0);
      context.scale(-1, 1);
      context.drawImage(
        finalImage,
        cropStartX,
        cropStartY,
        croppedWidth,
        croppedHeight,
        0,
        0,
        croppedWidth,
        croppedHeight
      );

      this.setState(
        {
          capturedImagesData: {
            ...this.state.capturedImagesData,
            [this.props.activeTask.id]: {
              ...this.state.capturedImagesData[this.props.activeTask.id],
              image: canvas.toDataURL()
            }
          },
          previewImage: canvas.toDataURL()
        },
        this.resetBlurImageHelpers
      );
    };

    finalImage.onerror = notifyError;

    // this line triggers the finalImage.onload function
    finalImage.src = this.lastShot;
  };

  removeCapturedPhoto = () => {
    this.setState(
      {
        capturedImagesData: {
          ...this.state.capturedImagesData,
          [this.props.activeTask.id]: {
            ...this.state.capturedImagesData[this.props.activeTask.id],
            image: null
          }
        },
        findBestFrame: false,
        startTimer: false,
        previewImage: null
      },
      this.resetBlurImageHelpers
    );
  };

  uploadCapturedPhoto = async () => {
    const { activeTask, uploadStudentWork } = this.props;

    const activeTaskImg = _.get(
      this.state.capturedImagesData,
      `${activeTask.id}.image`
    );

    // Set submitting state
    this.setState({
      capturedImagesData: {
        ...this.state.capturedImagesData,
        [activeTask.id]: {
          image: activeTaskImg,
          submitting: true
        }
      },
      findBestFrame: false,
      startTimer: false,
      previewImage: activeTaskImg
    });

    await uploadStudentWork(activeTaskImg);

    // Stop submitting state
    this.setState(
      {
        capturedImagesData: {
          ...this.state.capturedImagesData,
          [activeTask.id]: {
            image: null,
            submitting: false
          }
        },
        findBestFrame: false,
        startTimer: false,
        previewImage: null
      },
      this.resetBlurImageHelpers
    );
  };

  saveImageBlob = (files) => {
    compressImage(files[0], {
      maxSizeMB: 1,
      maxWidth: 1280,
      maxHeight: 720
    })
      .then(fileToBase64)
      .then((blob) =>
        this.setState({ uploadedImageBlob: blob, previewImage: blob })
      )
      .catch(notifyError);
  };

  uploadSavedImageBlob = async () => {
    if (this.state.uploadedImageBlob) {
      const { activeTask } = this.props;

      // Set submitting state
      this.setState({
        capturedImagesData: {
          ...this.state.capturedImagesData,
          [activeTask.id]: {
            submitting: true
          }
        }
      });

      await this.props.uploadStudentWork(this.state.uploadedImageBlob);

      // Stop submitting state
      this.setState({
        capturedImagesData: {
          ...this.state.capturedImagesData,
          [activeTask.id]: {
            submitting: false
          }
        },
        uploadedImageBlob: null,
        previewImage: null
      });
    }
  };

  removeSavedImageBlob = () => {
    if (this.state.uploadedImageBlob) {
      this.setState({ uploadedImageBlob: null, previewImage: null });
    }
  };

  onDropRejected = (files) =>
    _.forEach(files, (file) =>
      notifyError(`${file.name} exceeds the size limit of 20 MB.`)
    );

  getTaskImagePreview = (task) => {
    const { capturedImagesData } = this.state;
    const imageData = _.get(capturedImagesData, task.id);
    const currentImgSrc = _.get(imageData, 'image');

    if (currentImgSrc) {
      return currentImgSrc;
    }

    return _.get(_.first(task.work), 'work_url');
  };

  changeTask = (step) => {
    this.props.changeTask(step).then((activeTask) => {
      const previewImage = _.get(
        this.state.capturedImagesData,
        `${activeTask.id}.image`
      );

      this.setState(
        {
          previewImage,
          findBestFrame: false,
          startTimer: false
        },
        this.resetBlurImageHelpers
      );
    });
  };

  render() {
    const { cameraError, capturedImagesData, startTimer, previewImage } =
      this.state;
    const { tasks, activeTask } = this.props;

    const imageData = _.get(capturedImagesData, activeTask.id);

    const currentImgSubmitting = _.get(imageData, 'submitting');

    return (
      <div
        className={previewImage ? styles.webcamViewSetDims : styles.webcamView}
      >
        {!cameraError && !previewImage && (
          <div ref={(el) => (this.webcamDiv = el)}>
            <Webcam
              className={styles.webcam}
              audio={false}
              ref={this.webcamRef}
              forceScreenshotSourceSize={true} // we can set the dimension of the screenshot
              screenshotFormat="image/jpeg"
              mirrored={!isMobile}
              screenshotQuality={1} // set the screenshot quality to 100%. The default is 92%
              videoConstraints={{
                facingMode: 'environment', // opens rare (back) camera with priority (if on phone)
                // Set the dimensions of the screenshot
                width: isMobile ? 960 : WEBCAM_CAPTURE_WIDTH
              }}
              onUserMediaError={this.onUserMediaError}
            />

            {/* This is the guide line box */}
            {!isMobile && <div className={styles.webcamBox} />}
          </div>
        )}

        {currentImgSubmitting && (
          <Backdrop open style={{ zIndex: 1 }}>
            <Box textAlign="center">
              <StyledLoading size={52} />
              <Box color="white" fontSize="1.4rem" marginTop={2}>
                Uploading...
              </Box>
            </Box>
          </Backdrop>
        )}

        {previewImage && (
          <Image
            className={styles.previewImage}
            alt={`The captured image for task ${activeTask.name}`}
            src={previewImage}
          />
        )}

        {cameraError &&
          (cameraError.name === 'NotReadableError' ||
          cameraError.name === 'TrackStartError' ? (
            <div className={styles.errorContainer}>
              <WebcamError />
            </div>
          ) : (
            <Alert severity="warning">
              Cannot start camera: {cameraError.toString()}
            </Alert>
          ))}

        {!cameraError && (
          <div
            className={styles.taskNavigation}
            ref={(el) => (this.taskNav = el)}
          >
            <div className={styles.buttonsWrapper}>
              {!previewImage && (
                <Dropzone
                  onDrop={this.saveImageBlob}
                  maxSize={MAX_ATTACHMENT_FILE_SIZE_IN_BYTES}
                  accept={['image/jpeg', 'image/png']}
                  onDropRejected={this.onDropRejected}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className={styles.uploadButton} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <IconButton>
                        <AddPhotoAlternateIcon
                          color="primary"
                          fontSize="large"
                        />
                      </IconButton>
                    </div>
                  )}
                </Dropzone>
              )}

              {!previewImage && (
                <div
                  className={classnames(styles.cameraBtn, styles.screenshot)}
                  onClick={
                    isMobile ? this.capturePhotoMobile : this.startCapture
                  }
                >
                  {startTimer && !isMobile && (
                    <Timer seconds={3} onFinish={this.capturePhotoLaptop} />
                  )}
                </div>
              )}

              {previewImage && !this.state.uploadedImageBlob && (
                <div className={styles.confirmDeleteButtons}>
                  <div
                    className={classnames(styles.cameraBtn, styles.delete)}
                    onClick={this.removeCapturedPhoto}
                  >
                    <StyledClearRoundedIcon />
                  </div>
                  <div
                    className={classnames(styles.cameraBtn, styles.confirm)}
                    onClick={this.uploadCapturedPhoto}
                  >
                    <StyledDoneRoundedIcon />
                  </div>
                </div>
              )}

              {previewImage && this.state.uploadedImageBlob && (
                <div className={styles.confirmDeleteButtons}>
                  <div
                    className={classnames(styles.cameraBtn, styles.delete)}
                    onClick={this.removeSavedImageBlob}
                  >
                    <StyledClearRoundedIcon />
                  </div>
                  <div
                    className={classnames(styles.cameraBtn, styles.confirm)}
                    onClick={this.uploadSavedImageBlob}
                  >
                    <StyledDoneRoundedIcon />
                  </div>
                </div>
              )}

              {!previewImage && <div className={styles.rightSidePlaceholder} />}
            </div>
            <TaskInfo
              task={activeTask}
              img={this.getTaskImagePreview(activeTask)}
              changeTask={this.changeTask}
              showArrows={_.size(tasks) > 1}
            />
          </div>
        )}
      </div>
    );
  }
}

export default WebcamCapture;
