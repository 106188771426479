import { useEffect, useMemo, useRef, useState } from 'react';

import { BASE_URL } from 'config/urls';
import _ from 'lodash';

import { parseAndPrepareStudentPortfolioDateFilters } from 'pages/Students/utils';
import {
  callUrl,
  get,
  post,
  requestSdk,
  useFetchV2 as useFetch,
  useFetchInfinite
} from 'utils/sdk';
import { stringifyParams } from 'utils/urls';

export const getStandards = (params) =>
  callUrl(get, `${BASE_URL}/v1/standards/`, params);

export const usePagination = ({
  initialData,
  isLoading: initialIsLoading = false
}) => {
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const [results, setResults] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);

  useEffect(() => {
    setNextUrl(_.get(initialData, 'next', null));
    setResults(_.get(initialData, 'results', []));
  }, [initialData]);

  useEffect(() => {
    setIsLoading(initialIsLoading);
  }, [initialIsLoading]);

  const hasMore = useMemo(() => !_.isNil(nextUrl), [nextUrl]);

  const fetchMore = async () => {
    if (nextUrl) {
      setIsLoading(true);

      const response = await get(nextUrl);

      if (response.status.toString().startsWith('2')) {
        setNextUrl(response.data.next);
        setResults((prevResults) => [...prevResults, ...response.data.results]);
      } else {
        setNextUrl(null);
        // Add some toast or something else.
      }

      setIsLoading(false);
    }
  };

  return { results, hasMore, fetchMore, isLoading };
};

export const useAssignmentResults = (selectedSectionId) =>
  useFetch(
    selectedSectionId
      ? [`/v1/trackers/assignments/${selectedSectionId}/results/`]
      : undefined
  );

export const addCommentToAssignment = ({ id, text, parent }) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/instances/sections/${id}/add-comment/`,
    { text, parent }
  );

export const addCommentToSharedAssignment = ({ id, text, parent }) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/instances/sections/shared/${id}/add-comment/`,
    { text, parent }
  );

export const addCommentToSection = ({ sectionId, data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/sections/${sectionId}/add-comment/`, data)
  );

export const useStandardsForStudentPortfolio = ({ studentId, filters }) =>
  useFetch(
    filters
      ? [`/v1/trackers/student-portfolio/${studentId}/standards/`]
      : undefined,
    filters
  );

// This is the same thing as the previous method but it refetches when `filters` changes, instead of
// having the need to call the exported refetch method.
export const useStandardsForStudentPortfolioWithFilters = ({
  studentId,
  filters
}) => {
  const formattedFilters = Object.assign(
    _.omit(filters, ['startDate', 'endDate']),
    parseAndPrepareStudentPortfolioDateFilters({ filters })
  );

  return useFetch(
    [
      `/v1/trackers/student-portfolio/${studentId}/standards/`,
      formattedFilters
    ],
    formattedFilters
  );
};

export const useStudentSections = ({ studentId }) =>
  useFetch([`/v1/trackers/student-portfolio/sections/${studentId}/`]);

export const useStudentAssignments = ({ studentId }) =>
  useFetch([`/v1/trackers/student-portfolio/assignments-names/${studentId}/`]);

export const useCurrentSchoolYear = () =>
  useFetch([`/v1/core/current-school-year/`]);

export const studentPortfolioTriggerAssignmentsExport = async (
  studentId,
  requestParams
) =>
  await requestSdk(() =>
    get(
      `${BASE_URL}/v1/trackers/student-portfolio/trigger-export-assignments/${studentId}/`,
      requestParams
    )
  );

export const studentPortfolioTriggerAssignmentsWithStandardsExport = async (
  studentId,
  requestParams
) =>
  await requestSdk(() =>
    get(
      `${BASE_URL}/v1/trackers/student-portfolio/trigger-export/assignments-for-standards/${studentId}/`,
      requestParams
    )
  );

export const studentPortfolioGetAssignmentsExportUrl = (exportUUID) =>
  `${BASE_URL}/v1/trackers/student-portfolio/export-url/${exportUUID}/`;

export const useStudentPortfolio = ({ studentId, filters }) => {
  const formattedFilters = Object.assign(
    _.omit(filters, ['startDate', 'endDate']),
    parseAndPrepareStudentPortfolioDateFilters({ filters })
  );

  return useFetch(
    _.isEmpty(filters)
      ? undefined
      : [`/v1/trackers/student-portfolio/${studentId}/`, formattedFilters],
    formattedFilters
  );
};

export const useStudent = ({ studentId }) =>
  useFetch([`/v1/students/${studentId}/detail/`]);

export const addAssignmentComment = async ({ studentId, assignmentId, data }) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/students/${studentId}/assignments/${assignmentId}/add-comment/`,
      data
    )
  );

export const useCurriculums = ({ parentId }) =>
  useFetch(['/v1/curriculum/', parentId], { parent_id: parentId });

export const useAssignmentTemplate = ({ assignmentTemplateId }) =>
  useFetch([
    `/v1/curriculum/assignment-templates/${assignmentTemplateId}/retrieve/`
  ]);

export const createTrackerFromAssignmentTemplate = async ({
  assignmentTemplateId,
  data
}) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/create/from-assignment-template/${assignmentTemplateId}/`,
      data
    )
  );

export const useMeetingBoards = ({ selectedMeetingId, queryParams }) =>
  useFetch(
    selectedMeetingId
      ? [`/v1/meetings/${selectedMeetingId}/boards/`, queryParams]
      : undefined
  );

export const assingAssignmentToBulkUpload = async ({ bulkUploadId, data }) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/assign-assignment/`, data)
  );

export const useAssignmentsNamesList = ({ data }) =>
  useFetch([`/v1/trackers/assignments/names-list/`, data], data);

export const useRecentlyCreatedAssignments = ({ params }) => {
  return useFetch([
    `/v2/trackers/assignments/recent/list/?` + stringifyParams(params)
  ]);
};

export const useMeetingBoardShares = ({ meetingBoardId }) =>
  useFetch([`/v1/meetings/boards/${meetingBoardId}/shares/`]);

export const meetingBoardUpdate = async ({ meetingBoardId, data }) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/boards/${meetingBoardId}/update/`, data)
  );

export const meetingBoardDelete = async ({ meetingBoardId }) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/boards/${meetingBoardId}/delete/`)
  );

export const useUsersInUserSections = () =>
  useFetch(['/v1/sections/users-in-sections/']);

export const useYears = () => useFetch(['/v1/core/years/active/']);

export const useUserSections = ({ params }) =>
  useFetch([`/v1/sections/user/list/`, params], params);

export const useTrackersOverview = ({ params, enabled }) => {
  const random = useRef(Date.now());

  return useFetch(
    enabled ? ['/v1/trackers/overview/', params, random] : undefined,
    params
  );
};

export const useOrganizations = () => useFetch([`/v1/organizations/`]);

export const useClosestTask = () =>
  useFetch([`/v1/trackers/assignments/get-closest-task/`]);

export const useUserSectionsList = ({ params, enabled = true }) =>
  useFetch(
    enabled ? ['/v1/sections/list-for-user/', params] : undefined,
    params
  );

export const useCollegeSchoolUsers = ({ params }) =>
  useFetch(['/v1/schools/collegue-school-users/', params], params);

export const useStudentPortfolioStudentsList = ({ params }) =>
  useFetch(['/v1/trackers/student-portfolio/students-list/', params], params);

export const useUserSectionsCount = () =>
  useFetch(['/v1/sections/count-for-user/']);

export const useSessionsStatistics = () =>
  useFetch(['/v1/trackers/shared-tracker-instance-sections/statistics/']);

export const useSubmittedTasksStatistics = () =>
  useFetch(['/v1/trackers/assignments/submitted-tasks-statistics/']);

export const useGoogleAuthToken = () =>
  useFetch(['/v1/integrations/google/auth-token/']);

export const useGoogleLastSync = () =>
  useFetch(['/v1/integrations/google/classroom/last-sync/']);

export const useCleverLastSync = () => useFetch(['/v1/clever/last-sync/']);

export const googleManualSync = async () =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/integrations/google/classroom/sync-manually/`)
  );

export const useUserSectionsNames = () =>
  useFetch(['/v1/sections/names-list-for-user/']);

export const useSection = ({ sectionId }) =>
  useFetch([`/v1/sections/${sectionId}/retrieve/`]);

export const useStudentGroups = ({ params }) =>
  useFetch(['/v1/sections/student-groups/', params], params);

export const useSectionFeed = ({ sectionId, params }) =>
  useFetchInfinite((pageIndex) => [
    `/v1/sections/${sectionId}/feed/?` +
      stringifyParams({ ...params, limit: 10, offset: pageIndex * 10 })
  ]);

export const addCommentToStudent = ({
  trackerInstanceSectionId,
  studentId,
  data
}) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/instances/sections/${trackerInstanceSectionId}/students/${studentId}/add-comment/`,
      data
    )
  );

export const taskStatusUpdate = ({ trackerScoreResponseId, data }) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/assignments/task/${trackerScoreResponseId}/update-status/`,
      data
    )
  );

export const sectionUpdate = ({ sectionId, data }) =>
  requestSdk(() => post(`${BASE_URL}/v1/sections/${sectionId}/update/`, data));

export const studentGroupCreate = ({ data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/sections/student-groups/create/`, data)
  );

export const useSchoolUsers = ({ params }) =>
  useFetch(
    params ? ['/v1/schools/schools-school-users/', params] : undefined,
    params
  );

export const createBulkUpload = async ({ data }) =>
  await requestSdk(() => post(`${BASE_URL}/v1/bulk-uploads/create/`, data));

export const useBulkUploadDetail = ({ bulkUploadId }) => {
  const random = useRef(Date.now());

  return useFetch(
    bulkUploadId
      ? [`/v1/bulk-uploads/${bulkUploadId}/retrieve/`, random]
      : undefined
  );
};

export const bulkUploadAssignmentAssign = ({ bulkUploadId, data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/assignment-assign/`, data)
  );

export const useBulkUploadFirstAssignmentItems = ({ bulkUploadId }) =>
  useFetch([
    `/v1/bulk-uploads/${bulkUploadId}/first-assignment-items-retrieve/`
  ]);

export const bulkUploadPagesConfirm = ({ bulkUploadId, rotationCount }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/pages-confirm/`, {
      rotation_count: rotationCount
    })
  );

export const selectBulkUploadNameRegion = ({ bulkUploadId, data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/region-select/`, data)
  );

export const useBulkUploadGroups = ({ bulkUploadId, params }) =>
  useFetch([`/v1/bulk-uploads/${bulkUploadId}/groups/list/`, params], params);

export const useBulkUploadStudents = ({
  unmatchedOnly = false,
  bulkUploadId
}) =>
  useFetch([`/v1/bulk-uploads/${bulkUploadId}/students/list/`, unmatchedOnly], {
    unmatched_only: unmatchedOnly
  });

export const excludeBulkUploadImage = ({ bulkUploadId, bulkUploadImageId }) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/images/${bulkUploadImageId}/exclude/`
    )
  );

export const bulkUploadImageUpdate = ({
  bulkUploadId,
  bulkUploadImageId,
  data
}) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/images/${bulkUploadImageId}/update/`,
      data
    )
  );

export const useAssignmenTasks = ({ assignmentId, data }) =>
  useFetch(
    assignmentId
      ? [`/v2/trackers/assignments/${assignmentId}/task/list/`, data]
      : undefined,
    data
  );

export const useBulkUploadSubmissionsCount = ({ bulkUploadId }) =>
  useFetch([`/v1/bulk-uploads/${bulkUploadId}/submissions-count/`]);

export const finishBulkUpload = ({ bulkUploadId }) =>
  requestSdk(() => post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/finish/`));

export const uploadAssignmentFeedback = ({ data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v2/trackers/assignments/task/upload-feedback/`, data)
  );

export const useAssignmentStudentWork = ({ assignmentId }) =>
  useFetch(
    assignmentId
      ? [`/v2/trackers/assignments/${assignmentId}/student-work/`]
      : undefined
  );

export const useUserTodayMeetings = () =>
  useFetch(['/v1/meetings/count-for-today/']);

export const bulkUploadSkipNameMatching = ({ bulkUploadId }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/bulk-uploads/${bulkUploadId}/skip-name-matching/`)
  );

export const addMeetingAgendas = ({ meetingId, data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/${meetingId}/add-agenda/`, data)
  );

export const meetingSharesCreate = ({ meetingId, data }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/${meetingId}/shares/create/`, data)
  );

export const useMeetingInfo = ({
  invitationIdentifier,
  params,
  errorHandlers
}) =>
  useFetch(
    invitationIdentifier
      ? [`/v1/meetings/${invitationIdentifier}/`]
      : undefined,
    params,
    errorHandlers
  );

export const useTeamMeetings = ({ teamId }) =>
  useFetch(teamId ? [`/v1/meetings/teams/${teamId}/meetings/`] : undefined);

export const updateMeetingSharingEnabled = ({ meetingId, data }) =>
  requestSdk(() =>
    post(
      `${BASE_URL}/v1/meetings/${meetingId}/update-is-sharing-enabled/`,
      data
    )
  );

export const useMeetingInvitationDetail = ({ invitationIdentifier }) =>
  useFetch(
    invitationIdentifier
      ? [`/v1/meetings/${invitationIdentifier}/meeting-invitation-details/`]
      : undefined
  );

export const useMeetingInvitationSchools = ({ invitationIdentifier }) =>
  useFetch(
    invitationIdentifier
      ? [`/v1/meetings/${invitationIdentifier}/meeting-schools/`]
      : undefined
  );

export const acceptMeetingInvitation = ({ invitationIdentifier }) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/${invitationIdentifier}/accept-invitation/`)
  );

export const completeProfile = async ({ data }) =>
  requestSdk(() => post(`${BASE_URL}/v1/users/complete-profile/`, data));

export const useTeamUpcomingMeetings = ({ teamId, params }) =>
  useFetch(
    teamId
      ? [`/v1/meetings/teams/${teamId}/meetings/list/upcoming/`, params]
      : undefined,
    params
  );

export const useTeamPastMeetings = ({ teamId, params }) =>
  useFetch(
    teamId
      ? [`/v1/meetings/teams/${teamId}/meetings/list/past/`, params]
      : undefined,
    params
  );

export const useTeamMeetingsPerUser = ({ teamId, params }) =>
  useFetch(
    teamId
      ? [`/v1/meetings/teams/${teamId}/meetings/list/`, params]
      : undefined,
    params
  );

export const useTeam = ({ teamId }) =>
  useFetch(teamId ? [`/v1/meetings/teams/${teamId}/`] : undefined);

export const createProfile = ({ data }) =>
  requestSdk(() => post(`${BASE_URL}/v1/walkthrough/create-profile/`, data));
