import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';

import App from 'config';

import './index.css';
import { initMixpanel } from './mixpanel';
import { initSentry } from './sentry';

initSentry();
initMixpanel();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
