import { useEffect, useState } from 'react';

import _ from 'lodash';

import { notifyError, notifySuccess } from 'utils/notifications';

export const dataTabular = (data) => {
  var str = '';
  for (let el of data) {
    for (let key of Object.keys(el)) {
      str = str.concat(el[key]);
      str = str.concat('\t');
    }
    str = str.concat('\n');
  }
  return str;
};

export const copyToClipboard = (copyText, msg = 'Copied to clipboard.') => {
  navigator.clipboard.writeText(copyText).then(
    () => {
      notifySuccess(msg);
    },
    (error) =>
      notifyError(
        `Failed to copy ${copyText} to the clipboard. The error was: ${error}`
      )
  );
};

export function humanReadableFileSize(bytes, round) {
  const bytesPow = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['B', 'kB', 'MB', 'GB']; // We don't need more than that

  const humanReadbleSize = _.round(bytes / Math.pow(1024, bytesPow), round);

  return `${humanReadbleSize} ${sizes[bytesPow]}`;
}

export async function sleep(ms) {
  await new Promise((r) => setTimeout(r, ms));
}

export function noop() {}
export function noopPromise() {
  return new Promise((res) => res());
}

export const randomNumber = () => Math.round(Math.random() * 100000);

export const identity = (x) => x;

export const useCaching = (obj) => {
  const [cachedObj, setCachedObj] = useState(obj);

  useEffect(() => {
    setCachedObj(obj);
  }, [obj]);

  return [cachedObj, setCachedObj];
};

export const getStandardButtonLabel = ({ standards }) => {
  const standardsCount = _.get(standards, 'length', 0);

  if (standardsCount === 0) {
    return 'Standards';
  }

  if (standardsCount === 1) {
    return _.get(_.first(standards), 'code', 'short_description');
  }

  return `${standardsCount} Standards`;
};

export const getItemFromLocalStorage = ({ item }) =>
  JSON.parse(localStorage.getItem(item));

export const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
