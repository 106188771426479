import React from 'react';

import cx from 'classnames';

import styles from './styles.module.css';

const Flex = ({
  className,
  children,
  spaceBetween = false,
  flexStart = false,
  flexEnd = false,
  wrapped = false,
  column = false,
  alignCenter = true,
  onClick,
  ...rest
}) => (
  <div
    onClick={onClick}
    className={cx(
      styles.flex,
      { [styles.spaceBetween]: spaceBetween },
      { [styles.flexStart]: flexStart },
      { [styles.flexEnd]: flexEnd },
      { [styles.wrapped]: wrapped },
      { [styles.column]: column },
      { [styles.alignCenter]: alignCenter },
      className
    )}
    {...rest}
  >
    {children}
  </div>
);

export default Flex;
