import React, { useCallback, useEffect, useRef, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

import AddButton from 'components/AddButton';
import Button from 'components/Button';
import InputField from 'components/InputField';

import styles from './styles.module.css';

const AddColumnButton = ({ addNewColumn }) => {
  const [isAddingColumn, setIsAddingColumn] = useState(false);
  const [newColumnTitle, setNewColumnTitle] = useState('');

  const inputRef = useRef(null);

  const startAddingColumn = useCallback(() => setIsAddingColumn(true), []);
  const stopAddingColumn = useCallback(() => setIsAddingColumn(false), []);

  const handleChange = useCallback(
    (event) => setNewColumnTitle(event.target.value),
    []
  );
  const handleSave = useCallback(() => {
    addNewColumn(newColumnTitle);
    stopAddingColumn();
    setNewColumnTitle('');
  }, [addNewColumn, newColumnTitle, stopAddingColumn]);

  const handleClose = useCallback(() => {
    stopAddingColumn();
    setNewColumnTitle('');
  }, [stopAddingColumn]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        // Enter
        handleSave();
      }
    },
    [handleSave]
  );

  const handleClickOutsideInputField = useCallback(
    (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        handleClose();
      }
    },
    [inputRef, handleClose]
  );

  useEffect(() => {
    if (isAddingColumn) {
      window.addEventListener('mousedown', handleClickOutsideInputField);

      return () =>
        window.removeEventListener('mousedown', handleClickOutsideInputField);
    }
  }, [isAddingColumn, handleClickOutsideInputField]);

  return (
    <div className={styles.addButton}>
      {isAddingColumn ? (
        <div ref={inputRef}>
          <InputField
            autoFocus
            type="text"
            variant="underlined"
            placeholder="Column title"
            value={newColumnTitle}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <div className={styles.newColumnButtons}>
            <Button
              size="small"
              color="pink"
              disabled={_.isEmpty(newColumnTitle)}
              onClick={handleSave}
            >
              Save
            </Button>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      ) : (
        <AddButton color="blue" onClick={startAddingColumn}>
          Add column
        </AddButton>
      )}
    </div>
  );
};

export default React.memo(AddColumnButton);
