import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { buildFilters } from 'utils/filters';
import { useFetch } from 'utils/sdk';
import { stringifyParams } from 'utils/urls';

import { fetchSessionsAssignedToMe } from './sdk';

export const buildFiltersData = (filters, userId) => {
  if (_.isEmpty(filters)) {
    return {};
  }

  return buildFilters({
    student_group_name: _.get(filters, 'studentGroupName'),
    student: _.get(filters, 'student'),
    assigned_to: _.get(filters, 'tutor'),
    assigned: 'all'
  });
};

export const useSessionsAssignedToMe = (requestData) => {
  const result = useFetch(
    fetchSessionsAssignedToMe,
    {
      data: [],
      loading: true
    },
    requestData
  );

  return result;
};

export const useFilters = (history) => {
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const builtFilters = buildFilters(filters);
    if (!_.isEmpty(builtFilters)) {
      history.replace({
        search: stringifyParams(builtFilters)
      });
    }
  }, [filters, history]);

  return [filters, setFilters];
};

/**
 * The goal of this hook is to make the optimistic re-rendering of the updated
 * student groups possible.
 */
export const useCopySectionResponse = (results) => {
  const [sections, setSectioons] = useState([]);
  useEffect(() => setSectioons(results), [results]);

  const updateSections = useCallback(({ sectionId, data }) => {
    setSectioons((prevGroups) => {
      const studentGroupIndex = _.findIndex(prevGroups, { id: sectionId });

      if (studentGroupIndex !== -1) {
        // prevGroups needs to be copied in order to trigger setState
        const updatedSections = _.cloneDeep(prevGroups);

        updatedSections[studentGroupIndex] = {
          ...updatedSections[studentGroupIndex],
          name: data.name,
          students: data.students,
          users: data.users
        };

        return updatedSections;
      }
      return prevGroups;
    });
  }, []);

  return [sections, updateSections];
};
