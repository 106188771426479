import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import AssignmentStatusIcon from 'components/AssignmentStatusIcon';
import Typography from 'components/Typography';

import styles from './styles.module.css';

const HighlightTableRow = withStyles({
  root: {
    backgroundColor: colors.grey7,
    '&:hover': {
      backgroundColor: colors.grey6
    }
  }
})(TableRow);

const StatusBadge = ({ status }) => {
  const { icon, color } = AssignmentStatusIcon[status];

  return (
    <div
      className={styles.statusBadge}
      style={{
        backgroundColor: color
      }}
    >
      <SvgIcon component={icon} htmlColor={colors.white} />
      <Typography
        variant="S-TEXT-2"
        className={styles.label}
        color={colors.white}
      >
        {status}
      </Typography>
    </div>
  );
};

const StudentsStatusTable = ({ students }) => (
  <Table size="small" className={styles.tableWrapper}>
    <TableHead>
      <TableRow>
        <TableCell
          className={classnames(
            styles.headCell,
            styles.fixedCell,
            styles.studentsCell
          )}
          style={{
            backgroundColor: colors.grey3,
            zIndex: 4
          }}
        >
          <Typography variant="S-TEXT-2" color={colors.white}>
            Student
          </Typography>
        </TableCell>
        {_.map(_.get(students, '[0].tasks'), (task, index) => (
          <TableCell
            key={task.name}
            className={classnames(styles.headCell, styles.fixedCell)}
            style={{
              backgroundColor: colors.grey2,
              zIndex: 3
            }}
          >
            <Typography
              variant="S-TEXT-2"
              color={colors.white}
              noWrap
              className={styles.taskName}
            >
              {index + 1}. {task.name}
            </Typography>
          </TableCell>
        ))}
        <TableCell
          className={classnames(styles.headCell, styles.fixedCell)}
          style={{
            backgroundColor: colors.grey3,
            zIndex: 4
          }}
        >
          <Typography variant="S-TEXT-2" color={colors.white}>
            Overall
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {_.map(students, (student) => (
        <HighlightTableRow key={student.id}>
          <TableCell
            className={classnames(
              styles.bodyCell,
              styles.fixedCell,
              styles.leftBorderCell
            )}
            style={{ zIndex: 2 }}
          >
            <Typography variant="B-Text-3" color={colors.grey1}>
              {student.name}
            </Typography>
          </TableCell>
          {_.map(student.tasks, (task) => (
            <TableCell
              key={`${student.id}.${task.name}`}
              className={styles.bodyCell}
              style={{ zIndex: 2 }}
            >
              <Typography variant="B-Text-3" color={colors.grey2}>
                {_.startCase(task.status)}
              </Typography>
            </TableCell>
          ))}
          <TableCell
            className={classnames(
              styles.bodyCell,
              styles.fixedCell,
              styles.rightBorderCell
            )}
            style={{ zIndex: 2 }}
          >
            <StatusBadge status={student.status} />
          </TableCell>
        </HighlightTableRow>
      ))}
    </TableBody>
  </Table>
);

export default StudentsStatusTable;
