import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';
import { Field, Formik } from 'formik';
import _ from 'lodash';

import { colors } from 'theme/palette';

import InputField from 'components/InputField';
import Typography from 'components/Typography';

import { ReactComponent as addCommentIcon } from './assets/add_comment.svg';
import styles from './styles.module.css';

const initialValues = { text: '' };

const Comments = ({ comments, addComment }) => {
  return (
    <div className={styles.commentSection}>
      {!_.isEmpty(comments) && (
        <Grid container direction="column" className={styles.comments}>
          {_.map(comments, (comment) => (
            <Grid
              item
              container
              key={comment.id}
              className={classnames(styles.comment, {
                [styles.isStudent]: comment.user.is_student
              })}
            >
              <Typography
                variant="B-Text-2"
                color={colors.blue1}
                className={styles.author}
              >
                {comment.user.name}
              </Typography>
              <Typography variant="B-Text-2" color={colors.grey1}>
                {comment.text}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}

      <Formik initialValues={initialValues} onSubmit={addComment}>
        {({ isSubmitting, handleSubmit, submitForm, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Field
                component={InputField}
                className={styles.input}
                name="text"
                placeholder="Add a comment..."
              />
              <IconButton
                size="medium"
                disabled={isSubmitting || !dirty}
                onClick={() => !isSubmitting && dirty && submitForm()}
              >
                <SvgIcon
                  fontSize="large"
                  titleAccess="Add comment"
                  component={addCommentIcon}
                  htmlColor={
                    isSubmitting || !dirty ? colors.grey4 : colors.green3
                  }
                />
              </IconButton>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(Comments);
