import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import TableContainer from '@material-ui/core/TableContainer';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import { TEACHERS_ASSIGNMENTS_STUDENTS_STATUS_URL } from 'config/urls';
import _ from 'lodash';

import { ProgressBar, StudentsStatusTable } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { AssignmentStatusOptions } from 'utils/constants';
import { formatDate } from 'utils/moment';
import { notifyErrors } from 'utils/notifications';
import { openInNewTab, reverse } from 'utils/urls';

import AssignmentStatusIcon from 'components/AssignmentStatusIcon';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import {
  assignmentStatus,
  bulkUpdateTasksStatus,
  getStudentsStatusesCsvDownloadUrl,
  studentsStatuses
} from './sdk';
import styles from './styles.module.css';

const OverallStatus = ({ total, value, status, smScreen }) => {
  const { icon, color } = AssignmentStatusIcon[status];

  return (
    <Grid item xs className={styles.statusBox}>
      <SvgIcon
        component={icon}
        htmlColor={color}
        className={styles.statusIcon}
      />

      <div className={styles.statusText}>
        <Typography variant="S-TEXT-2" color={colors.greyDark}>
          {value} / {total} {status}
        </Typography>
        <ProgressBar
          size="small"
          width="90%"
          value={value}
          total={total}
          color={color}
          className={styles.statusProgress}
        />
      </div>
    </Grid>
  );
};

const OverallStatusSkeleton = () => (
  <Grid item xs className={styles.statusBox}>
    <Skeleton variant="circle" width={30} height={30} />

    <div className={styles.statusText}>
      <Skeleton variant="text" width="50%" height={15} />
      <Skeleton variant="text" width="90%" height={15} />
    </div>
  </Grid>
);

const TableSkeleton = () => (
  <div>
    {_.times(8, (index) => (
      <Skeleton key={index} variant="text" height={30} />
    ))}
  </div>
);

class AssignmentStatusDialog extends React.Component {
  state = {
    assignmentStatusData: null,
    studentsStatusData: null,
    loading: {
      assignments: true,
      students: true
    },
    isMarkingSubmissions: false
  };

  async componentDidMount() {
    // The calls are not parallel on purpose! We need to fetch the status data first in order to know the height of the statuses container
    await this.fetchAssignmentStatusData();
    await this.fetchStudentsStatusData();
  }

  fetchAssignmentStatusData = async () => {
    const { sectionId } = this.props;

    const { success, data, errors } = await assignmentStatus(sectionId);

    if (success) {
      this.setState({
        assignmentStatusData: data,
        loading: { ...this.state.loading, assignments: false }
      });
    } else {
      this.setState(
        { loading: { ...this.state.loading, assignments: false } },
        () => notifyErrors(errors)
      );
    }
  };

  fetchStudentsStatusData = async () => {
    const { sectionId } = this.props;

    const { success, data, errors } = await studentsStatuses(sectionId);

    if (success) {
      this.setState({
        studentsStatusData: data,
        loading: { ...this.state.loading, students: false }
      });
    } else {
      this.setState(
        { loading: { ...this.state.loading, students: false } },
        () => notifyErrors(errors)
      );
    }
  };

  openStudentsTableInNewTab = () => {
    const { sectionId } = this.props;
    openInNewTab(
      reverse(TEACHERS_ASSIGNMENTS_STUDENTS_STATUS_URL, { sectionId })
    );
  };

  exportStudentsStatusDataAsCsv = () => {
    const { sectionId } = this.props;

    const url = getStudentsStatusesCsvDownloadUrl(sectionId);
    openInNewTab(url);
  };

  markAllSubmissionsAsComplete = async () => {
    this.setState({ isMarkingSubmissions: true });

    const { studentsStatusData } = this.state;

    const submittedTaskIds = _(studentsStatusData)
      .map('tasks')
      .flatten()
      .filter({ status: AssignmentStatusOptions.SUBMITTED })
      .map('id')
      .value();

    const { sectionId, onSubmissionsMarkComplete } = this.props;

    const { success, errors } = await bulkUpdateTasksStatus(sectionId, {
      tracker_score_responses: submittedTaskIds,
      status: AssignmentStatusOptions.COMPLETE
    });

    this.setState({ isMarkingSubmissions: false });

    if (success) {
      onSubmissionsMarkComplete(submittedTaskIds);
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const {
      assignmentStatusData,
      studentsStatusData,
      loading,
      isMarkingSubmissions
    } = this.state;

    const { onClose, assignment } = this.props;

    const allStudentsAreComplete = _.every(
      studentsStatusData,
      (s) => s.status === AssignmentStatusOptions.COMPLETE
    );

    return (
      <Dialog open onClose={onClose} fullWidth maxWidth="lg">
        <div className={styles.dialogTitleWrapper}>
          <div className={styles.dialogTitle}>
            <Typography variant="H-TEXT-2" color={colors.blueDarker}>
              Status
            </Typography>
            <Typography variant="B-Text-2" color={colors.greyDark}>
              Due: {formatDate(assignment.due_at, 'ddd MM/DD hh:mm A')}
            </Typography>
          </div>
          <Button
            color="green"
            onClick={this.markAllSubmissionsAsComplete}
            disabled={isMarkingSubmissions || allStudentsAreComplete}
          >
            Change All Submitted To Complete
          </Button>
        </div>

        <div className={styles.overallWrapper}>
          <Typography
            variant="H-TEXT-2"
            color={colors.blueDarker}
            className={styles.overallTitle}
          >
            Overall
          </Typography>

          <Grid container alignItems="center" justify="space-between">
            {loading.assignments &&
              _.times(4, (index) => <OverallStatusSkeleton key={index} />)}
            {!loading.assignments &&
              _.map(
                _.get(assignmentStatusData, 'overall_count'),
                (value, status) => (
                  <OverallStatus
                    key={status}
                    total={_.get(assignmentStatusData, 'total_count')}
                    value={value}
                    status={status}
                  />
                )
              )}
          </Grid>
        </div>

        <div className={styles.tableWrapper}>
          <div className={styles.tableTitleWrapper}>
            <Typography variant="H-TEXT-2" color={colors.blueDarker}>
              Students
            </Typography>
            <div>
              <IconButton onClick={this.exportStudentsStatusDataAsCsv}>
                <GetAppRoundedIcon fontSize="large" />
              </IconButton>
              <IconButton onClick={this.openStudentsTableInNewTab}>
                <LaunchRoundedIcon fontSize="large" />
              </IconButton>
            </div>
          </div>

          <div>
            {loading.students && <TableSkeleton />}
            {!loading.students && (
              <TableContainer
                component={Paper}
                className={styles.tableContainer}
              >
                <StudentsStatusTable students={studentsStatusData} />
              </TableContainer>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AssignmentStatusDialog;
