import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import {
  TEACHERS_ACTIVITY_STREAM_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL,
  TEACHERS_CAPTURE_URL,
  TEACHERS_MEETINGS_LIST_URL,
  TEACHERS_STUDENTS_LIST,
  TEACHERS_STUDENT_GROUP_LIST_URL
} from 'config/urls';
import { ReactComponent as ResultsIcon } from 'images/results.svg';

import { ReactComponent as ActivityIcon } from './assets/activity.svg';
import { ReactComponent as AssignmentsIcon } from './assets/assignments.svg';
import { ReactComponent as CreateIcon } from './assets/create-plus.svg';
import { ReactComponent as MeetingsIcon } from './assets/meetings.svg';
import { ReactComponent as ReviewIcon } from './assets/review.svg';
import { ReactComponent as TutoringIcon } from './assets/tutoring.svg';

export const ITEMS = [
  {
    key: 'create',
    text: 'Create Assignment',
    shortText: 'Create',
    url: TEACHERS_CAPTURE_URL,
    Icon: (props) => <CreateIcon {...props} />
  },
  {
    key: 'capture',
    text: 'Capture',
    url: TEACHERS_CAPTURE_URL,
    Icon: (props) => <CameraAltIcon {...props} />
  },
  {
    key: 'review',
    text: 'Review',
    url: '',
    Icon: (props) => <ReviewIcon {...props} />
  },
  {
    key: 'assignments',
    text: 'Assignments',
    url: TEACHERS_ASSIGNMENTS_LIST_URL,
    Icon: (props) => <SvgIcon {...props} component={AssignmentsIcon} />
  },
  {
    key: 'students',
    text: 'Students',
    url: TEACHERS_STUDENTS_LIST,
    Icon: (props) => <SvgIcon {...props} component={ResultsIcon} />
  },
  {
    key: 'classes',
    text: 'Classes',
    Icon: (props) => <SvgIcon {...props} component={TutoringIcon} />,
    url: TEACHERS_STUDENT_GROUP_LIST_URL
  },
  {
    key: 'meetings',
    text: 'Meetings',
    Icon: (props) => <SvgIcon {...props} component={MeetingsIcon} />,
    url: TEACHERS_MEETINGS_LIST_URL
  },
  {
    key: 'activity',
    text: 'Activity',
    Icon: (props) => <SvgIcon {...props} component={ActivityIcon} />,
    url: TEACHERS_ACTIVITY_STREAM_URL
  }
];
