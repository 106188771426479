import React from 'react';

import { Typography } from 'pages/Students/shared';
import { colors } from 'theme/palette';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import ForwardButton from 'components/ForwardButton';

import IncompleteImg from './assets/assigned.png';
import styles from './styles.module.css';

export const NotDoneDialog = ({
  toggleNotDoneDialog,
  goToAssignmentsList,
  goToNextPendingTask,
  getTasksCount
}) => {
  const incompleteTasksNumber = getTasksCount();
  return (
    <Dialog
      open
      showClose={false}
      onClose={toggleNotDoneDialog}
      classes={{ paper: styles.dialogPaper }}
    >
      <Typography variant="H-TEXT-1" color={colors.pink2} align="center">
        Are you sure?
      </Typography>
      <img
        className={styles.incompleteImg}
        src={IncompleteImg}
        alt="Incomplete tasks"
      />
      <Typography
        variant="H-TEXT-3"
        color={colors.blue4}
        align="center"
        className={styles.submittedHeader}
      >
        There {incompleteTasksNumber === 1 ? 'is' : 'are'} still{' '}
        {incompleteTasksNumber} incomplete{' '}
        {incompleteTasksNumber === 1 ? 'task' : 'tasks'}
      </Typography>
      <ForwardButton
        fullWidth
        noTypography
        color="pink"
        variant="textAndIcon"
        onClick={() => goToNextPendingTask().then(toggleNotDoneDialog)}
        className={styles.finishButton}
      >
        <Typography variant="S-TEXT-1" align="center" color={colors.white}>
          Finish tasks
        </Typography>
      </ForwardButton>
      <Button
        fullWidth
        color="blue"
        variant="medium"
        onClick={goToAssignmentsList}
      >
        Done
      </Button>
    </Dialog>
  );
};
