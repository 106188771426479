import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Pagination from '@material-ui/lab/Pagination';
import classnames from 'classnames';
import _ from 'lodash';

import { withNavbar } from 'pages/Teachers/shared';
import {
  ActivityStreamTimespanOptions,
  ActivityStreamVerbs
} from 'utils/constants';
import { buildFilters } from 'utils/filters';
import { notifyErrors } from 'utils/notifications';

import UserContext from 'components/UserContext';

import { ActivityFilters, ActivityList } from './components';
import { recentActivity } from './sdk';
import styles from './styles.module.css';

class ActivityStream extends React.Component {
  static contextType = UserContext;

  DEFAULT_LIMIT = 10;

  state = {
    activityStream: null,
    loading: true,
    filters: {
      verb: ActivityStreamVerbs.ALL,
      title: '',
      actor: '',
      section: '',
      timespan: ActivityStreamTimespanOptions.WEEK.value
    },
    pagination: {
      numPages: 1,
      currentPage: 1,
      limit: this.DEFAULT_LIMIT
    }
  };

  componentDidMount() {
    this.fetchActivityStream();
  }

  getFilters = () => {
    const { filters } = this.state;

    if (filters.verb === ActivityStreamVerbs.ALL) {
      return { ...filters, verb: '' };
    }

    return filters;
  };

  fetchActivityStream = async () => {
    const { currentPage } = this.state.pagination;
    const paginationParams = {
      offset: this.state.pagination.limit * (currentPage - 1)
    };
    const filters = this.getFilters();

    const {
      success,
      data: { limit, offset, count, results },
      errors
    } = await recentActivity({
      ...buildFilters(filters),
      ...paginationParams
    });

    if (success) {
      const numPages = _.floor(count / limit);
      this.setState({
        activityStream: results,
        loading: false,
        pagination: {
          ...this.state.pagination,
          numPages: numPages,
          limit: limit,
          offset: offset
        }
      });
    } else {
      notifyErrors(errors);
      return;
    }
  };

  filterVerb = (value) => {
    this.setState(
      {
        filters: { ...this.state.filters, verb: value }
      },
      this.changePage(1)
    );
  };

  filterTimespan = (value) => {
    this.setState(
      {
        filters: { ...this.state.filters, timespan: value }
      },
      this.changePage(1)
    );
  };

  filterActor = (value) => {
    this.setState(
      {
        filters: { ...this.state.filters, actor: value }
      },
      this.changePage(1)
    );
  };

  filterTitle = (value) => {
    this.setState(
      {
        filters: { ...this.state.filters, title: value }
      },
      this.changePage(1)
    );
  };

  filterClass = (value) => {
    this.setState(
      {
        filters: { ...this.state.filters, section: value }
      },
      this.changePage(1)
    );
  };

  changePage = (page) => {
    this.setState(
      (prevState) => ({
        pagination: {
          ...prevState.pagination,
          currentPage: page
        },
        loading: true
      }),
      this.fetchActivityStream
    );
  };

  render() {
    const {
      loading,
      activityStream,
      filters,
      pagination: { numPages, currentPage }
    } = this.state;

    const { user } = this.context;

    const lgScreen = isWidthUp('lg', this.props.width);

    return (
      <div
        className={classnames({
          [styles.activityPageWrapper]: lgScreen,
          [styles.paddedRight]: lgScreen
        })}
      >
        <ActivityFilters
          user={user}
          filters={filters}
          filterActor={this.filterActor}
          filterTitle={this.filterTitle}
          filterClass={this.filterClass}
          filterVerb={this.filterVerb}
          filterTimespan={this.filterTimespan}
          lgScreen={lgScreen}
          className={classnames({
            [styles.activityPageWrapper]: !lgScreen
          })}
        />
        <Paper
          variant="outlined"
          className={classnames({ [styles.tabletActivityTable]: !lgScreen })}
        >
          <Grid container>
            <ActivityList
              stream={activityStream}
              loading={loading}
              lgScreen={lgScreen}
            />
            {!loading && numPages > 1 && (
              <Grid item xs={12}>
                <Pagination
                  className={styles.pagination}
                  count={numPages}
                  page={currentPage}
                  onChange={(_, page) => this.changePage(page)}
                  color="secondary"
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withWidth()(withNavbar(ActivityStream, { title: 'Activity' }));
