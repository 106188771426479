import React, { useCallback, useMemo, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';
import { TEACHERS_ASSIGNMENTS_FEEDBACK_URL } from 'config/urls';
import _ from 'lodash';

import {
  default as StudentWorkSelectInput,
  getLabelForWork
} from 'pages/Teachers/shared/StudentWorkSelectInput';
import { colors } from 'theme/palette';
import { openInNewTab, reverse, stringifyParams } from 'utils/urls';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import StudentWorkTasksButtons from 'components/StudentWorkTasksButtons';
import Typography from 'components/Typography';

import { ReactComponent as ArrowWithBorder } from './assets/arrow-with-border.svg';
import styles from './styles.module.css';

const StudentWorkDialog = ({
  onClose,
  initialTaskIndex,
  initialWorkIndex,
  studentWork,
  assignmentId,
  trackerId
}) => {
  const [taskIndex, setTaskIndex] = useState(initialTaskIndex);
  const [workIndex, setWorkIndex] = useState(initialWorkIndex);

  const activeTask = _.get(studentWork.tasks, taskIndex, {});
  const activeWork = _.get(activeTask.work, workIndex, { emptyWork: true });

  const studentName = studentWork.student.is_teacher_demo_student
    ? `Exemplar - ${studentWork.student.preferred_name}`
    : studentWork.student.name;

  const tasksButtonsElements = useMemo(
    () =>
      studentWork.tasks.map((task, index) => ({
        label: index + 1,
        key: task.id,
        value: index,
        isActive: index === taskIndex
      })),
    [studentWork.tasks, taskIndex]
  );

  const taskWorks = useMemo(
    () =>
      _.map(activeTask.work, (work, index) => ({
        key: work.id,
        label: getLabelForWork(work),
        value: index
      })),
    [activeTask.work]
  );

  const onNextClick = useCallback(() => {
    setTaskIndex((taskIndex) => (taskIndex + 1) % studentWork.tasks.length);
    setWorkIndex(0);
  }, [studentWork.tasks.length]);
  const onPreviousClick = useCallback(() => {
    setTaskIndex(
      (taskIndex) =>
        (studentWork.tasks.length + (taskIndex - 1)) % studentWork.tasks.length
    );
    setWorkIndex(0);
  }, [studentWork.tasks.length]);

  const redirectToFeedback = useCallback(() => {
    const pageUrl = reverse(TEACHERS_ASSIGNMENTS_FEEDBACK_URL, { trackerId });
    const params = stringifyParams({
      section: assignmentId,
      student: studentWork.student.id,
      task: activeTask.id,
      work: activeWork.id
    });

    openInNewTab(`${pageUrl}?${params}`);
  }, [
    trackerId,
    assignmentId,
    studentWork.student.id,
    activeTask.id,
    activeWork.id
  ]);

  return (
    <Dialog open onClose={onClose} classes={{ paper: styles.paper }}>
      <img
        src={activeWork.s3_work_url}
        className={styles.image}
        alt={activeWork.original_filename}
      />
      {studentWork.tasks.length > 1 && (
        <IconButton
          className={classnames(styles.left, styles.arrow)}
          onClick={onPreviousClick}
        >
          <SvgIcon component={ArrowWithBorder} />
        </IconButton>
      )}
      {studentWork.tasks.length > 1 && (
        <IconButton
          className={classnames(styles.right, styles.arrow)}
          onClick={onNextClick}
        >
          <SvgIcon component={ArrowWithBorder} />
        </IconButton>
      )}
      <div className={styles.footer}>
        <Typography
          variant="H-TEXT-3"
          color={colors.blue1}
          className={styles.studentName}
        >
          {studentName}
        </Typography>
        <Button fullWidth color="pink" onClick={redirectToFeedback}>
          Feedback
        </Button>
        <StudentWorkTasksButtons
          classes={{ root: styles.tasksButtons }}
          elements={tasksButtonsElements}
          onChange={(element) => {
            setTaskIndex(element.value);
            setWorkIndex(0);
          }}
        />
        <StudentWorkSelectInput
          value={workIndex}
          onChange={(value) => {
            setWorkIndex(value);
          }}
          options={taskWorks}
          disabled={_.get(activeWork, 'emptyWork', false)}
        />
      </div>
    </Dialog>
  );
};

export default StudentWorkDialog;
