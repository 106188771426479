import React from 'react';

import StudentGroupCard from 'pages/Teachers/StudentGroupList/components/StudentGroupCard';

import styles from './styles.module.scss';

const SectionsList = ({ sections, onStudentGroupUpdate }) => (
  <div className={styles.container}>
    {sections?.map((section) => (
      <StudentGroupCard
        group={section}
        key={section.id}
        onStudentGroupUpdate={onStudentGroupUpdate}
      />
    ))}
  </div>
);

export default SectionsList;
