import React, { useCallback, useMemo, useState } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import _ from 'lodash';
import { sectionUpdate, studentGroupCreate } from 'sdk';

import { colors } from 'theme/palette';
import { MuiAutocompleteChangeReasons } from 'utils/constants';
import { notifyError, notifySuccess } from 'utils/notifications';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import SearchFilter from 'components/SearchFilter';
import SelectFilter from 'components/SelectFilter';
import Typography from 'components/Typography';

import styles from './styles.module.css';
import {
  useDefaultGroupName,
  useSchoolStudents,
  useSchoolUsers,
  useSchools
} from './utils';

const getOptionLabel = (option) => _.get(option, 'name', '');

const CreateStudentGroupDialog = ({
  onClose,
  onStudentGroupCreate,
  onStudentGroupUpdate,
  initialStudents,
  initialUsers,
  initialStudentGroup = null,
  initialSchool = null
}) => {
  const memoizedSelectedStudents = useMemo(
    () => _.map(initialStudents, 'id'),
    [initialStudents]
  );

  const initialStudentGroupName = _.get(initialStudentGroup, 'name', '');
  const [studentGroupName, setStudentGroupName] = useDefaultGroupName(
    initialStudentGroupName,
    memoizedSelectedStudents
  );

  const [submitting, setSubmitting] = useState(false);

  const { selectedSchool, setSelectedSchool, schools } =
    useSchools(initialSchool);

  const onSchoolChange = useCallback(
    (event) => {
      setSelectedSchool(_.find(schools, { id: event.target.value }));
    },
    [schools, setSelectedSchool]
  );

  const showSelects = !_.isNull(selectedSchool);
  const schoolId = _.get(selectedSchool, 'id');
  const schoolsLength = _.get(schools, 'length', 0);

  const {
    students,
    addStudent,
    setStudentName,
    selectedStudents,
    removeStudent,
    studentName
  } = useSchoolStudents(schoolId, initialStudents);

  const { users, addUser, removeUser, setUserName, userName, selectedUsers } =
    useSchoolUsers(schoolId, initialUsers);

  const disableSubmit =
    _.isEmpty(selectedSchool) ||
    _.isEmpty(selectedStudents) ||
    _.isEmpty(selectedUsers) ||
    submitting;

  const onCreateClick = useCallback(async () => {
    setSubmitting(true);

    const { data, success, errors } = await studentGroupCreate({
      data: {
        name: studentGroupName,
        school: selectedSchool?.id,
        users: _.map(selectedUsers, 'id'),
        students: _.map(selectedStudents, 'id')
      }
    });

    setSubmitting(false);

    if (success) {
      onClose();
      onStudentGroupCreate({ studentGroup: data });
    } else {
      notifyError(errors);
    }
  }, [
    onClose,
    onStudentGroupCreate,
    selectedSchool?.id,
    selectedStudents,
    selectedUsers,
    studentGroupName
  ]);

  const onUpdateClick = useCallback(async () => {
    setSubmitting(true);

    const data = {
      name: studentGroupName,
      users: _.map(selectedUsers, 'id'),
      students: _.map(selectedStudents, 'id')
    };

    const { success, errors } = await sectionUpdate({
      sectionId: initialStudentGroup?.id,
      data
    });

    setSubmitting(false);

    if (success) {
      notifySuccess(`Successfully updated the student group!`);
      onStudentGroupUpdate({
        sectionId: initialStudentGroup.id,
        data: {
          name: studentGroupName,
          students: selectedStudents,
          users: selectedUsers
        }
      });
      onClose();
    } else {
      notifyError(errors);
    }
  }, [
    initialStudentGroup?.id,
    onClose,
    onStudentGroupUpdate,
    selectedStudents,
    selectedUsers,
    studentGroupName
  ]);

  const isUpdateMode = !_.isNull(initialStudentGroup);
  const mode = isUpdateMode ? 'Update' : 'Create';

  const filterStudentOptions = useCallback(
    (options) =>
      _.reject(options, ({ id }) =>
        _.includes(_.map(selectedStudents, 'id'), id)
      ),
    [selectedStudents]
  );
  const onStudentInputChange = useCallback(
    (e, val, trigger) => {
      if (trigger === 'input') {
        setStudentName(val);
      }
    },
    [setStudentName]
  );
  const onStudentChange = useCallback(
    (e, opt, trigger) => {
      if (trigger === MuiAutocompleteChangeReasons.SELECT_OPTION) {
        addStudent(opt);
      }
    },
    [addStudent]
  );
  const filterUserOptions = useCallback(
    (options) =>
      _.reject(options, ({ id }) => _.includes(_.map(selectedUsers, 'id'), id)),
    [selectedUsers]
  );
  const onUserInputChange = useCallback(
    (e, val, trigger) => {
      if (trigger === 'input') {
        setUserName(val);
      }
    },
    [setUserName]
  );
  const onUserChange = useCallback(
    (e, opt, trigger) => {
      if (trigger === MuiAutocompleteChangeReasons.SELECT_OPTION) {
        addUser(opt);
      }
    },
    [addUser]
  );

  return (
    <Dialog
      open
      alignTop
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.dialogHeader}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          {mode} student group
        </Typography>

        <div>
          <Button
            color="lightGrey"
            onClick={onClose}
            className={styles.dialogButton}
            startIcon={<DeleteRoundedIcon />}
          >
            Cancel
          </Button>
          <Button
            color="pink"
            disabled={disableSubmit}
            onClick={isUpdateMode ? onUpdateClick : onCreateClick}
            className={styles.dialogButton}
          >
            {mode}
          </Button>
        </div>
      </div>
      <div className={styles.dialogContent}>
        {schoolsLength > 1 && (
          <SelectFilter
            fullWidth
            options={_.map(schools, (school) => ({
              key: school.id,
              value: school.id,
              label: school.name
            }))}
            value={schoolId}
            onChange={onSchoolChange}
            placeholder="Select School"
            className={styles.schoolsSelect}
          />
        )}
        <InputField
          fullWidth
          variant="underlined"
          placeholder="Student Group Name"
          value={studentGroupName}
          onChange={(e) => setStudentGroupName(e.target.value)}
        />
        {showSelects && (
          <>
            <Typography
              variant="S-TEXT-1"
              color={colors.grey3}
              className={styles.label}
            >
              Students
            </Typography>
            <SearchFilter
              inputValue={studentName}
              placeholder="Add Student"
              options={students}
              filterOptions={filterStudentOptions}
              getOptionLabel={getOptionLabel}
              onInputChange={onStudentInputChange}
              onChange={onStudentChange}
            />
            <SelectedStudents
              students={selectedStudents}
              removeStudent={removeStudent}
            />
            <Typography
              variant="S-TEXT-1"
              color={colors.grey3}
              className={styles.label}
            >
              Staff
            </Typography>
            <SearchFilter
              inputValue={userName}
              placeholder="Add Staff"
              options={users}
              filterOptions={filterUserOptions}
              getOptionLabel={getOptionLabel}
              onInputChange={onUserInputChange}
              onChange={onUserChange}
            />
            <SelectedUsers users={selectedUsers} removeUser={removeUser} />
          </>
        )}
        {submitting && <LinearProgress className={styles.progress} />}
      </div>
    </Dialog>
  );
};

const SelectedStudents = ({ students, removeStudent }) => (
  <div className={styles.selectedStudents}>
    {_.map(students, (student) => (
      <div key={student.id} className={styles.selectedStudentRow}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {student.name}
        </Typography>
        <div className={styles.selectedStudentExtraInfo}>
          <Typography
            className={styles.selectedStudentId}
            variant="S-TEXT-1"
            color={colors.grey3}
          >
            {student.local_student_id}
          </Typography>
          <Typography variant="S-TEXT-1" color={colors.grey3}>
            {student.grade_ordinal}
          </Typography>
          <Button
            color="blue"
            variant="small"
            noTypography
            className={styles.minusButton}
            onClick={() => removeStudent(student)}
          >
            <Typography variant="H-TEXT-1" color={colors.white}>
              -
            </Typography>
          </Button>
        </div>
      </div>
    ))}
  </div>
);

const SelectedUsers = ({ users, removeUser }) => (
  <div className={styles.selectedUsers}>
    {_.map(users, (user) => (
      <div key={user.id} className={styles.selectedUserRow}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {user.name}
        </Typography>
        <Button
          color="blue"
          variant="small"
          noTypography
          className={styles.minusButton}
          onClick={() => removeUser(user)}
        >
          <Typography variant="H-TEXT-1" color={colors.white}>
            -
          </Typography>
        </Button>
      </div>
    ))}
  </div>
);

export default CreateStudentGroupDialog;
