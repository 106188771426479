import React from 'react';

import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import { Typography } from 'pages/Students/shared';
import { colors } from 'theme/palette';
import { AssignmentStatusOptions } from 'utils/constants';

import { ReactComponent as AssignedBig } from './assets/assigned_big.svg';
import { ReactComponent as CompleteBig } from './assets/complete_big.svg';
import { ReactComponent as ReviseBig } from './assets/revise_big.svg';
import { ReactComponent as SubmittedBig } from './assets/submitted_big.svg';
import styles from './styles.module.css';

export const StatusOptions = {
  ALL: 'all',
  ...AssignmentStatusOptions
};

export const DarkStatusIcons = {
  [AssignmentStatusOptions.ASSIGNED]: {
    icon: AssignedBig,
    color: colors.yellow2
  },
  [AssignmentStatusOptions.REVISE]: {
    icon: ReviseBig,
    color: colors.pink1
  },
  [AssignmentStatusOptions.SUBMITTED]: {
    icon: SubmittedBig,
    color: colors.blue4
  },
  [AssignmentStatusOptions.COMPLETE]: {
    icon: CompleteBig,
    color: colors.green2
  }
};
export const LightStatusIcons = {
  [AssignmentStatusOptions.ASSIGNED]: {
    icon: AssignedBig,
    color: colors.yellow4
  },
  [AssignmentStatusOptions.REVISE]: {
    icon: ReviseBig,
    color: colors.pink4
  },
  [AssignmentStatusOptions.SUBMITTED]: {
    icon: SubmittedBig,
    color: colors.blue5
  },
  [AssignmentStatusOptions.COMPLETE]: {
    icon: CompleteBig,
    color: colors.green4
  }
};

export const AssignmentStatus = ({
  status,
  hideText = false,
  className,
  onClick,
  light = true
}) => {
  const { icon, color } = light
    ? _.get(LightStatusIcons, status, { icon: () => '' })
    : _.get(DarkStatusIcons, status, { icon: () => '' });

  const IconComponent = icon;

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      onClick={onClick}
      className={className}
    >
      <IconComponent style={{ fill: color }} />
      {!hideText && (
        <Typography variant="S-TEXT-3" color={color} className={styles.name}>
          {status}
        </Typography>
      )}
    </Grid>
  );
};

export const AssignmentStatusHorizontal = ({
  status,
  onClick,
  lgScreen,
  light = true
}) => {
  const { icon, color } = light
    ? LightStatusIcons[status]
    : DarkStatusIcons[status];

  const IconComponent = icon;

  return (
    <Grid container alignItems="center" onClick={onClick} spacing={1}>
      <Grid item>
        <IconComponent className={styles.smallIcon} style={{ fill: color }} />
      </Grid>
      <Grid item>
        <Typography variant={lgScreen ? 'S-TEXT-1' : 'S-TEXT-2'} color={color}>
          {status}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const AssignmentStatusIcon = ({ status }) => {
  const { icon, color } = DarkStatusIcons[status];

  const IconComponent = icon;

  return <IconComponent className={styles.smallIcon} style={{ fill: color }} />;
};
