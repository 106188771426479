import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import { StudentCardSkeleton } from 'pages/Teachers/shared';

import styles from './styles.module.css';

const LoadingGridViewSkeleton = () => (
  <>
    <div className={styles.monthName}>
      <Skeleton variant="text" width={100} />
    </div>
    <div className={styles.monthCards}>
      {[1, 2, 3].map((x) => (
        <StudentCardSkeleton
          key={x}
          className={styles.cardSkeleton}
          objectivesCount={1}
        />
      ))}
    </div>
  </>
);

export default LoadingGridViewSkeleton;
