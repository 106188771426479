import React from 'react';

import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { ActivityStreamVerbs } from 'utils/constants';
import { calendarDate } from 'utils/moment';

import Typography from 'components/Typography';

import { ReactComponent as AssignedIcon } from './assets/assigned.svg';
import { ReactComponent as CreateIcon } from './assets/create.svg';
import { ReactComponent as ReviewIcon } from './assets/review.svg';
import { ReactComponent as SubmitIcon } from './assets/submit.svg';
import styles from './styles.module.css';

const ActivityIcon = ({ verb }) => {
  const mapping = {
    [ActivityStreamVerbs.CREATED]: (
      <SvgIcon component={CreateIcon} className={styles.activityIcon} />
    ),
    [ActivityStreamVerbs.UPDATED]: (
      <SvgIcon component={CreateIcon} className={styles.activityIcon} />
    ),
    [ActivityStreamVerbs.ASSIGNED]: (
      <SvgIcon component={AssignedIcon} className={styles.activityIcon} />
    ),
    [ActivityStreamVerbs.REVIEWED]: (
      <SvgIcon component={ReviewIcon} className={styles.activityIcon} />
    ),
    [ActivityStreamVerbs.SUBMITTED]: (
      <SvgIcon component={SubmitIcon} className={styles.activityIcon} />
    ),
    [ActivityStreamVerbs.REVISED]: (
      <SvgIcon component={SubmitIcon} className={styles.activityIcon} />
    )
  };
  return mapping[verb];
};

export const ActivityList = ({ stream }) => (
  <Grid container spacing={1}>
    {_.map(stream, (action) => (
      <Grid key={action.id} item container className={styles.actionRow}>
        <Grid item>
          <ActivityIcon verb={action.verb} />
        </Grid>
        <Grid item>
          <Typography
            variant="B-Text-2"
            style={{ display: 'inline-block', color: colors.grey1 }}
          >
            {action.actor.name}
          </Typography>{' '}
          <Typography
            variant="H-TEXT-3"
            style={{ display: 'inline-block', color: colors.blueLight }}
          >
            {action.verb}
          </Typography>{' '}
          <Typography
            variant="B-Text-2"
            style={{ display: 'inline-block', color: colors.grey1 }}
          >
            {action.data.tracker_name}
          </Typography>{' '}
          {action.verb === ActivityStreamVerbs.ASSIGNED && (
            <>
              <Typography
                variant="B-Text-2"
                style={{ display: 'inline-block', color: colors.grey1 }}
              >
                to {action.data.section_name}
              </Typography>{' '}
            </>
          )}
          <Typography
            variant="B-Text-2"
            style={{ display: 'inline-block', color: colors.grey3 }}
          >
            {calendarDate(action.timestamp)}
          </Typography>
        </Grid>
      </Grid>
    ))}
  </Grid>
);
