import React from 'react';

import _ from 'lodash';

import { getObjectiveValueTypeIcon } from 'pages/Teachers/shared/utils';
import { colors } from 'theme/palette';
import { getObjectiveValueTypeName } from 'utils/trackers';

import Typography from 'components/Typography';

import styles from './styles.module.css';

export const TasksList = ({ tasks }) => (
  <div>
    {_.map(tasks, (task) => {
      const valueTypeName = getObjectiveValueTypeName(task);
      const ValueTypeIcon = getObjectiveValueTypeIcon(task.value_type);

      return (
        <div key={task.id}>
          <div className={styles.task}>
            <Typography noWrap variant="S-TEXT-1" color={colors.blueDark}>
              {task.name}
            </Typography>

            <div className={styles.valueType}>
              <ValueTypeIcon htmlColor={colors.greyDarker} />
              <Typography variant="B-Text-3" color={colors.grey1}>
                - {valueTypeName}
              </Typography>
            </div>
          </div>

          <div className={styles.criteria}>
            {_.map(task.children, (criteria) => (
              <Typography
                key={criteria.id}
                variant="B-Text-3"
                color={colors.blue1}
              >
                {criteria.name}
              </Typography>
            ))}
          </div>
        </div>
      );
    })}
  </div>
);
