import { BASE_URL } from 'config/urls';

import { callUrl, get, post } from 'utils/sdk';

export const getSchoolUsers = ({ id, search }) =>
  callUrl(get, `${BASE_URL}/v1/schools/schools-school-users/`, {
    name: search,
    schools_ids: [id]
  });

export const assignStudentGroup = (data) =>
  callUrl(post, `${BASE_URL}/v1/sections/student-groups/assign/`, data);
