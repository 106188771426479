import React, { useCallback } from 'react';

import classnames from 'classnames';
import { addCommentToSection } from 'sdk';
import { useCaching } from 'utils';

import { Comments } from 'pages/Teachers/shared';
import { notifyError } from 'utils/notifications';

import styles from './styles.module.scss';

const FeedComment = ({ element, sectionId }) => {
  const [comment, setComment] = useCaching(element);

  const handleAddingComment = useCallback(
    async (event, text) => {
      if (text === '') return;

      const { data, success, errors } = await addCommentToSection({
        sectionId,
        data: {
          parent: element.id,
          text
        }
      });

      if (success) {
        setComment((comment) => ({
          ...comment,
          replies: [...comment.replies, data]
        }));
      } else {
        notifyError(errors);
      }
    },
    [sectionId, setComment, element.id]
  );
  return (
    <div
      className={classnames(styles.commentBlock, {
        [styles.newComment]: element.newComment
      })}
    >
      <Comments comments={[comment]} onSubmit={handleAddingComment} />
    </div>
  );
};

export default FeedComment;
