import React from 'react';

import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import _ from 'lodash';

import {
  ActivityStreamTimespanOptions,
  ActivityStreamVerbs
} from 'utils/constants';
import { notifyErrors } from 'utils/notifications';

import SearchFilter from 'components/SearchFilter';
import SelectFilter from 'components/SelectFilter';

import { actorNamesList, userSections, userSectionsTrackers } from './sdk';
import styles from './styles.module.css';

// Drop until we have them working.
const ACTIONS_TO_OMIT = ['UPDATED', 'SCORED', 'GENERATED'];

class ActivityFilters extends React.Component {
  state = {
    userSections: [],
    trackers: [],
    actors: []
  };

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    await Promise.all([
      this.fetchUserSections(),
      this.fetchUserSectionsTrackers(),
      this.fetchActors()
    ]);
  }

  fetchUserSections = async () => {
    const { data, success, errors } = await userSections();

    if (success) {
      this.setState({ userSections: data.results });
    } else {
      notifyErrors(errors);
    }
  };

  fetchUserSectionsTrackers = async () => {
    const { data, success, errors } = await userSectionsTrackers();

    if (success) {
      this.setState({ trackers: data });
    } else {
      notifyErrors(errors);
    }
  };

  fetchActors = async () => {
    const { data, success, errors } = await actorNamesList();

    if (success) {
      this.setState({ actors: this.orderActors(data) });
    } else {
      notifyErrors(errors);
    }
  };

  orderActors = (actors) => {
    // Make sure actors are ordered correctly -> `Me` is always first
    const { user } = this.props;

    _.remove(actors, { id: user.id });
    return [{ ...user, name: 'Me' }, ...actors];
  };

  render() {
    const { filters, lgScreen, user } = this.props;

    const { actors, trackers, userSections } = this.state;

    return (
      <Grid
        container
        spacing={1}
        className={classnames({
          [styles.filtersWrapper]: lgScreen,
          [styles.filtersWrapperTablet]: !lgScreen
        })}
      >
        <Grid item xs>
          <SearchFilter
            freeSolo
            autoComplete
            value={_.get(filters, 'actor', '')}
            options={_.map(actors, 'name')}
            onChange={(e, val) =>
              this.props.filterActor(val === 'Me' ? user.name : val)
            }
            placeholder="Teacher/Student"
          />
        </Grid>
        <Grid item xs>
          <SearchFilter
            freeSolo
            autoComplete
            value={_.get(filters, 'title', '')}
            options={_.map(trackers, 'name')}
            onChange={(e, val) => this.props.filterTitle(val)}
            placeholder="Title"
          />
        </Grid>
        <Grid item xs>
          <SearchFilter
            freeSolo
            autoComplete
            value={_.get(filters, 'section', '')}
            options={_.map(userSections, 'name')}
            onChange={(e, val) => this.props.filterClass(val)}
            placeholder="Class"
          />
        </Grid>
        <Grid item xs>
          <SelectFilter
            value={_.get(filters, 'verb', ActivityStreamVerbs.ALL)}
            // Remove updated as option for now because the action is not generated anywhere
            options={_.map(
              _.omit(ActivityStreamVerbs, ACTIONS_TO_OMIT),
              (verb) => ({
                value: verb,
                label: _.capitalize(verb)
              })
            )}
            onChange={(e) => this.props.filterVerb(e.target.value)}
            placeholder="Action"
          />
        </Grid>
        <Grid item xs>
          <SelectFilter
            value={_.get(
              filters,
              'timespan',
              ActivityStreamTimespanOptions.MONTH.value
            )}
            options={_.map(ActivityStreamTimespanOptions, (option) => ({
              value: option.value,
              label: option.text
            }))}
            onChange={(e) => this.props.filterTimespan(e.target.value)}
            placeholder="Timespan"
          />
        </Grid>
      </Grid>
    );
  }
}

export default ActivityFilters;
