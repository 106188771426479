import React, { forwardRef, useEffect, useRef } from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

const useStyles = makeStyles({
  rounded: {
    border: `1px solid ${colors.grey6}`,
    borderRadius: 25,
    height: 40,
    padding: '4px 10px 0 16px'
  },
  underlined: {
    // Default appearance
    height: 40,
    borderRadius: '5px 5px 1px 1px',
    '& .MuiInputBase-root': { borderRadius: '5px 5px 1px 1px' },
    '& .MuiSelect-root': {
      paddingLeft: 10
    },
    '& input': {
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: '15px',
      lineHeight: 'initial',
      fontWeight: 400,
      letterSpacing: '0px'
    },
    '& textarea': {
      paddingLeft: 10,
      paddingRight: 10
    },
    '& .MuiInputAdornment-root button': { padding: 10 }, // 12px by MUI deafult

    // TODO: Remove after we delete the MuiTextField override
    border: 0,
    paddingLeft: 0,
    paddingRight: 0,
    // =======================================================

    // Borders
    '& .MuiInput-underline:before': {
      borderBottomColor: colors.grey4,
      borderBottomWidth: 2
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.grey4,
      borderBottomWidth: 2
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: colors.greyDark
    },
    '& .Mui-focused.MuiInput-underline:after': {
      borderBottomColor: colors.greenDarker
    },
    '& .Mui-error.MuiInput-underline:after': {
      borderBottomColor: colors.pink
    },
    '& .Mui-error.Mui-focused.MuiInput-underline:after': {
      borderBottomColor: colors.pink
    },
    // Backgound
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.grey6
    },
    '& .Mui-focused': {
      backgroundColor: colors.grey6
    },
    // Error text
    '& .MuiFormHelperText-root': {
      color: colors.pink,
      backgroundColor: colors.white,
      paddingLeft: 10
    }
  },
  withBlueUnderline: {
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: colors.grey4
    },
    '& .Mui-focused.MuiInput-underline:after': {
      borderBottomColor: colors.greyDark
    }
  },
  default: {}
});

const useHasValueStyles = makeStyles({
  root: {
    backgroundColor: colors.grey6,
    '& .MuiInput-underline:before': {
      borderBottomColor: colors.greyDark,
      borderBottomWidth: 2
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.greyDark,
      borderBottomWidth: 2
    }
  },
  withBlueUnderline: {
    '& .MuiInput-underline:before': {
      borderBottomColor: colors.blue4,
      borderBottomWidth: 2
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.blue4,
      borderBottomWidth: 2
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: colors.blue4
    }
  }
});

const renderValue = (value) => (
  <Typography variant="B-Text-2" color={colors.grey1} noWrap>
    {value}
  </Typography>
);

const InputField = (
  {
    form,
    field = {},
    selectOnMount,
    className,
    variant = 'rounded',
    valueEqualToTheInitial = false,
    withBlueUnderline = false,
    InputProps,
    ...rest
  },
  ref
) => {
  const hasValue = !_.isEmpty(_.get(field, 'value') || _.get(rest, 'value'));

  const componentRef = useRef(null);
  const styles = useStyles();
  const hasValueStyles = useHasValueStyles();

  useEffect(() => {
    if (componentRef && selectOnMount) {
      componentRef.current.focus();
    }

    if (variant && !styles[variant]) {
      raiseImproperlyConfigured();
    }
  });

  const raiseImproperlyConfigured = () => {
    throw new Error(
      `No such variant for InputField. Options are ${_.keys(styles)}`
    );
  };

  const localInputProps = { disableUnderline: variant === 'rounded' };

  // helperText in the arguments below should be set before `{...rest}` so it can be overwritten from outside.
  const errorText =
    rest.helperText ||
    (_.get(form, `touched[${field.name}]`) &&
      _.get(form, `errors[${field.name}]`));

  // Note! passing onBlur prop here would break the DateTimePickers that use this component.
  return (
    <TextField
      ref={ref || componentRef}
      helperText={errorText}
      error={!_.isNil(errorText)}
      value={field.value}
      name={field.name}
      onChange={field.onChange}
      renderValue={renderValue}
      {...rest}
      className={classnames(
        styles[variant],
        {
          [hasValueStyles.root]: hasValue && !valueEqualToTheInitial,
          [styles.withBlueUnderline]: withBlueUnderline,
          [hasValueStyles.withBlueUnderline]:
            hasValue && withBlueUnderline && !valueEqualToTheInitial
        },
        className
      )}
      InputProps={{ ...localInputProps, ...InputProps }}
    />
  );
};

export default forwardRef(InputField);
