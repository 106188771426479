import React, { useCallback, useContext, useEffect, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import cx from 'classnames';
import {
  CLEVER_SSO_TEACHER_REDIRECT_URI,
  GOOGLE_CLASSROOM_LOGIN_REDIRECT_URI
} from 'config/constants';
import {
  EDLIGHT_MAIN_DOMAIN_URL,
  WALKTHROUGH_CREATE_PROFILE_URL
} from 'config/urls';
import logo from 'images/logo-dark.svg';
import _ from 'lodash';
import { completeProfile as completeInvitationProfile } from 'sdk';

import { useWalkthroughUser } from 'pages/Walkthrough/components';
import MeetingInvitationSelectSchool from 'pages/Walkthrough/components/RolloutForm/components/MeetingInvitationSelectSchool';
import { buildFilters } from 'utils/filters';
import { openCleverLoginPage } from 'utils/integrations/clever';
import { openGoogleLoginPage } from 'utils/integrations/google';
import { updateIntercomEntity } from 'utils/integrations/intercom';
import { notifyError, notifyErrors } from 'utils/notifications';
import { useBreakpoints } from 'utils/resizing';
import { parseParams } from 'utils/urls';

import Image from 'components/Image';
import UserContext from 'components/UserContext';

import { StudentsLoadMethodChooser, TeacherDataForm } from './components';
import {
  completeWalkthroughProfile,
  createSchool,
  fetchMe,
  getOrCreateWalkthroughOrganization,
  getSchoolsList,
  getStatesList,
  updateUserPreferredName
} from './sdk';
import styles from './styles.module.css';

const RolloutForm = ({ history, location }) => {
  useWalkthroughUser(location.pathname);

  const searchParams = parseParams(history.location.search);

  const invitationIdentifier = _.get(searchParams, 'invitationIdentifier');

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      fetchMe().then(({ data, success }) => {
        if (success) {
          setUser(data);
        } else {
          notifyError('You need to create account first');
          history.push(WALKTHROUGH_CREATE_PROFILE_URL);
        }
      });
    }
  }, [user, setUser, history]);

  useEffect(() => {
    if (user) {
      updateIntercomEntity({ email: user.email });
    }
  }, [user]);

  const [activeStep, setActiveStep] = useState(0);

  const [preferredName, setPreferredName] = useState('');

  const [schools, setSchools] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedWalkthroughSchool, setSelectedWalkthroughSchool] =
    useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const [isCompletingProfile, setIsCompletingProfile] = useState(false);

  const [selectedSchoolId, setSelectedSchoolId] = useState(null);

  const [showSelectFromMeetingSchools, setShowSelectFromMeetingSchools] =
    useState(!_.isNil(invitationIdentifier));

  useEffect(() => {
    if (selectedState) {
      getSchoolsList(buildFilters({ state: selectedState }))
        .then(({ data, success, errors }) => {
          if (success) {
            setSchools(data);
          } else {
            notifyErrors(errors);
          }
        })
        .catch(notifyErrors);
    }
  }, [selectedState]);

  useEffect(() => {
    getStatesList()
      .then(({ data, success, errors }) => {
        if (success) {
          setStates(data);
        } else {
          notifyErrors(errors);
        }
      })
      .catch(notifyErrors);
  }, []);

  const openGoogleClassroomLoginPage = () => {
    const redirectUri = GOOGLE_CLASSROOM_LOGIN_REDIRECT_URI;

    const scope = _.join(
      [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/classroom.profile.emails',
        'https://www.googleapis.com/auth/classroom.courses.readonly',
        'https://www.googleapis.com/auth/classroom.rosters.readonly'
      ],
      ' '
    );

    const state = {
      schoolId: selectedSchoolId,
      userId: user.id,
      searchParams
    };

    openGoogleLoginPage(redirectUri, scope, state);
  };

  const openCleverSsoPage = () => {
    const redirectUri = CLEVER_SSO_TEACHER_REDIRECT_URI;
    const state = {
      schoolId: selectedSchoolId,
      userId: user.id,
      searchParams
    };

    openCleverLoginPage(redirectUri, state);
  };

  const ensureWalkthroughOrganization = async (values, orgData) => {
    const { data, success, errors } = await getOrCreateWalkthroughOrganization(
      orgData
    );

    if (success) {
      await createNewSchool(values, data.id);
    } else {
      notifyErrors(errors);
    }
  };

  const createNewSchool = async (values, walkthroughOrganizationId) => {
    const newSchoolData = {
      name: selectedWalkthroughSchool.name,
      state: values.stateAbbreviation,
      walkthrough_organization: walkthroughOrganizationId,
      city: values.city
    };

    const { data, success, errors } = await createSchool(newSchoolData);

    if (success) {
      await completeProfile(data.id);
    } else {
      notifyErrors(errors);
    }
  };

  const completeProfile = async (walkthroughSchoolId) => {
    const schoolData = {
      walkthrough_school: walkthroughSchoolId
    };

    const { data, success, errors } = await completeWalkthroughProfile(
      schoolData
    );

    if (success) {
      setSelectedSchoolId(data.school_id);
      setActiveStep(1);
    } else {
      notifyErrors(errors);
    }
  };

  const handleInvitedMemberProfileComplete = useCallback(
    async ({ schoolId, preferredUserName }) => {
      setIsCompletingProfile(true);

      await updateUserPreferredName(user.id, {
        preferred_name: preferredUserName
      });

      const { success, errors } = await completeInvitationProfile({
        data: { school: schoolId }
      });

      if (success) {
        setSelectedSchoolId(schoolId);
        setActiveStep(1);
        setShowSelectFromMeetingSchools(false);
      } else {
        notifyErrors(errors);
      }
    },
    [user?.id]
  );

  const handleJoinDifferentSchool = useCallback(({ preferredName }) => {
    setPreferredName(preferredName);
    setShowSelectFromMeetingSchools(false);
  }, []);

  const onNextClick = async (values) => {
    setIsCompletingProfile(true);

    await updateUserPreferredName(user.id, {
      preferred_name: values.preferredUserName
    });

    if (_.isNull(selectedWalkthroughSchool.id)) {
      await ensureWalkthroughOrganization(values, {
        organization: selectedOrganization.id,
        name: selectedOrganization.name
      });
    } else {
      await completeProfile(selectedWalkthroughSchool.id);
    }

    setIsCompletingProfile(false);
  };

  return (
    <div className={styles.page}>
      {!isMobile && (
        <a href={EDLIGHT_MAIN_DOMAIN_URL}>
          <Image src={logo} className={styles.logoSmall} alt="Logo link" />
        </a>
      )}
      <div className={styles.container}>
        <Paper
          elevation={0}
          className={cx(styles.paper, {
            [styles.smallerPaper]: activeStep === 1
          })}
        >
          <Image src={logo} className={styles.logoBig} alt="Logo placeholder" />

          {!_.isNil(invitationIdentifier) && showSelectFromMeetingSchools && (
            <MeetingInvitationSelectSchool
              invitationIdentifier={invitationIdentifier}
              isCompletingProfile={isCompletingProfile}
              onJoinDifferentSchool={handleJoinDifferentSchool}
              onNextClick={handleInvitedMemberProfileComplete}
            />
          )}

          {activeStep === 0 && !showSelectFromMeetingSchools && (
            <TeacherDataForm
              schools={schools}
              states={states}
              selectedWalkthroughSchool={selectedWalkthroughSchool}
              setSelectedWalkthroughSchool={setSelectedWalkthroughSchool}
              setSelectedState={setSelectedState}
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
              isCompletingProfile={isCompletingProfile}
              onNextClick={onNextClick}
              initalPreferredName={preferredName}
            />
          )}
          {activeStep === 1 && (
            <StudentsLoadMethodChooser
              loadViaGoogleClassroom={openGoogleClassroomLoginPage}
              loadViaClever={openCleverSsoPage}
              invitationIdentifier={invitationIdentifier}
            />
          )}
        </Paper>
      </div>
    </div>
  );
};

export default RolloutForm;
