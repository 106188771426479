import React from 'react';

import { colors } from 'theme/palette';

import SelectFilter from 'components/SelectFilter';
import Typography from 'components/Typography';

import { excludeAssignedFilterOptions } from './constants';
import styles from './styles.module.css';

const Filters = ({ filters, setFilters, ...props }) => (
  <div className={styles.filtersWrapper}>
    <div>
      <Typography variant="S-TEXT-1" color={colors.grey3}>
        Show
      </Typography>
      <SelectFilter
        className={styles.filterField}
        options={excludeAssignedFilterOptions}
        value={filters.exclude_assigned}
        onChange={(e) =>
          setFilters((prevFilters) => ({
            ...prevFilters,
            exclude_assigned: e.target.value
          }))
        }
        {...props}
      />
    </div>
  </div>
);

export default Filters;
