import React from 'react';

import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import BarChartIcon from '@material-ui/icons/BarChart';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import Alert from '@material-ui/lab/Alert';
import classnames from 'classnames';
import { Field } from 'formik';
import { ReactComponent as TrashIcon } from 'images/trashicon.svg';
import _ from 'lodash';
import { getStandardButtonLabel } from 'utils';

import { ObjectiveSelectField } from 'pages/Teachers/shared/AssignmentDialog/components';
import {
  EMPTY_CRITERIA,
  EMPTY_TASK,
  VALUES_OPTIONS
} from 'pages/Teachers/shared/AssignmentDialog/constants';
import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';

import Button from 'components/Button';
import DebouncedField from 'components/DebouncedField';
import EmojiInputField from 'components/EmojiInputField';
import InputField from 'components/InputField';
import StandardButton from 'components/StandardButton';
import StandardDialog from 'components/StandardDialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const TasksForm = ({ push, replace, move, remove, isAssigned, form }) => {
  const {
    isOpened: isStandardDialogOpened,
    closeDialog: closeStandardDialog,
    openDialog: openStandardDialog,
    setDialogData: setStandardDialogData,
    dialogData: standardDialogData
  } = useDialog();

  const addTask = () => {
    const assignmentStandards = _.chain(form.values.tasks)
      .map('standards')
      .flatten()
      .uniqBy('id')
      .value();

    push({
      ...EMPTY_TASK,
      standards: assignmentStandards,
      disableEdit: false,
      name: `Page ${form.values.tasks.length + 1}`
    });
  };

  const addCriteria = ({ task, taskIndex }) => {
    replace(taskIndex, {
      ...task,
      children: [...task.children, { ...EMPTY_CRITERIA, disableEdit: false }]
    });
  };

  const removeCriteria = ({ task, taskIndex, criteriaIndex }) => {
    task.children.splice(criteriaIndex, 1);

    replace(taskIndex, task);
  };

  const moveUp = ({ taskIndex }) => {
    move(taskIndex, taskIndex - 1);
  };

  const moveDown = ({ taskIndex }) => {
    move(taskIndex, taskIndex + 1);
  };

  const removeTask = ({ taskIndex }) => {
    remove(taskIndex);
  };

  const copyTask = ({ task }) => {
    const editableTaskChildren = _.map(task.children, (child) => ({
      ..._.omit(child, 'id'),
      disableEdit: false
    }));

    push({
      ..._.omit(task, 'id'),
      disableEdit: false,
      children: editableTaskChildren
    });
  };

  const { values, errors, setFieldValue } = form;

  return (
    <div className={styles.tasks}>
      <Typography variant="H-TEXT-2" color={colors.blue1}>
        Pages
      </Typography>

      {_.map(values.tasks, (task, taskIndex) => {
        const taskFieldName = `tasks.${taskIndex}.name`;
        const tasksLength = _.size(values.tasks);

        const shouldFocusTaskName =
          taskIndex === tasksLength - 1 && taskIndex !== 0;
        const objectiveFieldValueType = `tasks.${taskIndex}.value_type`;

        const disableMoveUp = taskIndex === 0;
        const disableMoveDown = taskIndex === tasksLength - 1;
        const disableTaskEdit = _.get(task, 'disableEdit', isAssigned);

        return (
          <React.Fragment key={taskIndex}>
            <Grid container alignItems="center" className={styles.tasksBox}>
              <Grid
                container
                item
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={8}>
                  <DebouncedField
                    onChange={(value) => setFieldValue(taskFieldName, value)}
                    component={EmojiInputField}
                    hideEmojiTogglerOnInactiveInput
                    autoFocus={shouldFocusTaskName}
                    error={_.has(errors, taskFieldName)}
                    helperText={_.get(errors, taskFieldName)}
                    value={task.name}
                    fullWidth
                    placeholder="Page Name"
                    variant="underlined"
                    EmojiButtonProps={{ tabIndex: '-1' }}
                    classes={{ root: styles.name }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    required
                    fullWidth
                    disabled={disableTaskEdit}
                    name={objectiveFieldValueType}
                    value={task.value_type}
                    component={ObjectiveSelectField}
                    options={VALUES_OPTIONS}
                    error={_.has(errors, objectiveFieldValueType)}
                  />
                </Grid>
              </Grid>

              {task.children.length > 0 && (
                <Grid item className={styles.criteriaTitle}>
                  <Typography variant="H-TEXT-3" color={colors.blue1}>
                    Tasks
                  </Typography>
                </Grid>
              )}

              {task.children.map((criteria, criteriaIndex, criteriaList) => {
                const criteriaFieldName = `tasks.${taskIndex}.children.${criteriaIndex}.name`;
                const shouldFocusCriteriaName =
                  criteriaIndex === criteriaList.length - 1;

                const disableCriteriaEdit = _.get(
                  criteria,
                  'disableEdit',
                  isAssigned
                );

                return (
                  <Grid
                    key={criteriaIndex}
                    container
                    item
                    spacing={1}
                    alignItems="center"
                    className={styles.criteriaBox}
                  >
                    <Grid item className={styles.fakeColumn} />
                    <Grid item xs={6}>
                      <DebouncedField
                        className={styles.criteriaNameField}
                        component={InputField}
                        autoFocus={shouldFocusCriteriaName}
                        fullWidth
                        onChange={(value) =>
                          setFieldValue(criteriaFieldName, value)
                        }
                        value={criteria.name}
                        placeholder="Task Name"
                        variant="underlined"
                      />
                    </Grid>
                    <Grid item className={styles.removeCriteria}>
                      <Tooltip title="DELETE TASK">
                        <TrashIcon
                          className={classnames(styles.action, {
                            [styles.disabled]: disableCriteriaEdit
                          })}
                          onClick={() =>
                            disableCriteriaEdit
                              ? undefined
                              : removeCriteria({
                                  task,
                                  taskIndex,
                                  criteriaIndex
                                })
                          }
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid
                item
                container
                className={classnames(styles.topOffset, styles.criteriaBox)}
              >
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item container xs={6} justify="flex-start" spacing={1}>
                    <Grid item>
                      <Button
                        color="lightGrey"
                        className={styles.addCriteriaButton}
                        onClick={() => addCriteria({ task, taskIndex })}
                      >
                        <Typography
                          variant="B-Text-3"
                          className={styles.buttonContent}
                        >
                          <SvgIcon
                            component={BarChartIcon}
                            className={classnames(
                              styles.rotated,
                              styles.buttonIcon
                            )}
                          />
                          {task.children.length > 0 && 'Add '}Tasks
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <StandardButton
                        label={getStandardButtonLabel({
                          standards: task.standards
                        })}
                        blue={_.get(task, 'standards.length', 0) > 0}
                        onClick={() => {
                          setStandardDialogData({ task, taskIndex });
                          openStandardDialog();
                        }}
                        className={styles.standardButton}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container xs={6} justify="flex-end" spacing={4}>
                    <Grid item>
                      <Tooltip title="MOVE UP">
                        <SvgIcon
                          component={ArrowUpwardRoundedIcon}
                          className={classnames(styles.action, {
                            [styles.disabled]: disableMoveUp
                          })}
                          onClick={() =>
                            disableMoveUp ? undefined : moveUp({ taskIndex })
                          }
                        />
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Tooltip title="MOVE DOWN">
                        <ArrowDownwardRoundedIcon
                          className={classnames(styles.action, {
                            [styles.disabled]: disableMoveDown
                          })}
                          onClick={() =>
                            disableMoveDown
                              ? undefined
                              : moveDown({ taskIndex })
                          }
                        />
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Tooltip title="DUPLICATE">
                        <FileCopyRoundedIcon
                          className={styles.action}
                          onClick={() => copyTask({ task })}
                        />
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Tooltip title="DELETE PAGE">
                        <TrashIcon
                          className={classnames(styles.action, {
                            [styles.disabled]: disableTaskEdit
                          })}
                          onClick={() =>
                            disableTaskEdit
                              ? undefined
                              : removeTask({ taskIndex })
                          }
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })}
      {typeof errors.tasks === 'string' && (
        <Alert
          variant="outlined"
          severity="error"
          className={styles.errorAlert}
        >
          {errors.tasks}
        </Alert>
      )}
      <Button
        width="100%"
        className={styles.addTaskButton}
        onClick={addTask}
        color="transparent"
        startIcon={<SvgIcon component={AddIcon} />}
      >
        Add Page
      </Button>

      {isStandardDialogOpened && (
        <StandardDialog
          selectedItems={standardDialogData.task.standards}
          onClose={closeStandardDialog}
          onSave={({ selectedStandards }) => {
            const objectiveStandardsFieldName = `tasks.${standardDialogData?.taskIndex}.standards`;

            setFieldValue(objectiveStandardsFieldName, selectedStandards);
          }}
        />
      )}
    </div>
  );
};

export default TasksForm;
