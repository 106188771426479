import React, { useCallback, useMemo, useState } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { MuiAutocompleteChangeReasons } from 'utils/constants';
import { notifyErrors } from 'utils/notifications';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import SearchFilter from 'components/SearchFilter';
import Typography from 'components/Typography';

import { useTeam, useTeamMembers, useTeamSchools } from './hooks';
import { updateTeacherTeam } from './sdk';
import styles from './styles.module.css';

const getOptionLabel = (option) => _.get(option, 'name', '');

const SelectedSchools = ({ schools }) => (
  <div className={styles.multiSelect}>
    {_.map(schools, (school) => (
      <div key={school.id} className={styles.selectedElementRow}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {school.name}
        </Typography>
      </div>
    ))}
  </div>
);

const SelectedUsers = ({ users, removeUser }) => (
  <div className={styles.multiSelect}>
    {_.map(users, (user) => (
      <div key={user.id} className={styles.selectedElementRow}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {user.name}
        </Typography>
        <Button
          color="blue"
          variant="small"
          noTypography
          className={styles.minusButton}
          onClick={() => removeUser(user)}
        >
          <Typography variant="H-TEXT-1" color={colors.white}>
            -
          </Typography>
        </Button>
      </div>
    ))}
  </div>
);

const UpdateTeacherTeamDialog = ({ teamId, onClose, onSuccess }) => {
  const { team, teamName, setTeamName } = useTeam(teamId);

  const { schools, addSchool, schoolName, setSchoolName, selectedSchools } =
    useTeamSchools(team);

  const { users, addUser, removeUser, userName, setUserName, selectedUsers } =
    useTeamMembers(team, selectedSchools);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const disableSubmit = useMemo(
    () => _.isEmpty(teamName) || _.isEmpty(selectedUsers) || isSubmitting,
    [teamName, selectedUsers, isSubmitting]
  );

  const filterSchoolOptions = useCallback(
    (options) =>
      _.reject(options, ({ id }) =>
        _.includes(_.map(selectedSchools, 'id'), id)
      ),
    [selectedSchools]
  );
  const onSchoolSearchInputChange = useCallback(
    (e, val, trigger) => {
      if (trigger === 'input') {
        setSchoolName(val);
      }
    },
    [setSchoolName]
  );
  const onSchoolSearchChange = useCallback(
    (e, opt, trigger) => {
      if (trigger === MuiAutocompleteChangeReasons.SELECT_OPTION) {
        addSchool(opt);
      }
    },
    [addSchool]
  );

  const filterUserOptions = useCallback(
    (options) =>
      _.reject(options, ({ id }) => _.includes(_.map(selectedUsers, 'id'), id)),
    [selectedUsers]
  );
  const onUserSearchInputChange = useCallback(
    (e, val, trigger) => {
      if (trigger === 'input') {
        setUserName(val);
      }
    },
    [setUserName]
  );
  const onUserSearchChange = useCallback(
    (e, opt, trigger) => {
      if (trigger === MuiAutocompleteChangeReasons.SELECT_OPTION) {
        addUser(opt);
      }
    },
    [addUser]
  );

  const buildTeamUpdateData = useCallback(() => {
    // Construct update data only with fields that have actually changed.
    const updateData = {};

    if (team.name !== teamName) {
      updateData.name = teamName;
    }

    const existingSchoolsIds = _.map(team.schools, 'id');
    const newSchoolsIds = _.map(selectedSchools, 'id');
    if (!_.isEqual(existingSchoolsIds, newSchoolsIds)) {
      updateData.schools_to_add = newSchoolsIds;
    }

    const existingUsersIds = _.map(team.teacher_members, 'user.id');
    const newUsersIds = _.map(selectedUsers, 'id');
    if (!_.isEqual(existingUsersIds, newUsersIds)) {
      updateData.members = newUsersIds;
    }

    return updateData;
  }, [team, teamName, selectedSchools, selectedUsers]);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);

    const teamUpdateData = buildTeamUpdateData();
    const { success, data, errors } = await updateTeacherTeam(
      teamId,
      teamUpdateData
    );

    setIsSubmitting(false);

    if (success) {
      onSuccess(data);
      onClose();
    } else {
      notifyErrors(errors);
    }
  }, [buildTeamUpdateData, teamId, onSuccess, onClose]);

  const onTeamNameChange = useCallback(
    (event) => setTeamName(event.target.value),
    [setTeamName]
  );

  return (
    <Dialog
      open
      alignTop
      fullWidth
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Update teacher team
        </Typography>
        <Button
          color="pink"
          onClick={onSubmit}
          disabled={disableSubmit}
          className={styles.updateButton}
        >
          Update
        </Button>
      </div>
      <>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Schools
        </Typography>
        <SearchFilter
          inputValue={schoolName}
          placeholder="Add School"
          options={schools}
          filterOptions={filterSchoolOptions}
          getOptionLabel={getOptionLabel}
          onChange={onSchoolSearchChange}
          onInputChange={onSchoolSearchInputChange}
        />
        {!_.isEmpty(selectedSchools) && (
          <SelectedSchools schools={selectedSchools} />
        )}

        <InputField
          fullWidth
          variant="underlined"
          placeholder="Team name"
          value={teamName}
          onChange={onTeamNameChange}
          className={styles.label}
        />

        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Staff
        </Typography>
        <SearchFilter
          inputValue={userName}
          placeholder="Add Staff"
          options={users}
          filterOptions={filterUserOptions}
          getOptionLabel={getOptionLabel}
          onChange={onUserSearchChange}
          onInputChange={onUserSearchInputChange}
        />
        {!_.isEmpty(selectedUsers) && (
          <SelectedUsers users={selectedUsers} removeUser={removeUser} />
        )}

        {isSubmitting && <LinearProgress className={styles.linearProgress} />}
      </>
    </Dialog>
  );
};

export default React.memo(UpdateTeacherTeamDialog);
