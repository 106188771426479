import React from 'react';

import { colors } from 'theme/palette';

import Image from 'components/Image';
import Typography from 'components/Typography';

import NoResultsImage from './assets/no_results.png';
import styles from './styles.module.css';

const NoResultsPlaceholder = ({ button, text = 'No results yet!' }) => (
  <div className={styles.noResults}>
    <Image src={NoResultsImage} className={styles.noResultsImage} />
    <Typography
      variant="H-TEXT-3"
      align="center"
      color={colors.blue3}
      className={styles.noResultsHeader}
    >
      {button}
      {text}
    </Typography>
  </div>
);

export default NoResultsPlaceholder;
