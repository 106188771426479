import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { noopPromise } from 'utils';

import { useFetch } from 'utils/sdk';

import UserContext from 'components/UserContext';

import {
  getDefaultStudentGroupName,
  getSchoolStudents,
  getSchoolUsers,
  getUserSchools
} from './sdk';

export const useDefaultGroupName = (
  initialStudentGroupName = '',
  selectedStudentIds = []
) => {
  const [defaultGroupName, setDefaultGroupName] = useState(
    initialStudentGroupName
  );

  const sdk = useMemo(
    () =>
      _.isEmpty(initialStudentGroupName)
        ? getDefaultStudentGroupName
        : noopPromise,
    [initialStudentGroupName]
  );

  const requestData = useMemo(
    () => ({ students: selectedStudentIds }),
    [selectedStudentIds]
  );

  const { data } = useFetch(sdk, '', requestData);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const defaultName = _.get(data, 'student_group_default_name', '');
      setDefaultGroupName(defaultName);
    }
  }, [data]);

  return [defaultGroupName, setDefaultGroupName];
};

export const useSchools = (initialSchool = null) => {
  const [selectedSchool, setSelectedSchool] = useState(initialSchool);

  const sdk = useMemo(
    () => (initialSchool ? noopPromise : getUserSchools),
    [initialSchool]
  );
  const { data: schools } = useFetch(sdk, { data: [] });

  useEffect(() => {
    if (_.get(schools, 'length', 0) === 1) {
      setSelectedSchool(schools[0]);
    }
  }, [schools]);

  return { selectedSchool, setSelectedSchool, schools };
};

export const useSchoolStudents = (schoolId, initialStudents) => {
  const [selectedStudents, setSelectedStudents] = useState(initialStudents);
  const [studentName, setStudentName] = useState('');

  useEffect(() => {
    if (schoolId && _.isEmpty(initialStudents)) {
      setSelectedStudents([]);
    }
  }, [schoolId, initialStudents]);

  const requestParams = useMemo(
    () => ({ id: schoolId, search: studentName }),
    [schoolId, studentName]
  );

  const sdk = useMemo(
    () => (schoolId ? getSchoolStudents : noopPromise),
    [schoolId]
  );

  const { data: students } = useFetch(sdk, { data: [] }, requestParams);

  const addStudent = useCallback((student) => {
    setSelectedStudents((prev) => [student, ...prev]);
    setStudentName('');
  }, []);
  const removeStudent = useCallback(
    (student) =>
      setSelectedStudents((prev) => _.reject(prev, { id: student.id })),
    []
  );

  return {
    students,
    addStudent,
    removeStudent,
    studentName,
    setStudentName,
    selectedStudents
  };
};

export const useSchoolUsers = (schoolId, initialUsers) => {
  const [selectedUsers, setSelectedUsers] = useState(initialUsers);
  const [userName, setUserName] = useState('');

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (schoolId && _.isEmpty(initialUsers)) {
      setSelectedUsers([user]);
    }
  }, [schoolId, user, initialUsers]);

  const requestParams = useMemo(
    () => ({ id: schoolId, search: userName }),
    [schoolId, userName]
  );

  const sdk = useMemo(
    () => (schoolId ? getSchoolUsers : noopPromise),
    [schoolId]
  );

  const { data: users } = useFetch(sdk, { data: [] }, requestParams);

  const addUser = useCallback((user) => {
    setSelectedUsers((prev) => [user, ...prev]);
    setUserName('');
  }, []);
  const removeUser = useCallback(
    (user) => setSelectedUsers((prev) => _.reject(prev, { id: user.id })),
    []
  );

  return { users, addUser, removeUser, userName, setUserName, selectedUsers };
};
