import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { markAllSessionsAsSeen, markSelectedSessionAsSeen } from './sdk';

export const useSessions = (sessionsList) => {
  const [sessions, setSessions] = useState(sessionsList);

  const removeSelectedSession = useCallback((selectedSessionId) => {
    markSelectedSessionAsSeen(selectedSessionId);

    setSessions((sessions) =>
      _.filter(sessions, (session) => session.id !== selectedSessionId)
    );
  }, []);

  const clearAllSessions = useCallback(() => {
    markAllSessionsAsSeen();
    setSessions([]);
  }, []);

  return {
    sessions,
    removeSelectedSession,
    clearAllSessions
  };
};

export const useRefetchOnLastRowRemoved = (sessions, pageCount, setPage) => {
  useEffect(() => {
    if (_.isEmpty(sessions) && pageCount > 1) {
      setPage(-1); // In order to trigger table refetch
    }
  }, [sessions, pageCount, setPage]);
};
