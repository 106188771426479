import { withSentryRouting } from '@sentry/react';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import {
  LOGIN_URL,
  MAGIC_LOGIN_URL,
  MOBILE_APP_URL,
  SCHOOL_LOGIN_URL,
  STUDENTS_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL,
  TEACHERS_URL,
  TRY_URL,
  WALKTHROUGH_OVERVIEW_URL,
  WALKTHROUGH_URL,
  WEBCAM_PLAYGROUND_URL
} from 'config/urls';
import { createBrowserHistory } from 'history';
import {
  Login,
  MagicLogin,
  MobileApp,
  SchoolLogin,
  StripeTest,
  Students,
  Teachers,
  Walkthrough,
  WebcamPlayground
} from 'pages';

export const history = createBrowserHistory();

const SentryRoute = withSentryRouting(Route);

const TeachersAssignmentsListRedirect = ({ location }) => {
  // Since the <Redirect> component of React Router DOM *does not* receive any history props,
  // we need to wrap in in the `render` prop of the parent `Route` so that we can pass-through
  // query params that were initially passed.
  // Reference: https://stackoverflow.com/questions/51869083/how-preserve-query-string-and-hash-fragment-in-react-router-4-switchredirect
  const { search } = location;

  return (
    <Redirect
      to={{
        search,
        pathname: TEACHERS_ASSIGNMENTS_LIST_URL
      }}
    />
  );
};

class Routes extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <SentryRoute path={MOBILE_APP_URL} component={MobileApp} />
          <SentryRoute path={TEACHERS_URL} component={Teachers} />
          <SentryRoute path={STUDENTS_URL} component={Students} />
          <SentryRoute path={LOGIN_URL} component={Login} />
          <Redirect exact from={WALKTHROUGH_OVERVIEW_URL} to={TRY_URL} />
          <Redirect exact from={WALKTHROUGH_URL} to={TRY_URL} />
          <SentryRoute exact path={TRY_URL} component={Walkthrough} />
          <SentryRoute path={WALKTHROUGH_URL} component={Walkthrough} />
          <SentryRoute
            path={WEBCAM_PLAYGROUND_URL}
            component={WebcamPlayground}
          />
          <SentryRoute path={MAGIC_LOGIN_URL} component={MagicLogin} />
          <SentryRoute path={SCHOOL_LOGIN_URL} component={SchoolLogin} />
          <Route path="/stripe" component={StripeTest} />
          <Route path="*" render={TeachersAssignmentsListRedirect} />
        </Switch>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
      </Router>
    );
  }
}

export default Routes;
