import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';

import Dialog from 'components/Dialog';

import { EditAssignmentForm } from './components';
import styles from './styles.module.css';

const AssignmentEditDialog = ({ assignment, onClose }) => (
  <Dialog
    open
    alignTop
    fullWidth
    maxWidth="md"
    onClose={onClose}
    classes={{ paper: styles.dialog }}
    disableBackdropClick={true}
  >
    <DialogContent>
      <EditAssignmentForm assignment={assignment} onClose={onClose} />
    </DialogContent>
  </Dialog>
);

export default AssignmentEditDialog;
