import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withTheme } from '@material-ui/core/styles';
import { isWidthUp } from '@material-ui/core/withWidth';
import Pagination from '@material-ui/lab/Pagination';
import _ from 'lodash';
import {
  useAssignmentsNamesList,
  useTrackersOverview,
  useUserSections,
  useUsersInUserSections,
  useYears
} from 'sdk';

import { parseParams, stringifyParams } from 'utils/urls';

import AssignmentDialogContext from 'components/AssignmentDialogContext';
import UserContext from 'components/UserContext';

import NoAssignmentsImg from './assets/no_assignments.gif';
import { AssignmentRow, Filters, SkeletonRow } from './components';
import styles from './styles.module.scss';

const DEFAULT_LIMIT = 10;

const OverviewTable = ({
  history,
  history: {
    location: { search }
  },
  width,
  theme: {
    palette: {
      common: { colors }
    }
  }
}) => {
  const { user } = useContext(UserContext);
  const assignmentDialogContext = useContext(AssignmentDialogContext);

  const [filtersHaveBeenInitialized, setFiltersHaveBeenInitialized] =
    useState(false);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    numPages: 1,
    currentPage: 1,
    limit: DEFAULT_LIMIT
  });

  const author = _.get(filters, 'author');

  const trackersOverviewFilters = useMemo(() => {
    let offset = undefined;

    if (!_.isNil(pagination.limit)) {
      offset = pagination.limit * (pagination.currentPage - 1);
    }

    return _.omit({ ...filters, offset }, _.isNil);
  }, [filters, pagination]);

  const { data: sectionUsers, isLoading: isLoadingSectionUsers } =
    useUsersInUserSections();
  const { data: years, isLoading: isLoadingYears } = useYears();
  const { data: userSections, isLoading: isLoadingUserSections } =
    useUserSections({
      params: author ? { co_user: author } : {}
    });
  const { data: userAssignments, isLoading: isLoadingUserAssignments } =
    useAssignmentsNamesList({
      data: author ? { co_user: author } : {}
    });

  const {
    data: trackersOverviewData,
    isLoading: isLoadingTrackersOverview,
    refetch: refetchTrackersOverview
  } = useTrackersOverview({
    enabled: filtersHaveBeenInitialized,
    params: trackersOverviewFilters
  });

  const refetchTrackerOverviewCallback = useCallback(
    () => refetchTrackersOverview(),
    [refetchTrackersOverview]
  );

  useEffect(() => {
    if (assignmentDialogContext.isAssignmentDialogOpened) {
      assignmentDialogContext.setAssignAssignmentSuccessAction(
        () => refetchTrackerOverviewCallback
      );
    }
  }, [assignmentDialogContext, refetchTrackerOverviewCallback]);

  const loading = _.some([
    isLoadingSectionUsers,
    isLoadingYears,
    isLoadingUserSections,
    isLoadingUserAssignments,
    isLoadingTrackersOverview
  ]);

  const count = _.get(trackersOverviewData, 'count');
  const limit = _.get(trackersOverviewData, 'limit', DEFAULT_LIMIT);
  const trackerListData = _.get(trackersOverviewData, 'results');

  useEffect(() => {
    let numPages = 1;

    if (!_.isNil(count)) {
      numPages = _.ceil(count / limit);
    }

    setPagination((prevPagination) => ({
      ...prevPagination,
      numPages,
      limit
    }));
  }, [count, limit]);

  useEffect(() => {
    if (isLoadingYears || filtersHaveBeenInitialized) {
      return;
    }

    const filters = parseParams(search, { parseNumbers: true });
    let author = _.get(filters, 'author');

    // In case when author is completely missing or is not fully provided (e.g. `?author=`)
    if (!_.isInteger(author)) {
      const hasNonAuthorFilter = _.chain(filters)
        .omit('author')
        .values()
        .some((filterValue) => !_.isEmpty(filterValue))
        .value();

      // If any of assignments or sections filters are not empty => set author to null
      if (hasNonAuthorFilter) {
        author = null;
      } else {
        author = user.id;
      }
    }

    const name = _.get(filters, 'name');
    const section = _.get(filters, 'section');

    const currentYear = _.last(_.sortBy(years, 'is_current'));
    const year = _.get(filters, 'year', _.get(currentYear, 'id'));

    setFiltersHaveBeenInitialized(true);
    setFilters((prevFilters) => ({
      ...prevFilters,
      author,
      name,
      section,
      year
    }));
  }, [search, user.id, years, isLoadingYears, filtersHaveBeenInitialized]);

  const sectionUsersForSearch = useMemo(() => {
    // Explicitly put `this.props.user` on top of the `sectionUsers` filter list
    const currentSectionUser = _.find(sectionUsers, { id: user.id });

    const sectionUsersWithoutCurrentUser = _.filter(
      sectionUsers,
      (u) => u.id !== user.id
    );

    return [currentSectionUser, ...sectionUsersWithoutCurrentUser];
  }, [sectionUsers, user.id]);

  useEffect(() => {
    if (!loading) {
      history.replace({
        search: stringifyParams(filters)
      });
    }
  }, [filters, loading, history]);

  const filterByAuthor = (e, user) =>
    setFilters((prevFilters) => ({
      ...prevFilters,
      author: _.get(user, 'id')
    }));

  const filterByYear = (year) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      year
    }));
  };
  const filterBySectionName = (e, section) =>
    setFilters((prevFilters) => ({
      ...prevFilters,
      section
    }));

  const filterByAssignmentName = (e, name) =>
    setFilters((prevFilters) => ({
      ...prevFilters,
      name
    }));

  const changePageAndRefetchListData = (page) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: page
    }));
  };

  useEffect(() => {
    // Reset the pagination every time the filters change.
    changePageAndRefetchListData(1);
  }, [filters]);

  const selectedUser = _.find(sectionUsers, { id: filters.author }) || null;
  const lgScreen = isWidthUp('lg', width);
  const { numPages, currentPage } = pagination;

  return (
    <div className={styles.overviewContainer}>
      <Filters
        user={user}
        filters={filters}
        selectedUser={selectedUser}
        authorOptions={sectionUsersForSearch}
        filterByAuthor={filterByAuthor}
        assignmentsOptions={userAssignments}
        filterByAssignmentName={filterByAssignmentName}
        sectionsOptions={userSections}
        filterBySectionName={filterBySectionName}
        yearsOptions={years}
        filterByYear={filterByYear}
      />
      <Paper variant="outlined">
        {!loading && _.isEmpty(trackerListData) && (
          <img
            src={NoAssignmentsImg}
            alt="No assignments yet. Create one."
            className={styles.noAssignmentsImage}
            onClick={() =>
              assignmentDialogContext.setIsAssignmentDialogOpened(true)
            }
          />
        )}

        <Grid container>
          {loading && <SkeletonRow lgScreen={lgScreen} />}

          {!loading &&
            _.map(trackerListData, (trackerInstance, index) => (
              <AssignmentRow
                key={index}
                width={width}
                colors={colors}
                trackerInstance={trackerInstance}
              />
            ))}
        </Grid>

        {!loading && numPages > 1 && (
          <Grid item xs={12}>
            <Pagination
              className={styles.pagination}
              count={numPages}
              page={currentPage}
              color="secondary"
              onChange={(event, page) => changePageAndRefetchListData(page)}
            />
          </Grid>
        )}
      </Paper>
    </div>
  );
};

export default withRouter(withTheme(OverviewTable));
