import React, { useCallback, useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { FieldArray, Formik } from 'formik';
import { ReactComponent as TrashIcon } from 'images/trashicon.svg';
import _ from 'lodash';
import { getStandardButtonLabel } from 'utils';

import { AssignmentDialogModes } from 'pages/Teachers/shared/AssignmentDialog';
import { dialogValidationSchema } from 'pages/Teachers/shared/AssignmentDialog/utils';
import AttachmentDropzone from 'pages/Teachers/shared/AttachmentDropzone';
import AttachmentList from 'pages/Teachers/shared/AttachmentList';
import AttachmentUploadOverlay from 'pages/Teachers/shared/AttachmentUploadOverlay';
import MaterialsButton from 'pages/Teachers/shared/MaterialsButton';
import TasksForm from 'pages/Teachers/shared/TasksForm';
import { colors } from 'theme/palette';
import { buildFormDataForUpdate } from 'utils/assignments';
import { setFormErrors } from 'utils/forms';
import { useDialog } from 'utils/hooks';
import { notifySuccess } from 'utils/notifications';

import Button from 'components/Button';
import DebouncedField from 'components/DebouncedField';
import EmojiInputField from 'components/EmojiInputField';
import InputField from 'components/InputField';
import StandardButton from 'components/StandardButton';
import StandardDialog from 'components/StandardDialog';
import Typography from 'components/Typography';

import { trackerUpdate } from './sdk';
import styles from './styles.module.scss';

const EditAssignmentForm = ({ assignment, onClose }) => {
  const [initialValues, setInitialValues] = useState();

  const {
    isOpened: isStandardDialogOpened,
    closeDialog: closeStandardDialog,
    openDialog: openStandardDialog
  } = useDialog();

  useEffect(() => {
    if (!initialValues) {
      setInitialValues({
        assignmentName: assignment.name,
        description: assignment.description,
        tasks: assignment.tasks,
        attachments: assignment.attachments
      });
    }
  }, [assignment, initialValues]);

  const onSubmit = useCallback(
    async ({ values, setFieldError }) => {
      const formData = buildFormDataForUpdate({ values });

      const { success, errors } = await trackerUpdate(assignment.id, formData);

      if (success) {
        notifySuccess('Assignment succesfully updated.');
        onClose();
      } else {
        setFormErrors(errors, setFieldError);
      }
    },
    [assignment.id, onClose]
  );

  const onStandardsSelected = ({
    selectedStandards,
    formikTasks,
    setFieldValue
  }) => {
    formikTasks.forEach((task, taskIndex) => {
      const objectiveStandardsFieldName = `tasks.${taskIndex}.standards`;

      setFieldValue(objectiveStandardsFieldName, selectedStandards);
    });
  };

  const TasksFormComponent = (props) => <TasksForm {...props} isAssigned />;

  return initialValues ? (
    <div className={styles.container}>
      <Formik
        enableReinitialize
        validationSchema={dialogValidationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setFieldError }) =>
          onSubmit({ values, setFieldError })
        }
      >
        {({
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          values,
          setFieldValue
        }) => {
          const assignmentStandards = _.chain(values.tasks)
            .map('standards')
            .flatten()
            .uniqBy('id')
            .value();

          return (
            <AttachmentDropzone
              mode={AssignmentDialogModes.UPDATE}
              setFieldValue={setFieldValue}
              initialAttachments={values.attachments}
            >
              {({ attachments, onRemove, isDragActive, openFileDialog }) => (
                <>
                  {isDragActive && <AttachmentUploadOverlay />}
                  <Grid
                    container
                    alignItems="center"
                    className={styles.actionButtons}
                  >
                    <Grid xs={4} item>
                      <Typography variant="H-TEXT-2" color={colors.blue1}>
                        Edit assignment
                      </Typography>
                    </Grid>
                    <Grid xs={8} item>
                      <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                          <Button
                            color="lightGrey"
                            disableElevation
                            onClick={onClose}
                            startIcon={<TrashIcon />}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            color="pink"
                            disabled={
                              isSubmitting || !dirty || !_.isEmpty(errors)
                            }
                            onClick={handleSubmit}
                            disableElevation
                            endIcon={<ChevronRightRoundedIcon />}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <DebouncedField
                    value={values.assignmentName}
                    component={EmojiInputField}
                    hideEmojiTogglerOnInactiveInput
                    autoFocus
                    fullWidth
                    onChange={(value) => setFieldValue('assignmentName', value)}
                    error={!_.isNil(errors.assignmentName)}
                    helperText={errors.assignmentName}
                    type="text"
                    placeholder="Assignment Name"
                    variant="underlined"
                    classes={{ root: styles.name }}
                    EmojiButtonProps={{ tabIndex: '-1' }}
                  />
                  <DebouncedField
                    value={values.description}
                    component={InputField}
                    multiline
                    fullWidth
                    minRows={1}
                    onChange={(value) => setFieldValue('description', value)}
                    error={!_.isNil(errors.description)}
                    helperText={errors.description}
                    type="text"
                    placeholder="Optional Description..."
                    variant="underlined"
                    classes={{ root: styles.description }}
                  />
                  <StandardButton
                    disabled={values.tasks.length === 0}
                    label={getStandardButtonLabel({
                      standards: assignmentStandards
                    })}
                    blue={assignmentStandards.length > 0}
                    onClick={openStandardDialog}
                    className={styles.standardButton}
                  />

                  <MaterialsButton
                    className={styles.materialsButton}
                    onClick={openFileDialog}
                  />

                  <AttachmentList
                    attachments={attachments}
                    onRemove={onRemove}
                  />

                  <FieldArray name="tasks" component={TasksFormComponent} />
                  {isStandardDialogOpened && (
                    <StandardDialog
                      selectedItems={assignmentStandards}
                      onClose={closeStandardDialog}
                      onSave={({ selectedStandards }) =>
                        onStandardsSelected({
                          selectedStandards,
                          formikTasks: values.tasks,
                          setFieldValue
                        })
                      }
                    />
                  )}
                </>
              )}
            </AttachmentDropzone>
          );
        }}
      </Formik>
    </div>
  ) : null;
};

export default EditAssignmentForm;
