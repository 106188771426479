import React from 'react';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import styles from './styles.module.css';

const MeetingInfoBoxSkeleton = () => (
  <Paper variant="outlined" className={styles.container}>
    <div>
      <Skeleton animation="wave" width={120} style={{ marginBottom: 24 }} />
      <div className={styles.infoRow}>
        <Skeleton animation="wave" width={70} style={{ marginBottom: 24 }} />
        <Skeleton
          animation="wave"
          width={90}
          height={30}
          style={{ marginBottom: 24, marginLeft: '20px' }}
        />
        <Skeleton
          animation="wave"
          width={90}
          height={30}
          style={{ marginBottom: 24, marginLeft: '20px' }}
        />
      </div>
      <div className={styles.infoRow}>
        <Skeleton animation="wave" width={70} />
        <Skeleton
          animation="wave"
          width={90}
          height={30}
          style={{ marginLeft: '20px' }}
        />
        <Skeleton
          animation="wave"
          width={90}
          height={30}
          style={{ marginLeft: '20px' }}
        />
        <Skeleton
          animation="wave"
          width={90}
          height={30}
          style={{ marginLeft: '20px' }}
        />
      </div>
    </div>
    <Divider className={styles.divider} />
    <div>
      <Skeleton
        variant="text"
        animation="wave"
        width={70}
        style={{ marginBottom: 16 }}
      />
      <div className={styles.notes}>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
        <div className={styles.notesContent}>
          <Skeleton
            animation="wave"
            height={15}
            width="40%"
            style={{ marginBottom: 8 }}
          />
          <Skeleton animation="wave" height={15} width="60%" />
        </div>
      </div>
      <Skeleton
        variant="text"
        animation="wave"
        width="100%"
        height={40}
        style={{ marginTop: 16 }}
      />
    </div>
  </Paper>
);

export default MeetingInfoBoxSkeleton;
