import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TEACHERS_STUDENTS_LIST } from 'config/urls';
import _ from 'lodash';
import {
  getStandards,
  studentPortfolioTriggerAssignmentsExport,
  studentPortfolioTriggerAssignmentsWithStandardsExport,
  useStandardsForStudentPortfolio,
  useStudent
} from 'sdk';

import {
  INCOMPLETE_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION,
  STUDENT_PORTFOLIO_STANDARD_TABS,
  SUBMITTED_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION
} from 'utils/constants';
import { buildFilters } from 'utils/filters';
import { useFetch } from 'utils/sdk';

import ExportAndPrintMenu from './components/ExportAndPrintMenu';
import Filters from './components/Filters';
import FiltersSkeleton from './components/FiltersSkeleton';

import {
  DateView,
  IncompleteAssignmentsView,
  StandardTabs,
  StandardsView
} from './components';
import { VIEW_MODES } from './constants';
import styles from './styles.module.scss';
import {
  getFiltersObject,
  prepareAssignmentFilters,
  useDefaultFilters,
  useFilters,
  useInfiniteScroll,
  useInitialFilters,
  useNavigation,
  useStudentAssignments,
  validateFilters
} from './utils';

const StudentsPortfolio = ({ match, history }) => {
  const studentId = match.params.id;
  const { data: student } = useStudent({ studentId });
  const [filters, setFilters] = useFilters(history);

  const [viewMode, setViewMode] = useState(VIEW_MODES.STANDARDS);
  const [activeStandardTab, setActiveStandardTab] = useState(
    localStorage.getItem('lastlyOpenedStandardsTab') ||
      STUDENT_PORTFOLIO_STANDARD_TABS.MATH
  );

  const mainInitialFilters = useInitialFilters();

  const incompleteViewInitialFilters = useMemo(
    () => ({
      ...mainInitialFilters,
      status: INCOMPLETE_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION.value
    }),
    [mainInitialFilters]
  );

  const defaultFilters = useDefaultFilters(history);

  const standardFilters = useMemo(() => {
    if (validateFilters(filters)) {
      if (
        activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.MATH ||
        activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.ELA
      ) {
        return {
          ...buildFilters(prepareAssignmentFilters(filters)),
          standard: activeStandardTab
        };
      }
      return buildFilters(prepareAssignmentFilters(filters));
    }
    return null;
  }, [filters, activeStandardTab]);

  useEffect(() => {
    setFilters((prevFilters) => ({ ...defaultFilters, ...prevFilters }));
  }, [defaultFilters, setFilters]);

  const {
    data: standards,
    isLoading: standardsLoading,
    refetch: refetchStandardsForStudentPortfolio
  } = useStandardsForStudentPortfolio({
    studentId,
    filters: standardFilters
  });

  const params = useMemo(
    () =>
      buildFilters({
        'standard_ids[]': filters.standards
      }),
    [filters.standards]
  );

  const { data: standardsForFilters, loading: standardsForFiltersLoading } =
    useFetch(getStandards, { data: { results: [] } }, params);

  const assignmentFilters = useMemo(() => {
    /*
     We would like to rebuild the filters and refetch the assignments:
     1. Once filters are changed
     2. And we're on the NO_STANDARDS tab in the standard view

     OR

     1. Once filters are changed
     2. And we're on the DATE view

          OR

     1. Once filters are changed
     2. And we're on the INCOMPLETE_ASSIGNMENTS view

    */
    const rebuildFilters =
      (viewMode === VIEW_MODES.STANDARDS &&
        activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS) ||
      viewMode === VIEW_MODES.DATE ||
      viewMode === VIEW_MODES.INCOMPLETE_ASSIGNMENTS;

    if (validateFilters(filters) && rebuildFilters) {
      return {
        studentId,
        filters: {
          ...buildFilters(prepareAssignmentFilters(filters)),
          no_standards:
            viewMode === VIEW_MODES.STANDARDS &&
            activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS
        }
      };
    }
    return null;
  }, [studentId, filters, viewMode, activeStandardTab]);

  const [studentAssignments, loadNextPage, resetScroll] = useInfiniteScroll(
    useStudentAssignments,
    assignmentFilters
  );

  const handleSettingFilters = useCallback(
    (filtersOrCallback) => {
      setFilters((filters) => {
        const newFilters = getFiltersObject(filtersOrCallback, filters);

        if (validateFilters(newFilters)) {
          if (!_.isEqual(filters, newFilters)) {
            resetScroll({ withLoading: true });
            return newFilters;
          }
        }
        return filters;
      });
    },
    [setFilters, resetScroll]
  );

  const handleTitleClick = useCallback(
    () => history.push(TEACHERS_STUDENTS_LIST),
    [history]
  );

  const hangleSetViewMode = useCallback(
    (viewMode) => {
      if (viewMode === VIEW_MODES.INCOMPLETE_ASSIGNMENTS) {
        handleSettingFilters({
          ...filters,
          status:
            INCOMPLETE_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION.value
        });
      }

      if (viewMode === VIEW_MODES.STANDARDS || viewMode === VIEW_MODES.DATE) {
        handleSettingFilters({
          ...filters,
          status:
            SUBMITTED_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION.value
        });
      }

      setViewMode(viewMode);
    },
    [filters, handleSettingFilters]
  );

  useNavigation({
    studentName: _.get(student, 'name'),
    onTitleClick: handleTitleClick,
    viewMode: viewMode,
    setViewMode: hangleSetViewMode
  });

  useEffect(() => {
    if (
      viewMode === VIEW_MODES.STANDARDS &&
      (activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.ELA ||
        activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.MATH)
    ) {
      // Refetch the standards whenever we switch between the standards tabs
      refetchStandardsForStudentPortfolio();
    }
    // eslint-disable-next-line
  }, [viewMode, filters, activeStandardTab]);

  useEffect(() => {
    if (
      (viewMode === VIEW_MODES.STANDARDS &&
        activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS) ||
      viewMode === VIEW_MODES.DATE ||
      viewMode === VIEW_MODES.INCOMPLETE_ASSIGNMENTS
    ) {
      // Reset the infinite scroll, so that we do not see duplicated results
      resetScroll({ withLoading: true });
    }
  }, [viewMode, activeStandardTab, resetScroll]);

  const assignmentsCount = useMemo(() => {
    if (
      (viewMode === VIEW_MODES.STANDARDS &&
        activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS) ||
      viewMode === VIEW_MODES.DATE ||
      viewMode === VIEW_MODES.INCOMPLETE_ASSIGNMENTS
    ) {
      return studentAssignments.data.results.length;
    }

    const assignments = _.uniqBy(
      _.flatten(_.map(standards, 'assignments')),
      'id'
    );

    return assignments.length;
  }, [viewMode, activeStandardTab, standards, studentAssignments]);

  const nestedStandardsViewSelected =
    viewMode === VIEW_MODES.STANDARDS &&
    (activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.MATH ||
      activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.ELA);

  const dataIsLoading = useMemo(() => {
    if (
      (viewMode === VIEW_MODES.STANDARDS &&
        activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS) ||
      viewMode === VIEW_MODES.DATE ||
      viewMode === VIEW_MODES.INCOMPLETE_ASSIGNMENTS
    ) {
      return studentAssignments.loading;
    } else {
      return standardsLoading || standardsForFiltersLoading;
    }
  }, [
    viewMode,
    standardsLoading,
    activeStandardTab,
    standardsForFiltersLoading,
    studentAssignments.loading
  ]);

  const setActiveTab = (activeTab) => {
    localStorage.setItem('lastlyOpenedStandardsTab', activeTab);

    setActiveStandardTab(activeTab);
  };

  const initialFilters =
    viewMode === VIEW_MODES.INCOMPLETE_ASSIGNMENTS
      ? incompleteViewInitialFilters
      : mainInitialFilters;

  return (
    <>
      <div className={styles.filtersAndTabsContainer}>
        <div className={styles.filters}>
          {dataIsLoading || _.isNil(student) ? (
            <FiltersSkeleton
              student={student}
              renderStandardsFilter={nestedStandardsViewSelected}
            />
          ) : (
            <Filters
              initialFilters={initialFilters}
              filters={filters}
              student={student}
              standards={standardsForFilters}
              setFilters={handleSettingFilters}
              standardFilters={standardFilters}
              assignmentsCount={assignmentsCount}
              renderStandardsFilter={nestedStandardsViewSelected}
            />
          )}
          <ExportAndPrintMenu
            studentId={studentId}
            exportAction={
              nestedStandardsViewSelected
                ? studentPortfolioTriggerAssignmentsWithStandardsExport
                : studentPortfolioTriggerAssignmentsExport
            }
            filters={
              nestedStandardsViewSelected
                ? standardFilters
                : buildFilters(prepareAssignmentFilters(filters))
            }
          />
        </div>

        {viewMode === VIEW_MODES.STANDARDS && (
          <div>
            <StandardTabs
              activeTab={activeStandardTab}
              setActiveTab={setActiveTab}
            />
          </div>
        )}
      </div>
      <div className={styles.viewContainer}>
        {viewMode === VIEW_MODES.STANDARDS && (
          <div className={styles.standardsViewContainer}>
            <StandardsView
              standards={standards}
              filters={filters}
              initialFilters={mainInitialFilters}
              standardsLoading={standardsLoading}
              student={student}
              activeStandardTab={activeStandardTab}
              studentAssignments={studentAssignments}
              loadNextPage={loadNextPage}
            />
          </div>
        )}

        {viewMode === VIEW_MODES.DATE && (
          <DateView
            studentAssignments={studentAssignments}
            student={student}
            loadNextPage={loadNextPage}
          />
        )}

        {viewMode === VIEW_MODES.INCOMPLETE_ASSIGNMENTS && (
          <div className={styles.incompleteAssignmentsContainer}>
            <IncompleteAssignmentsView
              isLoading={studentAssignments.loading}
              assignments={studentAssignments.data.results}
              hasMore={!_.isNil(studentAssignments.data.next)}
              fetchMore={loadNextPage}
              studentId={studentId}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default StudentsPortfolio;
