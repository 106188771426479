import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import {
  TEACHERS_ASSIGNMENTS_DETAIL_URL,
  TEACHERS_ASSIGNMENTS_FEEDBACK_URL,
  TEACHERS_STUDENT_GROUP_URL
} from 'config/urls';
import _ from 'lodash';
import { useAssignmentResults } from 'sdk';
import { useAssignmentStudentWork } from 'sdk';

import { fillEmptyStudentWork } from 'pages/Teachers/Assignments/Results/utils';
import { CreateStudentGroupDialog } from 'pages/Teachers/StudentGroupList/components';
import { useDialog } from 'utils/hooks';
import { useMixpanelPageOpenTrack } from 'utils/integrations/mixpanel';
import { openInNewTab, reverse, stringifyParams } from 'utils/urls';

import AccordionSummary from 'components/AccordionSummary';
import UserContext from 'components/UserContext';

import {
  AssignStudentGroupDialog,
  AssignmentStatistics,
  AssignmentSummary,
  Filters,
  PageSkeleton,
  ShareToMeetingDialog,
  StudentWorkList
} from './components';
import {
  useAccordion,
  useActiveTasksIds,
  useFilters,
  useNavigation,
  useOrdering,
  usePageTooltips,
  usePulseEffect,
  usePusherManager,
  useSections,
  useVisibleStudentWork
} from './hooks';
import styles from './styles.module.scss';
import {
  addCommentBulk as addCommentBulkUtil,
  addComment as addCommentUtil,
  addSingleNewWork,
  addStickersBulk as addStickersBulkUtil,
  changeTaskStatus as changeTaskStatusUtil,
  changeTasksScoresBulk as changeTasksScoresBulkUtil,
  changeTasksStatusesBulk as changeTasksStatusesBulkUtil,
  handleNewStudentWorkWebhookCallback as handleNewStudentWorkWebhookCallbackUtil,
  updateWorkItems
} from './utils';

const TeacherAssignmentResults = ({ history, match, width }) => {
  const { user } = useContext(UserContext);
  const trackerId = useMemo(() => match.params.trackerId, [match]);
  useMixpanelPageOpenTrack('Teacher opened Results page');

  const [selectedSection, selectSection, sections, loadingSections] =
    useSections(trackerId);
  const selectedSectionId = useMemo(
    () => _.get(selectedSection, 'id'),
    [selectedSection]
  );

  /* 
    selectedWorksSelectedChildren = {
      taskId: activeWorkId,
      taskId2: activeWorkId2,
      taskId3: activeWorkId3,
      ...
    }
  */
  const [selectedWorksSelectedChildren, setSelectedWorksSelectedChildren] =
    useState({});
  const [studentWork, setStudentWork] = useState([]);

  const {
    data: assignment,
    mutate: setAssignment,
    refetch: refetchAssignment,
    isLoading: loadingAssignment
  } = useAssignmentResults(selectedSectionId);
  const {
    data: studentWorkData,
    isLoading: loadingStudentWork,
    refetch: refetchStudentWork
  } = useAssignmentStudentWork({ assignmentId: selectedSectionId });

  useEffect(() => {
    setStudentWork(fillEmptyStudentWork(studentWorkData));
  }, [studentWorkData]);

  const [filters, setFilters] = useFilters();
  const [ordering, setOrdering] = useOrdering();

  const [
    multiSelectTooltipOpen,
    onMultiSelectTooltipClick,
    tutoringTooltipOpen,
    onTutoringTooltipClick,
    shareTooltipOpen,
    onShareTooltipClick
  ] = usePageTooltips();

  const [activeTasksIds, storeActiveTaskId, removeActiveTaskId] =
    useActiveTasksIds();

  const [
    visibleStudentWork,
    toggleSelectStudentWorkItem,
    toggleSelectAllVisibleStudentWork
  ] = useVisibleStudentWork(
    studentWork,
    filters,
    ordering,
    !multiSelectTooltipOpen && !shareTooltipOpen && !tutoringTooltipOpen,
    activeTasksIds
  );

  const selectedStudentWorkList = useMemo(
    () => _.filter(visibleStudentWork, 'selected'),
    [visibleStudentWork]
  );

  const selectedStudentWork = useMemo(
    () =>
      _(selectedStudentWorkList)
        .map('work')
        .flatten()
        .filter('selected')
        .value(),
    [selectedStudentWorkList]
  );

  useNavigation({
    assignment,
    selectedSectionId,
    sections,
    selectSection
  });

  const handleNewStudentWorkWebhookCallback = useCallback(
    (newStudentWorkIds) => {
      handleNewStudentWorkWebhookCallbackUtil(
        { newStudentWorkIds },
        { studentWork }
      ).then((newStudentWork) => {
        if (newStudentWork) {
          setStudentWork(newStudentWork);
        }
      });
    },
    [studentWork, setStudentWork]
  );

  usePusherManager(selectedSectionId, handleNewStudentWorkWebhookCallback);

  const [isAccordionOpen, toggleAccordion] = useAccordion();

  const [pulseEffectActive, startPulseEffect, stopPulseEffect] =
    usePulseEffect();

  const buildAssignmentFeedbackLinkObject = useCallback(
    ({ student, work, task }) => ({
      pathname: reverse(TEACHERS_ASSIGNMENTS_FEEDBACK_URL, {
        trackerId: trackerId
      }),
      search: stringifyParams({
        section: selectedSectionId,
        student,
        task,
        work
      })
    }),
    [selectedSectionId, trackerId]
  );

  const goToAssignmentDetail = useCallback(
    () =>
      history.push(
        reverse(TEACHERS_ASSIGNMENTS_DETAIL_URL, {
          trackerId: trackerId
        })
      ),
    [history, trackerId]
  );

  const [studentGroup, setStudentGroup] = useState(null);

  const exportResults = useCallback(
    () => openInNewTab(assignment.csv_url),
    [assignment]
  );

  const addComment = useCallback(
    ({ studentId, taskId, data }) => {
      addCommentUtil({ studentId, taskId, data }, { studentWork }).then(
        setStudentWork
      );
    },
    [studentWork, setStudentWork]
  );

  const addCommentBulk = useCallback(
    (text) => {
      const newStudentWork = addCommentBulkUtil(
        { text },
        {
          selectedResponses: _.map(
            selectedStudentWork,
            'tracker_score_response_id'
          ),
          user,
          studentWork
        }
      );
      setStudentWork(newStudentWork);
      startPulseEffect();
    },
    [selectedStudentWork, user, studentWork, setStudentWork, startPulseEffect]
  );

  const updateWorkItemsInState = useCallback(
    (newWorkList) => {
      const newStudentWork = updateWorkItems({ newWorkList }, { studentWork });
      setStudentWork(newStudentWork);
    },
    [studentWork, setStudentWork]
  );

  const addStickersBulk = useCallback(
    (stickerUrl) => {
      addStickersBulkUtil(
        { stickerUrl },
        {
          selectedResponses: _.map(
            selectedStudentWork,
            'tracker_score_response_id'
          ),
          studentWork,
          updateWorkItemsInState,
          selectedWorksSelectedChildren
        }
      );
    },
    [
      selectedStudentWork,
      studentWork,
      updateWorkItemsInState,
      selectedWorksSelectedChildren
    ]
  );

  const changeTaskStatus = useCallback(
    (trackerScoreResponseId, studentId, status) => {
      changeTaskStatusUtil(
        { trackerScoreResponseId, studentId, status },
        { studentWork }
      ).then((newStudentWork) => {
        if (newStudentWork) {
          setStudentWork(newStudentWork);
        }
      });
    },
    [studentWork, setStudentWork]
  );

  const onAddSticker = useCallback(
    (studentId, activeTaskId, newWork) => {
      const newStudentWork = addSingleNewWork(
        { studentId, activeTaskId, newWork },
        { studentWork }
      );
      setStudentWork(newStudentWork);
    },
    [studentWork, setStudentWork]
  );

  const changeTasksStatusesBulk = useCallback(
    (status) => {
      changeTasksStatusesBulkUtil(
        { status },
        {
          selectedResponses: _.map(
            selectedStudentWork,
            'tracker_score_response_id'
          ),
          selectedSectionId,
          studentWork
        }
      ).then((newStudentWork) => {
        if (newStudentWork) {
          setStudentWork(newStudentWork);
          startPulseEffect();
        }
      });
    },
    [
      selectedStudentWork,
      selectedSectionId,
      studentWork,
      setStudentWork,
      startPulseEffect
    ]
  );

  const changeTasksScoresBulk = useCallback(
    (scoreValue) => {
      changeTasksScoresBulkUtil(
        { scoreValue },
        {
          selectedResponses: _.map(
            selectedStudentWork,
            'tracker_score_response_id'
          ),
          studentWork,
          trackerScoreResponses: _.get(
            assignment,
            'tracker_score_responses',
            []
          )
        }
      ).then((data) => {
        if (!data) {
          return;
        }
        setStudentWork(data.newStudentWork);
        setAssignment({
          ...assignment,
          tracker_score_responses: data.newTrackerScoreResponses
        });
        startPulseEffect();
      });
    },
    [
      selectedStudentWork,
      studentWork,
      assignment,
      setStudentWork,
      setAssignment,
      startPulseEffect
    ]
  );

  const {
    isOpened: isAssignStudentGroupDialogOpen,
    openDialog: openAssignStudentGroupDialog,
    closeDialog: closeAssignStudentGroupDialog,
    dialogData: assignStudentGroupDialogData,
    setDialogData: setAssignStudentGroupDialogData
  } = useDialog();

  const {
    isOpened: isCreateStudentGroupDialogOpen,
    openDialog: openCreateStudentGroupDialog,
    closeDialog: closeCreateStudentGroupDialog,
    dialogData: createStudentGroupDialogData,
    setDialogData: setCreateStudentGroupDialogData
  } = useDialog();

  const {
    isOpened: isShareToMeetingDialogOpen,
    openDialog: openShareToMeetingDialog,
    closeDialog: closeShareToMeetingDialog,
    dialogData: shareToMeetingDialogData,
    setDialogData: setShareToMeetingDialogData
  } = useDialog();

  const goToStudentGroupDetail = useCallback(
    (id) => history.push(reverse(TEACHERS_STUDENT_GROUP_URL, { id })),
    [history]
  );

  const openAssignStudentGroupDialogCallback = useCallback(
    (students) => {
      setAssignStudentGroupDialogData(students);
      openAssignStudentGroupDialog();
    },
    [openAssignStudentGroupDialog, setAssignStudentGroupDialogData]
  );
  const openCreateStudentGroupDialogCallback = useCallback(
    (students) => {
      setCreateStudentGroupDialogData(students);
      openCreateStudentGroupDialog();
    },
    [openCreateStudentGroupDialog, setCreateStudentGroupDialogData]
  );
  const openShareToMeetingDialogCallback = useCallback(
    (studentsData) => {
      setShareToMeetingDialogData(studentsData);
      openShareToMeetingDialog();
    },
    [openShareToMeetingDialog, setShareToMeetingDialogData]
  );

  const onStudentGroupCreate = useCallback(
    ({ studentGroup }) => {
      setStudentGroup(studentGroup);
      openAssignStudentGroupDialogCallback(studentGroup.students);
    },
    [openAssignStudentGroupDialogCallback]
  );

  const lgScreen = isWidthUp('lg', width);

  if (
    loadingSections ||
    loadingStudentWork ||
    loadingAssignment ||
    _.isNil(assignment)
  ) {
    return <PageSkeleton lgScreen={lgScreen} />;
  }

  return (
    <>
      <div className={styles.headerContainer}>
        <Filters
          filters={filters}
          ordering={ordering}
          setFilters={setFilters}
          setOrdering={setOrdering}
          selectedSectionId={selectedSectionId}
          studentWork={studentWork}
        />
      </div>
      <div className={styles.paperContainer}>
        <Accordion
          square={!lgScreen}
          variant="outlined"
          expanded={isAccordionOpen}
          className={styles.panel}
        >
          <AccordionSummary
            classes={{
              root: styles.panelSummary,
              expanded: styles.withBorder,
              content: styles.noBorder
            }}
            IconButtonProps={{
              size: 'small',
              onClick: toggleAccordion
            }}
          >
            <AssignmentSummary
              assignment={assignment}
              filters={filters}
              isAccordionOpen={isAccordionOpen}
              selectedStudentWork={selectedStudentWork}
              selectedStudentWorkList={selectedStudentWorkList}
              goToAssignmentDetail={goToAssignmentDetail}
              onSessionUpdateSuccess={refetchAssignment}
              exportResults={exportResults}
              toggleAccordion={toggleAccordion}
              multiSelectTooltipOpen={multiSelectTooltipOpen}
              onMultiSelectTooltipClick={onMultiSelectTooltipClick}
              addCommentBulk={addCommentBulk}
              addStickersBulk={addStickersBulk}
              changeTasksStatusesBulk={changeTasksStatusesBulk}
              changeTasksScoresBulk={changeTasksScoresBulk}
              studentDeeplink={_.get(selectedSection, 'student_deeplink')}
              shareTooltipOpen={shareTooltipOpen}
              onShareTooltipClick={onShareTooltipClick}
              tutoringTooltipOpen={tutoringTooltipOpen}
              onTutoringTooltipClick={onTutoringTooltipClick}
              openAssignStudentGroupDialog={
                openAssignStudentGroupDialogCallback
              }
              toggleSelectAllVisibleStudentWork={
                toggleSelectAllVisibleStudentWork
              }
              openShareToMeetingDialog={openShareToMeetingDialogCallback}
            />
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: styles.accordionDetails
            }}
          >
            <AssignmentStatistics assignment={assignment} filters={filters} />
          </AccordionDetails>
        </Accordion>
      </div>

      <StudentWorkList
        studentWorkList={visibleStudentWork}
        filters={filters}
        multiSelectMode={
          multiSelectTooltipOpen || shareTooltipOpen || tutoringTooltipOpen
        }
        buildAssignmentFeedbackLinkObject={buildAssignmentFeedbackLinkObject}
        showScores={isAccordionOpen}
        addComment={addComment}
        changeTaskStatus={changeTaskStatus}
        onAddSticker={onAddSticker}
        pulseEffectTriggered={pulseEffectActive}
        onPulseEffectEnd={stopPulseEffect}
        toggleSelectStudentWorkItem={toggleSelectStudentWorkItem}
        activeTasksIds={activeTasksIds}
        storeActiveTaskId={storeActiveTaskId}
        removeActiveTaskId={removeActiveTaskId}
        setSelectedWorksSelectedChildren={setSelectedWorksSelectedChildren}
        refetchStudentWork={refetchStudentWork}
      />
      {isAssignStudentGroupDialogOpen && (
        <AssignStudentGroupDialog
          assignment={assignment}
          students={assignStudentGroupDialogData}
          openCreateStudentGroupDialog={openCreateStudentGroupDialogCallback}
          onStudentGroupAssign={goToStudentGroupDetail}
          onClose={closeAssignStudentGroupDialog}
          initialStudentGroup={studentGroup}
        />
      )}
      {isCreateStudentGroupDialogOpen && (
        <CreateStudentGroupDialog
          initialSchool={assignment.school}
          initialStudents={createStudentGroupDialogData}
          onStudentGroupCreate={onStudentGroupCreate}
          onClose={closeCreateStudentGroupDialog}
        />
      )}
      {isShareToMeetingDialogOpen && (
        <ShareToMeetingDialog
          assignment={assignment}
          data={shareToMeetingDialogData}
          onClose={closeShareToMeetingDialog}
        />
      )}
    </>
  );
};

export default withWidth()(TeacherAssignmentResults);
