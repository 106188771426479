import React from 'react';

import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { colors } from 'theme/palette';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import { cancelSubscription } from './sdk';
import styles from './styles.module.css';

const PlanDowngradeConfirmationDialog = ({
  customer,
  onClose,
  redirectToDashboard
}) => {
  const downgradePlan = async () => {
    const { success, errors } = await cancelSubscription(
      customer.subscription.id
    );

    if (success) {
      onClose();
      redirectToDashboard();
      notifySuccess('You have successfully cancelled your subscription.');
    } else {
      notifyErrors(errors);
    }
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onClose}
      disableBackdropClick={true}
    >
      <DialogTitle disableTypography className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Downgrading to free plan
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box padding={2}>
          <Typography variant="H-TEXT-3">
            Your plan will be automatically downgraded after the next billing
            cycle.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="base" color="pink" onClick={downgradePlan}>
          Downgrade plan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlanDowngradeConfirmationDialog;
