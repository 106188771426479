import React from 'react';
import Webcam from 'react-webcam';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import * as cx from 'classnames';
import jsQR from 'jsqr';

import FeatureToggle from 'components/FeatureToggle';
import Flex from 'components/Flex';
import Timer from 'components/Timer';

import { trackerInstanceSectionDetail } from './sdk';
import styles from './styles.module.css';

const WIDTH = 1200;
const HEIGHT = 720; // Above max camera

const TIME_TO_CAPTURE = 3000; // ms

const videoConstraints = {
  width: WIDTH,
  height: HEIGHT,
  facingMode: 'user'
};

const drawLine = (canvas, begin, end, color) => {
  canvas.beginPath();
  canvas.moveTo(begin.x, begin.y);
  canvas.lineTo(end.x, end.y);
  canvas.lineWidth = 4;
  canvas.strokeStyle = color;
  canvas.stroke();
};

const StatusIndicator = ({ value, children }) => {
  let icon = null;

  if (value === null) {
    icon = <HourglassEmptyRoundedIcon />;
  } else if (value === true) {
    icon = <CheckRoundedIcon />;
  } else {
    icon = <ClearRoundedIcon />;
  }

  return (
    <div className={cx('pushLeft', styles.status)}>
      {children} {icon}
    </div>
  );
};

class WebcamPlayground extends React.Component {
  constructor(props) {
    super(props);

    this.webcamRef = React.createRef();
    this.canvasRef = React.createRef();
    this.cameraReady = false;
    this.found = false;

    this.lastShot = null;
    this.lastCode = null;
  }

  state = {
    camera: false,
    codeAvailableBeforeCapture: false,
    codeAvailableAfterCapture: null,
    foundFromApi: null,
    code: null
  };

  fetchTrackerInstanceSectionDetail = async () => {
    const { code } = this.state;

    if (code === null) {
      return;
    }

    const { success } = await trackerInstanceSectionDetail(code.data);

    this.setState({ foundFromApi: success });
  };

  startCamera = (cb) => {
    console.log('Starting camera');

    this.cameraReady = false;
    this.found = false;
    this.lastShot = null;
    this.lastCode = null;

    this.setState(
      {
        camera: true,
        codeAvailableBeforeCapture: false,
        codeAvailableAfterCapture: null,
        foundFromApi: null,
        code: null
      },
      cb
    );
  };

  stopCamera = (cb) => {
    console.log('Stopping camera');
    const canvasElement = this.canvasRef.current;

    if (this.found === false) {
      canvasElement.hidden = true;
    }

    this.cameraReady = false;
    this.found = false;

    this.setState({ camera: false }, cb);
  };

  toggleCamera = (cb = () => {}) => {
    const { camera } = this.state;
    if (camera) {
      this.stopCamera(cb);
    } else {
      this.startCamera(cb);
    }
  };

  setCanvasSize = (width, height) => {
    const canvas = this.canvasRef.current;

    canvas.width = width;
    canvas.height = height;
    canvas.hidden = false;
  };

  findQrCode = (canvas, width, height) => {
    const imageData = canvas.getImageData(0, 0, width, height);

    const code = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: 'dontInvert'
    });

    return { code, imageData };
  };

  tick = () => {
    if (!this.webcamRef.current) {
      return;
    }

    const video = this.webcamRef.current.video;

    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      this.cameraReady = true;
    }

    if (this.cameraReady) {
      this.cameraReady = true;
      const height = video.videoHeight / (video.videoWidth / WIDTH);

      this.setCanvasSize(WIDTH, height);

      const canvasElement = this.canvasRef.current;
      const canvas = canvasElement.getContext('2d');
      canvas.drawImage(this.webcamRef.current.video, 0, 0, WIDTH, height);

      const { code, imageData } = this.findQrCode(
        canvas,
        canvasElement.width,
        canvasElement.height
      );

      if (code) {
        this.lastShot = imageData;
        this.lastCode = code;

        drawLine(
          canvas,
          code.location.topLeftCorner,
          code.location.topRightCorner,
          '#FF3B58'
        );
        drawLine(
          canvas,
          code.location.topRightCorner,
          code.location.bottomRightCorner,
          '#FF3B58'
        );
        drawLine(
          canvas,
          code.location.bottomRightCorner,
          code.location.bottomLeftCorner,
          '#FF3B58'
        );
        drawLine(
          canvas,
          code.location.bottomLeftCorner,
          code.location.topLeftCorner,
          '#FF3B58'
        );
        // If this is the transitional state, set timeout.
        // This is due to timeouting the function only once
        if (!this.found) {
          console.log('Before capture', code);

          this.found = true;

          this.setState({ codeAvailableBeforeCapture: true, code });

          setTimeout(() => {
            this.capture();
          }, TIME_TO_CAPTURE);
        }
      }
    }

    requestAnimationFrame(this.tick);
  };

  capture = () => {
    this.toggleCamera();

    const canvasElement = this.canvasRef.current;
    const canvas = canvasElement.getContext('2d');

    const width = canvasElement.width;
    const height = canvasElement.height;

    canvas.putImageData(this.lastShot, 0, 0);

    const { code } = this.findQrCode(canvas, width, height);
    console.log('After capture:', code);

    this.setState(
      {
        codeAvailableAfterCapture: code !== null
      },
      this.fetchTrackerInstanceSectionDetail
    );
  };

  handleDevices = (devices) => {
    for (let device of devices.filter(
      (device) => device.kind === 'videoinput'
    )) {
      console.log(device);
    }
  };

  componentDidMount() {
    navigator.mediaDevices.enumerateDevices().then(this.handleDevices);
  }

  componentDidUpdate() {
    if (this.webcamRef.current) {
      requestAnimationFrame(this.tick);
    }
  }

  render() {
    const {
      camera,
      codeAvailableBeforeCapture,
      codeAvailableAfterCapture,
      foundFromApi,
      code
    } = this.state;

    const hasResult = codeAvailableAfterCapture !== null;

    return (
      <FeatureToggle dev>
        <div>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Flex>
              <Button
                onClick={() => this.toggleCamera()}
                color="primary"
                variant="contained"
              >
                {camera ? 'Turn camera off' : 'Turn camera on'}
              </Button>
              <StatusIndicator value={codeAvailableBeforeCapture}>
                Found before capture
              </StatusIndicator>
              <StatusIndicator value={codeAvailableAfterCapture}>
                Found after capture
              </StatusIndicator>
              <StatusIndicator value={foundFromApi}>
                Found from API
              </StatusIndicator>
            </Flex>

            <Flex>
              {codeAvailableBeforeCapture && !hasResult && (
                <Timer seconds={TIME_TO_CAPTURE / 1000} />
              )}

              {hasResult && (
                <h1>{code !== null ? `FOUND: ${code.data}` : 'NOT FOUND'}</h1>
              )}
            </Flex>

            <Flex className={styles.cameraContainer}>
              {!camera && !hasResult && (
                <Flex
                  className={styles.placeholder}
                  style={{ height: HEIGHT, width: WIDTH }}
                >
                  <h1>Camera will appear here</h1>
                </Flex>
              )}

              {camera && (
                <Webcam
                  hidden
                  ref={this.webcamRef}
                  audio={false}
                  videoConstraints={videoConstraints}
                  width={WIDTH}
                  height={HEIGHT}
                />
              )}
              <canvas hidden ref={this.canvasRef}></canvas>
            </Flex>
          </Box>
        </div>
      </FeatureToggle>
    );
  }
}

export default WebcamPlayground;
