import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@material-ui/core/SvgIcon';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import classnames from 'classnames';
import {
  CLEVER_LOGIN_REDIRECT_URI,
  GOOGLE_LOGIN_REDIRECT_URI
} from 'config/constants';
import { WALKTHROUGH_CREATE_PROFILE_URL } from 'config/urls';
import { Field, Formik } from 'formik';
import logo from 'images/logo-dark.svg';
import _ from 'lodash';
import * as yup from 'yup';

import { colors } from 'theme/palette';
import { openCleverLoginPage } from 'utils/integrations/clever';
import { openGoogleLoginPage } from 'utils/integrations/google';
import { bootIntercomWidget } from 'utils/integrations/intercom';
import { formatDate } from 'utils/moment';
import { notifyError } from 'utils/notifications';
import { parseParams, reverse, stringifyParams } from 'utils/urls';

import Button from 'components/Button';
import Image from 'components/Image';
import InputField from 'components/InputField';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as CleverIcon } from './assets/clever.svg';
import { ReactComponent as GoogleIcon } from './assets/google.svg';
import { getMeetingInvitationDetails, login } from './sdk';
import styles from './styles.module.css';
import { getRedirectPath, handleIntercomOnRedirect } from './utils';

const initialValues = { email: '', password: '' };

const validationSchema = yup.object().shape({
  email: yup.string().email('Email is invalid.').required('Email is required.'),
  password: yup.string().required('Password is required.')
});

const RegularSignupForm = ({
  passwordType,
  showPassword,
  togglePassword,
  isSubmitting,
  errors,
  values,
  apiError,
  dirty
}) => (
  <React.Fragment>
    {!_.isEmpty(apiError) && (
      <Box marginBottom="40px">
        <Typography
          variant="S-TEXT-1"
          color={colors.pink2}
          className={styles.mainError}
        >
          {apiError}
        </Typography>
      </Box>
    )}
    <Box marginBottom="40px">
      <Typography
        variant="H-TEXT-3"
        color={colors.blue1}
        className={styles.fieldLabel}
      >
        Email
      </Typography>
      <Field
        fullWidth
        type="email"
        name="email"
        placeholder="Your email"
        className={classnames(styles.field, {
          [styles.hasValue]: _.get(values, 'email')
        })}
        component={InputField}
        variant="underlined"
        error={_.has(errors, 'email')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled className={styles.icon}>
                {(errors.email || !dirty) && <CheckCircleRoundedIcon />}
                {!(errors.email || !dirty) && (
                  <CheckCircleRoundedIcon style={{ color: 'var(--green)' }} />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
    <Box marginBottom="40px">
      <Typography
        variant="H-TEXT-3"
        color={colors.blue1}
        className={styles.fieldLabel}
      >
        Password
      </Typography>
      <Field
        fullWidth
        type={passwordType}
        name="password"
        placeholder="Your password"
        className={classnames(styles.field, {
          [styles.hasValue]: _.get(values, 'password')
        })}
        component={InputField}
        variant="underlined"
        error={_.has(errors, 'password')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                className={styles.icon}
                onClick={togglePassword}
              >
                {showPassword && <VisibilityRoundedIcon />}
                {!showPassword && <VisibilityOffRoundedIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
    <Box marginBottom="40px">
      <Button
        color="pink"
        type="submit"
        fullWidth
        disabled={isSubmitting || !dirty || !_.isEmpty(errors)}
      >
        SIGN IN
      </Button>
    </Box>
  </React.Fragment>
);

class Login extends React.Component {
  state = {
    passwordType: 'password',
    showRegularSignup: false,
    nextPath: '',
    apiError: '',
    integrationError: '',
    meetingTopic: '',
    invitationIdentifier: null
  };

  static contextType = UserContext;

  componentDidMount() {
    const { setUser } = this.context;
    setUser(null);

    this.parseQueryParameters();
    bootIntercomWidget();
  }

  fetchMeetingDetails = async ({ invitationIdentifier }) => {
    const { data: meetingDetails, success } = await getMeetingInvitationDetails(
      { invitationIdentifier }
    );

    if (success) {
      const meetingTopic = meetingDetails.topic;
      const scheduledFor = meetingDetails.scheduled_for;

      const scheduleDateFormatted = formatDate(scheduledFor);

      if (_.isEmpty(meetingTopic)) {
        this.setState({ meetingTopic: `Meeting - ${scheduleDateFormatted}` });
      } else {
        this.setState({
          meetingTopic: `${meetingTopic} - ${scheduleDateFormatted}`
        });
      }
    } else {
      notifyError('Access Denied!');
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.search === '' && this.props.location.search !== '') {
      // When the user is being programatically redirected to this page via the `TeacherRequired` logic,
      // the `location` property from `react-router` is updated *later* than the `componentDidMount` of this component fires.
      // That's why we need to handle this `location` update & make sure to parse the query params again if this happens.
      // Reference: https://github.com/ReactTraining/react-router/blob/0853628daff26a809e5384f352fada57753fc1c3/packages/react-router/modules/Router.js#L22
      this.parseQueryParameters();
    }
  }

  parseQueryParameters = () => {
    const queryParams = parseParams(this.props.location.search);

    const nextPath = _.get(queryParams, 'next');
    const invitationIdentifier = _.get(queryParams, 'invitationIdentifier');

    if (!_.isNil(invitationIdentifier)) {
      this.setState({ invitationIdentifier });
      this.fetchMeetingDetails({ invitationIdentifier });
    }

    if (nextPath) {
      this.setState({ nextPath });
    }

    const errorMessage = _.get(queryParams, 'error');

    if (errorMessage) {
      delete queryParams['error'];

      this.setState({ integrationError: errorMessage }, () =>
        this.props.history.replace({
          search: stringifyParams(queryParams)
        })
      );
    }
  };

  redirect = (user) => {
    const redirectPath = getRedirectPath(user, this.state.nextPath);
    handleIntercomOnRedirect(user, redirectPath);
    this.props.history.push(redirectPath);
  };

  onSubmit = async (values, actions) => {
    const { data, success, errors } = await login(values);

    if (success) {
      const { setUser } = this.context;

      setUser(data.me);
      this.redirect(data.me);
    } else {
      const apiError = _.get(
        errors,
        '[0].message',
        'Incorrect email or password'
      );
      this.setState({ apiError }, () => {
        // Trigger the error state of the inputs
        const { setFieldError } = actions;
        setFieldError('email', true);
        setFieldError('password', true);
      });
    }
  };

  loginWithGoogle = () => {
    const state = {
      next: this.state.nextPath,
      searchParams: parseParams(this.props.location.search)
    };

    const redirectUri = GOOGLE_LOGIN_REDIRECT_URI;
    const scope = _.join(
      [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
      ],
      ' '
    );

    openGoogleLoginPage(redirectUri, scope, state);
  };

  loginWithClever = () => {
    const state = {
      next: this.state.nextPath,
      searchParams: parseParams(this.props.location.search)
    };
    const redirectUri = CLEVER_LOGIN_REDIRECT_URI;

    openCleverLoginPage(redirectUri, state);
  };

  togglePassword = (event) => {
    event.stopPropagation();

    const newType =
      this.state.passwordType === 'password' ? 'text' : 'password';

    this.setState({ passwordType: newType });
  };

  showSignupForm = () => {
    this.setState({ showRegularSignup: true });
  };

  hideSignupForm = () => {
    this.setState({ showRegularSignup: false });
  };

  render() {
    const {
      passwordType,
      showRegularSignup,
      apiError,
      integrationError,
      meetingTopic
    } = this.state;

    const isMeetingInvitation = !_.isEmpty(meetingTopic);

    const showPassword = passwordType === 'password';

    const createProfileUrl = isMeetingInvitation
      ? {
          pathname: reverse(WALKTHROUGH_CREATE_PROFILE_URL),
          search: this.props.location.search
        }
      : WALKTHROUGH_CREATE_PROFILE_URL;

    return (
      <div className={styles.loginWrapper}>
        <div className={styles.loginBox}>
          <Image src={logo} className={styles.logo} alt="Logo" />
          {showRegularSignup && (
            <Box maxWidth={300} margin="auto">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
              >
                {({ isSubmitting, handleSubmit, dirty, errors, values }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <RegularSignupForm
                        passwordType={passwordType}
                        showPassword={showPassword}
                        togglePassword={this.togglePassword}
                        isSubmitting={isSubmitting}
                        errors={errors}
                        dirty={dirty}
                        values={values}
                        apiError={apiError}
                      />
                    </form>
                  );
                }}
              </Formik>
              <Typography
                variant="B-Text-2"
                color={colors.blue1}
                onClick={this.hideSignupForm}
                className={classnames(styles.link, 'pointer')}
              >
                Back
              </Typography>
            </Box>
          )}
          {!showRegularSignup && (
            <Box>
              <Box>
                <Typography variant="S-TEXT-1" color={colors.grey2}>
                  {isMeetingInvitation
                    ? 'You have been invited to join'
                    : 'Welcome!'}
                </Typography>
              </Box>
              <Box marginTop="10px">
                {isMeetingInvitation ? (
                  <Typography variant="H-TEXT-3" color={colors.grey1}>
                    {meetingTopic}
                  </Typography>
                ) : (
                  <Typography variant="H-TEXT-2" color={colors.grey3}>
                    Sign in to Edlight
                  </Typography>
                )}
              </Box>
              {!_.isEmpty(integrationError) && (
                <Box marginTop="20px">
                  <Typography
                    variant="B-Text-2"
                    color={colors.pink1}
                    className={styles.mainError}
                  >
                    {integrationError}
                  </Typography>
                </Box>
              )}
              <Box marginTop={!_.isEmpty(integrationError) ? '20px' : '40px'}>
                <Button
                  fullWidth
                  variant="contained"
                  classes={{
                    root: styles.googleButton,
                    label: styles.googleButtonLabel,
                    startIcon: styles.googleButtonIcon
                  }}
                  startIcon={
                    <SvgIcon component={GoogleIcon} fontSize="large" />
                  }
                  noTypography
                  onClick={this.loginWithGoogle}
                >
                  Sign in with Google
                </Button>
              </Box>
              <Box marginTop="16px">
                <Button
                  fullWidth
                  variant="contained"
                  classes={{
                    root: styles.cleverButton,
                    label: styles.cleverButtonLabel,
                    startIcon: styles.cleverButtonIcon
                  }}
                  startIcon={
                    <SvgIcon component={CleverIcon} fontSize="large" />
                  }
                  noTypography
                  onClick={this.loginWithClever}
                >
                  Sign in with Clever
                </Button>
              </Box>
              <Box marginTop="16px">
                <Button
                  noTypography
                  className={styles.usePasswordButton}
                  onClick={this.showSignupForm}
                >
                  <Typography variant="H-TEXT-3" color={colors.blue3}>
                    Use a password
                  </Typography>
                </Button>
              </Box>
              <Box marginTop="40px">
                <Link className={styles.link} to={createProfileUrl}>
                  <Typography variant="B-Text-3" color={colors.blue1}>
                    New Teacher? Create Account
                  </Typography>
                </Link>
              </Box>
            </Box>
          )}
        </div>
      </div>
    );
  }
}

export default Login;
