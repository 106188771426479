import React from 'react';

import { colors } from 'theme/palette';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './styles.module.css';

const UpdateGoogleClassroomRostersDialog = ({ onSync, onClose }) => (
  <Dialog
    open
    alignTop
    fullWidth
    maxWidth="sm"
    onClose={onClose}
    classes={{ paper: styles.dialog }}
  >
    <div className={styles.container}>
      <Typography variant="H-TEXT-2" color={colors.blue2}>
        Happy new school year!
      </Typography>
      <Typography align="center" variant="B-Text-1" color={colors.grey1}>
        Do you want to update your rosters from Google Classroom?
      </Typography>

      <div className={styles.buttonsContainer}>
        <Button color="grey" onClick={onClose}>
          Later
        </Button>
        <Button color="pink" onClick={onSync}>
          Sync now
        </Button>
      </div>
    </div>
  </Dialog>
);

export default React.memo(UpdateGoogleClassroomRostersDialog);
