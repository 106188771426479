import { BASE_URL } from 'config/urls';

import { get, requestSdk } from 'utils/sdk';

export const userSections = () =>
  requestSdk(() =>
    get(`${BASE_URL}/v1/sections/list-for-user/`, { limit: 50 })
  );

export const userSectionsTrackers = () =>
  requestSdk(() => get(`${BASE_URL}/v1/trackers/activity/tracker-names/`));

export const actorNamesList = () =>
  requestSdk(() => get(`${BASE_URL}/v1/trackers/activity/actor-names/`));
