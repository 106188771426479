import React, { useEffect, useState } from 'react';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowForwardIosRounded from '@material-ui/icons/ArrowForwardIosRounded';
import classnames from 'classnames';
import { ReactComponent as FilterIcon } from 'images/filter.svg';
import _ from 'lodash';

import { Typography } from 'pages/Students/shared';
import { colors } from 'theme/palette';

import ForwardButton from 'components/ForwardButton';
import SearchFilter from 'components/SearchFilter';

import { fetchAssignmentsNamesList } from './sdk';
import styles from './styles.module.css';

const FilterDrawer = ({
  open,
  onClose,
  setFilters,
  onFiltersApply,
  resetStatus,
  filters,
  allSections
}) => {
  const [assignmentName, setAssignmentName] = useState('');
  const [selectedSections, setSelectedSections] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [sections, setSections] = useState([]);

  const applyFilters = () => {
    setFilters({ assignmentName, sections: selectedSections })
      .then(resetStatus)
      .then(onFiltersApply)
      .then(onClose);
  };

  const selectSection = (section) => {
    setSelectedSections(_.xor(selectedSections, [section]));
  };

  useEffect(() => {
    setAssignmentName(filters.assignmentName);
    setSelectedSections(filters.sections);
  }, [filters]);

  useEffect(() => {
    setSections(allSections);
  }, [allSections]);

  useEffect(() => {
    fetchAssignmentsNamesList().then((data) => data && setAssignments(data));
  }, []);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={applyFilters} // Feature Requierement: We want to apply the filters when the backdrop is clicked
      classes={{
        root: styles.filtersDrawer,
        paper: styles.filtersDrawerPaper
      }}
    >
      <div className={classnames(styles.menuSection, styles.filterTitle)}>
        <IconButton onClick={onClose} className={styles.backIcon} size="small">
          <ArrowForwardIosRounded style={{ color: colors.blue3 }} />
        </IconButton>
        <FilterIcon fill={colors.blue1} className={styles.filterIcon} />
        <Typography variant="H-TEXT-3">Filter</Typography>
      </div>

      <div className={styles.menuSection}>
        <SearchFilter
          autoComplete
          options={_.map(assignments, 'title')}
          onChange={(event, value) => setAssignmentName(value)}
          value={assignmentName}
          placeholder="Assignment name"
        />
      </div>

      <Typography
        variant="S-TEXT-1"
        color={colors.blue2}
        className={styles.itemPadded}
      >
        Class
      </Typography>
      <MenuList className={styles.menuList}>
        {_.map(sections, (section) => (
          <MenuItem
            key={section.id}
            selected={selectedSections.includes(section)}
            className={classnames({
              [styles.selectedSection]: selectedSections.includes(section)
            })}
            onClick={() => selectSection(section)}
          >
            <Typography
              variant="B-Text-2"
              color={colors.grey2}
              className="pushLeft"
            >
              {section.name}
            </Typography>
          </MenuItem>
        ))}
      </MenuList>
      <div className={styles.applyButtonContianer}>
        <ForwardButton fullWidth color="blue" onClick={applyFilters}>
          Apply
        </ForwardButton>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
