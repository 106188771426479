import React from 'react';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Image from 'components/Image';
import Typography from 'components/Typography';

import noActivity from './assets/no_activity.svg';
import styles from './styles.module.css';
import { ActivityMapping } from './utils';

const ActivityListSkeleton = () => (
  <React.Fragment>
    <Grid item xs={12} className={styles.activityRow}>
      <Skeleton width="70%" />
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12} className={styles.activityRow}>
      <Skeleton width="70%" />
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12} className={styles.activityRow}>
      <Skeleton width="70%" />
    </Grid>
  </React.Fragment>
);

const NoActivity = ({ lgScreen }) => (
  <div className={styles.noActivityWrapper}>
    <Typography
      variant="H-TEXT-1"
      className={styles.noActivityTitle}
      color={colors.blue3}
    >
      No activity
    </Typography>
    <Image
      src={noActivity}
      alt="No activity"
      style={lgScreen ? { width: '60%' } : { width: '100%' }}
    />
  </div>
);

export const ActivityList = ({ stream, loading, lgScreen }) => (
  <Grid container>
    {loading && <ActivityListSkeleton />}

    {!loading &&
      stream &&
      _.map(stream, (activity) => {
        const activityObject = ActivityMapping(activity);

        return (
          <Grid item container key={activity.id}>
            <Link to={activityObject.linkTo} className={styles.activityLink}>
              <Grid
                item
                container
                className={styles.activityRow}
                align="center"
              >
                <Grid item className={styles.activityIcon}>
                  {activityObject.icon}
                </Grid>
                <Grid item>
                  <Typography
                    variant="B-Text-2"
                    color={colors.grey1}
                    component="span"
                  >
                    {activityObject.object}
                  </Typography>
                  {activityObject.object && ' '}
                  <Typography
                    variant="H-TEXT-3"
                    color={colors.blue3}
                    component="span"
                  >
                    {activityObject.verb}
                  </Typography>
                  {activityObject.verb && ' '}
                  <Typography
                    variant="B-Text-2"
                    color={colors.grey1}
                    component="span"
                  >
                    {activityObject.actionObject}
                  </Typography>
                  {activityObject.actionObject && ' '}
                  <Typography
                    variant="B-Text-2"
                    color={colors.grey1}
                    component="span"
                  >
                    {activityObject.conjunction}
                  </Typography>
                  {activityObject.conjunction && ' '}
                  <Typography
                    variant="B-Text-2"
                    color={colors.grey1}
                    component="span"
                  >
                    {activityObject.target}
                  </Typography>
                  {activityObject.target && ' '}
                  <Typography
                    variant="B-Text-2"
                    color={colors.grey3}
                    component="span"
                  >
                    {activityObject.timestamp}
                  </Typography>
                </Grid>
              </Grid>
            </Link>
            <Grid item container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        );
      })}

    {!loading && _.isEmpty(stream) && <NoActivity lgScreen={lgScreen} />}
  </Grid>
);
