import React from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import classnames from 'classnames';
import _ from 'lodash';

import AddButton from 'components/AddButton';

import styles from './styles.module.css';

const NotesSkeleton = (paperProps) => (
  <Paper variant="outlined" {...paperProps}>
    <div className={styles.contentSection}>
      <Skeleton
        variant="text"
        animation="wave"
        width={70}
        style={{ marginBottom: 16 }}
      />
      <div className={styles.notes}>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
        <div className={styles.notesContent}>
          <Skeleton
            animation="wave"
            height={15}
            width="40%"
            style={{ marginBottom: 8 }}
          />
          <Skeleton animation="wave" height={15} width="60%" />
        </div>
      </div>
      <Skeleton
        variant="text"
        animation="wave"
        width="100%"
        height={40}
        style={{ marginTop: 16 }}
      />
    </div>
  </Paper>
);

const AssignmentPaperSkeleton = (paperProps) => {
  return (
    <Paper variant="outlined" {...paperProps}>
      <div className={styles.contentSection}>
        <div className={styles.assignmentHeader}>
          <Skeleton variant="text" animation="wave" width={500} />
          <Skeleton variant="text" animation="wave" width={80} height={30} />
        </div>
        <Skeleton variant="text" animation="wave" width={70} />
        <Skeleton
          animation="wave"
          height={15}
          style={{ margin: '16px 0px 5px 0px' }}
        />
        <Skeleton animation="wave" height={15} width="80%" />
      </div>
      <Divider height={5} />
      <NotesSkeleton />
    </Paper>
  );
};

const ResultsCardsSkeleton = ({ cardsCount = 3 }) => {
  return (
    <div className={styles.cardsContainer}>
      {_.times(cardsCount, (cardIdx) => (
        <Card variant="outlined" key={cardIdx}>
          <Skeleton animation="wave" variant="rect" height={200} />

          <CardContent className={styles.cardContent}>
            <div className={styles.contentSection}>
              <div className={styles.flexWithSpaceBetween}>
                <Skeleton
                  animation="wave"
                  width={100}
                  height={20}
                  style={{ marginBottom: 8 }}
                />
                <Skeleton animation="wave" height={15} width={80} />
              </div>
              <div className={styles.flexWithSpaceBetween}>
                <Skeleton
                  variant="circle"
                  animation="wave"
                  width={15}
                  height={15}
                />
                <Skeleton animation="wave" height={15} width={80} />
              </div>
            </div>
            <Divider />
            <div className={styles.contentSection}>
              <Skeleton
                animation="wave"
                height={20}
                width="60%"
                style={{ marginBottom: 8 }}
              />
              <div className={styles.flexWithSpaceBetween}>
                <Skeleton
                  animation="wave"
                  height={15}
                  width="40%"
                  style={{ paddingLeft: 8 }}
                />
                <Skeleton animation="wave" height={15} width="5%" />
              </div>
              <div className={styles.flexWithSpaceBetween}>
                <Skeleton
                  animation="wave"
                  height={15}
                  width="50%"
                  style={{ paddingLeft: 8 }}
                />
                <Skeleton animation="wave" height={15} width="5%" />
              </div>
            </div>
            <Divider />
            <div className={styles.contentSection}>
              <Skeleton
                animation="wave"
                width={70}
                height={20}
                style={{ marginBottom: 8 }}
              />
              <div className={styles.notes}>
                <Skeleton
                  animation="wave"
                  variant="circle"
                  width={30}
                  height={30}
                />
                <div className={styles.notesContent}>
                  <Skeleton
                    animation="wave"
                    height={15}
                    width="80%"
                    style={{ marginBottom: 8 }}
                  />
                  <Skeleton animation="wave" height={15} width="60%" />
                </div>
              </div>
              <Skeleton
                variant="text"
                animation="wave"
                width="100%"
                height={30}
                style={{ marginTop: 8 }}
              />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export const RandomTutoringFeedItemSkeleton = () => {
  const skeletons = [
    AssignmentPaperSkeleton,
    ResultsCardsSkeleton,
    NotesSkeleton
  ];

  const RandomSkeleton = _.sample(skeletons);

  return <RandomSkeleton />;
};

const TutoringFeedSkeleton = () => (
  <div className={styles.tutoringFeedSkeletonWrapper}>
    <div className={styles.headerContainer}>
      <Paper variant="outlined" className={styles.header}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Skeleton
            variant="text"
            animation="wave"
            width={200}
            className={styles.sessionTitle}
          />
          <div className={styles.actionButtons}>
            <AddButton disabled>New assignment</AddButton>
            <AddButton disabled>Note</AddButton>
          </div>
        </Box>
      </Paper>
    </div>
    <AssignmentPaperSkeleton
      className={classnames(styles.animationStep, styles.animationFirstStep)}
    />
    <div
      className={classnames(styles.animationStep, styles.animationSecondStep)}
    >
      <ResultsCardsSkeleton />
    </div>
    <NotesSkeleton
      className={classnames(styles.animationStep, styles.animationThirdStep)}
    />
  </div>
);

export default TutoringFeedSkeleton;
