import React from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import ZoomOutRoundedIcon from '@material-ui/icons/ZoomOutRounded';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.css';

const ZoomControlls = ({ zoom }) => (
  <ButtonGroup
    variant="contained"
    disableElevation
    className={styles.zoomControlls}
  >
    <Button
      className={styles.zoomOutIcon}
      onClick={(e) => {
        zoom.zoomOut(e);
      }}
      disabled={zoom.scale <= 1}
    >
      <ZoomOutRoundedIcon style={{ color: colors.blue3 }} fontSize="large" />
    </Button>
    <Button
      disableElevation
      disabled={true}
      variant="text"
      className={styles.percentage}
    >
      <Typography variant="H-TEXT-3" color={colors.blue3}>
        {Math.round((zoom.scale - 1) * 10 + 100)}%
      </Typography>
    </Button>
    <Button
      className={styles.zoomInIcon}
      onClick={(e) => {
        zoom.zoomIn(e);
      }}
      disabled={zoom.scale >= 11}
    >
      <ZoomInRoundedIcon style={{ color: colors.blue3 }} fontSize="large" />
    </Button>
  </ButtonGroup>
);

export default ZoomControlls;
