import React, { useCallback, useMemo, useState } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import _ from 'lodash';
import { sectionUpdate, useSchoolUsers } from 'sdk';

import SectionStudentsList from 'pages/Teachers/StudentGroupList/components/SectionStudentsList';
import { colors } from 'theme/palette';
import { MuiAutocompleteChangeReasons } from 'utils/constants';
import { notifyError, notifySuccess } from 'utils/notifications';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import SearchFilter from 'components/SearchFilter';
import Typography from 'components/Typography';

import styles from './styles.module.scss';
import { getOptionLabel } from './utils';

const UpdateSectionDialog = ({
  onClose,
  onSectionUpdate,
  students,
  studentsSyncedBy,
  users,
  section,
  school
}) => {
  const [sectionName, setSectionName] = useState(_.get(section, 'name', ''));
  const [submitting, setSubmitting] = useState(false);

  const [userName, setUserName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(users);

  const { data: schoolUsers, isLoading: isLoadingSchoolUsers } = useSchoolUsers(
    { params: { schools_ids: [school.id], name: userName } }
  );

  const initialUsersIds = useMemo(() => users.map((user) => user.id), [users]);
  const selectedUsersIds = useMemo(
    () => selectedUsers?.map((user) => user.id) || [],
    [selectedUsers]
  );

  const addUser = useCallback(({ user }) => {
    setSelectedUsers((prev) => [user, ...prev]);
    setUserName('');
  }, []);

  const removeUser = useCallback(
    ({ user: userToRemove }) =>
      setSelectedUsers((prev) =>
        prev.filter((user) => user.id !== userToRemove.id)
      ),
    []
  );

  const disableSubmit = isLoadingSchoolUsers || submitting;

  const onUpdateClick = async () => {
    setSubmitting(true);

    const data = {
      name: sectionName,
      users: selectedUsersIds,
      students: students.map((student) => student.id)
    };

    const { success, errors } = await sectionUpdate({
      sectionId: section.id,
      data
    });

    if (success) {
      notifySuccess('Successfully updated the class!');
      onSectionUpdate({
        sectionId: section.id,
        data: {
          name: sectionName,
          students: students,
          users: selectedUsers
        }
      });
      onClose();
    } else {
      notifyError(errors);
    }

    setSubmitting(false);
  };
  const filterUserOptions = useCallback(
    (options) =>
      options.filter((option) => !selectedUsersIds.includes(option.id)),
    [selectedUsersIds]
  );

  const onUserInputChange = useCallback((e, val, trigger) => {
    if (trigger === 'input') {
      setUserName(val);
    }
  }, []);
  const onUserChange = useCallback(
    (e, opt, trigger) => {
      if (trigger === MuiAutocompleteChangeReasons.SELECT_OPTION) {
        addUser({ user: opt });
      }
    },
    [addUser]
  );

  return (
    <Dialog
      open
      alignTop
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Update Class
        </Typography>

        <div>
          <Button color="lightGrey" onClick={onClose} className={styles.button}>
            Cancel
          </Button>
          <Button
            color="pink"
            disabled={disableSubmit}
            onClick={onUpdateClick}
            className={styles.button}
          >
            Update
          </Button>
        </div>
      </div>
      <div className={styles.dialogContent}>
        <Typography
          variant="B-Text-3"
          className={styles.source}
          color={colors.blue2}
        >
          Source: {studentsSyncedBy}
        </Typography>

        <InputField
          fullWidth
          variant="underlined"
          placeholder="Student Group Name"
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
        />

        <>
          <Typography
            variant="S-TEXT-1"
            color={colors.grey3}
            className={styles.label}
          >
            Students
          </Typography>

          <Typography
            variant="B-Text-2"
            className={styles.rostersInformation}
            color={colors.grey1}
          >
            Student rosters are set from {studentsSyncedBy}
          </Typography>

          <SectionStudentsList students={students} />
          <Typography
            variant="S-TEXT-1"
            color={colors.grey3}
            className={styles.label}
          >
            Staff
          </Typography>
          <SearchFilter
            inputValue={userName}
            placeholder="Add Staff"
            options={schoolUsers || []}
            filterOptions={filterUserOptions}
            getOptionLabel={getOptionLabel}
            onInputChange={onUserInputChange}
            onChange={onUserChange}
          />
          <div className={styles.selectedUsers}>
            {selectedUsers?.map((user) => (
              <div key={user.id} className={styles.selectedUserRow}>
                <Typography variant="B-Text-2" color={colors.grey1}>
                  {user.name}
                </Typography>
                {!initialUsersIds.includes(user.id) && (
                  <Button
                    color="blue"
                    variant="small"
                    noTypography
                    className={styles.minusButton}
                    onClick={() => removeUser({ user })}
                  >
                    <Typography variant="H-TEXT-1" color={colors.white}>
                      -
                    </Typography>
                  </Button>
                )}
              </div>
            ))}
          </div>
        </>

        {submitting && <LinearProgress className={styles.progress} />}
      </div>
    </Dialog>
  );
};

export default UpdateSectionDialog;
