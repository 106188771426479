import React from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Pagination from '@material-ui/lab/Pagination';
import { TEACHERS_STUDENT_GROUP_URL } from 'config/urls';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { formatDate } from 'utils/moment';
import { reverse } from 'utils/urls';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.css';
import { useRefetchOnLastRowRemoved, useSessions } from './utils';

const NoSessionsAssignedPlaceholder = () => (
  <Grid container item>
    <Grid item className={styles.noSessionsCell}>
      <Typography variant="B-Text-2" color={colors.grey3}>
        No sessions assigned
      </Typography>
    </Grid>
  </Grid>
);

const SessionsTable = ({ sessionsList, pageCount, currentPage, setPage }) => {
  const { sessions, removeSelectedSession, clearAllSessions } =
    useSessions(sessionsList);

  useRefetchOnLastRowRemoved(sessions, pageCount, setPage);

  return (
    <Grid container>
      <Grid item container className={styles.tableHeader} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="S-TEXT-1" color={colors.blue1}>
            Small Group
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="S-TEXT-1" color={colors.blue1}>
            Assigned By
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="S-TEXT-1" color={colors.blue1}>
            Assigned At
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="S-TEXT-1" color={colors.blue1}>
            Assignment
          </Typography>
        </Grid>
        <Grid item xs={2} align="center">
          <Button
            className={styles.completeAllBtn}
            variant="small"
            color="lightGrey"
            onClick={() => clearAllSessions()}
            disabled={_.isEmpty(sessions)}
          >
            Complete all
          </Button>
        </Grid>
      </Grid>
      {_.isEmpty(sessions) && <NoSessionsAssignedPlaceholder />}
      {_.map(sessions, (session) => (
        <Grid
          item
          container
          alignItems="center"
          key={session.id}
          className={styles.tableRow}
          component={Link}
          to={reverse(TEACHERS_STUDENT_GROUP_URL, {
            id: session.section.id
          })}
        >
          <Grid item xs={3}>
            <Typography variant="B-Text-2" color={colors.grey1}>
              {session.section.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="B-Text-2" color={colors.grey1}>
              {session.created_by.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="B-Text-2" color={colors.grey2}>
              {formatDate(session.created_at, 'ddd MM/DD h:mm A')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="B-Text-2" color={colors.grey1}>
              {session.tracker_instance_section.name}
            </Typography>
          </Grid>
          <Grid item xs={2} align="center">
            <IconButton
              className={styles.completeButton}
              onClick={(event) => {
                event.preventDefault();
                removeSelectedSession(session.id);
              }}
            >
              <SvgIcon component={CheckCircleIcon} />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      {pageCount > 1 && !_.isEmpty(sessions) && (
        <Grid container item>
          <Grid item className={styles.paginationCell}>
            <Pagination
              className={styles.pagination}
              count={pageCount}
              page={currentPage + 1}
              onChange={(_, page) => {
                setPage(page - 1);
              }}
              color="secondary"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SessionsTable;
