import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import { TopBarSelect, useNavbar } from 'pages/Teachers/shared';
import { default as pusherManager } from 'utils/integrations/pusher';
import { notifyError } from 'utils/notifications';
import { useFetch } from 'utils/sdk';
import { parseParams } from 'utils/urls';

import { assignmentSectionsList } from './sdk';
import {
  buildSectionsOptions,
  ensureStudentWorkSelect,
  getVisibleStudentWorkList,
  setVisibleStudentWorkSelected,
  toggleAllStudentWorkSelectValue,
  toggleStudentWorkItemSelect
} from './utils';

export const useSections = (trackerId) => {
  const history = useHistory();
  const [selectedSection, setSelectedSection] = useState(null);

  const { data: sections, loading } = useFetch(
    assignmentSectionsList,
    { data: {}, loading: true },
    trackerId
  );

  useEffect(() => {
    if (!loading) {
      // TODO: Handle errors in useFetch in order to be able to show them here
      if (_.isEmpty(sections)) {
        notifyError('There are no active sections for this assignment.');
      }
    }
  }, [loading, sections]);

  useEffect(() => {
    if (!_.isEmpty(sections) && !loading && !selectedSection) {
      const searchParams = parseParams(history.location.search);
      const sectionIdFromParams =
        _.get(searchParams, 'section') &&
        _.find(sections, { id: _.toNumber(searchParams.section) });

      setSelectedSection(sectionIdFromParams || _.first(sections));
    }
  }, [sections, loading, selectedSection, history]);

  useEffect(() => {
    setSelectedSection(null);
  }, [trackerId]);

  const selectSection = useCallback(
    (newSectionId) => {
      setSelectedSection(_.find(sections, { id: newSectionId }));
    },
    [sections]
  );

  return [selectedSection, selectSection, sections, loading];
};

export const useNavigation = ({
  assignment,
  selectedSectionId,
  sections,
  selectSection
}) => {
  const { navigation } = useNavbar({ title: '' });

  useEffect(() => {
    if (assignment) {
      navigation.setTitle(_.get(assignment, 'tracker_name'));
    } else {
      navigation.setTitle('');
    }
  }, [navigation, assignment]);

  const renderValue = useCallback(
    (value) => {
      return _.get(_.find(sections, { id: value }), 'section_name', '');
    },
    [sections]
  );

  useEffect(() => {
    if (!_.isEmpty(sections) && selectedSectionId) {
      navigation.setLeftSubheader(
        <TopBarSelect
          value={selectedSectionId}
          renderValue={renderValue}
          onChange={selectSection}
          options={buildSectionsOptions(sections)}
        />
      );
    }
  }, [navigation, selectedSectionId, sections, selectSection, renderValue]);
};

export const usePusherManager = (selectedSectionId, webhookCallback) => {
  useEffect(() => {
    if (selectedSectionId) {
      pusherManager.stopListeningForNewStudentWork(selectedSectionId);
      pusherManager.listenForNewStudentWork(selectedSectionId, webhookCallback);
    }
  }, [selectedSectionId, webhookCallback]);

  useEffect(() => {
    return () => pusherManager.disconnect();
  }, []);
};

export const usePulseEffect = () => {
  const [active, setActive] = useState(false);

  const start = useCallback(() => setActive(true), []);
  const stop = useCallback(() => setActive(false), []);

  return [active, start, stop];
};

const useTooltip = () => {
  const [opened, setOpened] = useState(false);

  const toggle = useCallback(() => setOpened((prev) => !prev), []);
  const close = useCallback(() => setOpened(false), []);

  return [opened, toggle, close];
};

export const usePageTooltips = () => {
  const [
    multiSelectTooltipOpen,
    toggleMultiSelectTooltip,
    closeMultiSelectTooltip
  ] = useTooltip();
  const [tutoringTooltipOpen, toggleTutoringTooltip, closeTutoringTooltip] =
    useTooltip();
  const [shareTooltipOpen, toggleShareTooltip, closeShareTooltip] =
    useTooltip();

  const onMultiSelectTooltipClick = useCallback(() => {
    toggleMultiSelectTooltip();
    closeTutoringTooltip();
    closeShareTooltip();
  }, [toggleMultiSelectTooltip, closeTutoringTooltip, closeShareTooltip]);

  const onTutoringTooltipClick = useCallback(() => {
    toggleTutoringTooltip();
    closeMultiSelectTooltip();
    closeShareTooltip();
  }, [toggleTutoringTooltip, closeMultiSelectTooltip, closeShareTooltip]);

  const onShareTooltipClick = useCallback(() => {
    toggleShareTooltip();
    closeMultiSelectTooltip();
    closeTutoringTooltip();
  }, [toggleShareTooltip, closeMultiSelectTooltip, closeTutoringTooltip]);

  return [
    multiSelectTooltipOpen,
    onMultiSelectTooltipClick,
    tutoringTooltipOpen,
    onTutoringTooltipClick,
    shareTooltipOpen,
    onShareTooltipClick
  ];
};

export const useAccordion = () => {
  const [opened, setOpened] = useState(false);
  const toggle = useCallback(() => setOpened((prev) => !prev), []);

  return [opened, toggle];
};

const defaultFilters = { tasks: [], show: { value: '' }, students: [] };
export const useFilters = () => {
  const [filters, setFilters] = useState(defaultFilters);
  return [filters, setFilters];
};

export const useOrdering = () => {
  const [ordering, setOrdering] = useState({ value: '' });
  return [ordering, setOrdering];
};

export const useVisibleStudentWork = (
  studentWork,
  filters,
  ordering,
  allTooltipsClosed,
  activeTasksIds
) => {
  const [studentWorkList, setStudentWorkList] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(studentWork)) {
      const studentWorkList = getVisibleStudentWorkList({
        studentWork,
        filters,
        ordering
      });

      setStudentWorkList((prev) =>
        ensureStudentWorkSelect({
          studentWorkList: prev,
          newStudentWorkList: studentWorkList
        })
      );
    }
  }, [studentWork, filters, ordering]);

  const toggleSelectStudentWorkItem = useCallback(
    ({ studentId, trackerScoreResponseId, selected }) => {
      setStudentWorkList((swList) =>
        toggleStudentWorkItemSelect({
          studentWorkList: swList,
          selected,
          studentId,
          trackerScoreResponseId
        })
      );
    },
    []
  );

  const toggleSelectAllVisibleStudentWork = useCallback(
    ({ includeEmpty = true }) =>
      setStudentWorkList((swList) =>
        setVisibleStudentWorkSelected(
          swList,
          activeTasksIds,
          filters.show.value,
          includeEmpty
        )
      ),
    [filters.show, activeTasksIds]
  );

  useEffect(() => {
    if (allTooltipsClosed) {
      setStudentWorkList((swList) =>
        toggleAllStudentWorkSelectValue({
          studentWorkList: swList,
          selected: false
        })
      );
    }
  }, [allTooltipsClosed]);

  return [
    studentWorkList,
    toggleSelectStudentWorkItem,
    toggleSelectAllVisibleStudentWork
  ];
};

export const useActiveTasksIds = () => {
  const [activeTasksIds, storeActiveTasksIds] = useState([]);

  const storeActiveTaskId = useCallback((id) => {
    storeActiveTasksIds((prev) => [...prev, id]);
  }, []);
  const removeActiveTaskId = useCallback((id) => {
    storeActiveTasksIds((prev) => prev.filter((x) => x !== id));
  }, []);

  return [activeTasksIds, storeActiveTaskId, removeActiveTaskId];
};
